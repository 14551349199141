import { DocRenderer } from '@cyntler/react-doc-viewer';
import { twMerge } from 'tailwind-merge';

const getImageRenderer = (className?: string) => {
  const ImageRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument) {
      return null;
    }

    const divClass = twMerge(className, '');

    return (
      <div className={divClass}>
        <img src={currentDocument.uri} />
      </div>
    );
  };

  ImageRenderer.fileTypes = [
    'jpg',
    'jpeg',
    'png',
    'webp',
    'gif',
    'tiff',
    'bmp',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/gif',
    'image/tiff',
    'image/bmp',
  ];
  ImageRenderer.weight = 1;

  return ImageRenderer;
};

export default getImageRenderer;
