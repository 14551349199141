import React, { useEffect, useRef } from 'react';

interface ClickOutsideProps extends React.HTMLProps<HTMLDivElement> {
  onOutsideClick: () => void;
}

const ClickOutside = ({ onOutsideClick, ...props }: ClickOutsideProps) => {
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapper.current &&
        event.target instanceof Element &&
        !wrapper.current.contains(event.target)
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div ref={wrapper} {...props}>
      {props.children}
    </div>
  );
};

export default ClickOutside;
