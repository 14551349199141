import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useShareFiles = () => {
  const { shareFile } = getFilesApi();
  const query = useMutation({
    mutationFn: shareFile,
    mutationKey: ['file-share'],
  });

  return query;
};

export default useShareFiles;
