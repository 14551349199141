import { getEnv } from '@app/config/env';
import {
  FilePermissions,
  Group,
  GroupResponse,
  PermissionLevel,
} from '@app/services/groups/types';
import {
  getRangedAccessToken,
  getRangedInstance,
} from '@app/utils/http-instance';
import {
  Paginated,
  ServiceOptions,
  SuccessfulResponse,
} from '@filot/types/api';

import getGroupsMock from './mock';
import { GroupsApi } from './types';

const getGroupsService = ({ url }: ServiceOptions = {}): GroupsApi => {
  const {
    filot: { groups },
  } = getEnv();

  return {
    ...getGroupsMock(),
    get: async (paginationOptions, userId) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      const { data } = await instance.get<Paginated<GroupResponse> | undefined>(
        `${url ?? groups.domain}/api/v1/groups/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: paginationOptions.page,
            page_size: paginationOptions.rowsPerPage,
          },
        }
      );

      if (!data) {
        return {
          data: [],
          message: 'No content',
          pagination: {
            links: {
              self: '',
            },
            page: 0,
            page_size: 0,
            pages: 0,
            total: 0,
          },
          success: true,
        };
      }

      return {
        ...data,
        data: data.data.map((group) => ({
          ...group,
          startDate: group.start_date,
          totalFiles: group.total_files,
          expirationDate: group.expiration_date,
          picture: group.icon_image,
          permission: userId
            ? (group.users[userId] ?? PermissionLevel.MEMBER)
            : PermissionLevel.MEMBER,
          files: [],
        })),
      };
    },

    createGroup: async ({ expirationDate, name, startDate }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      const { data } = await instance.post<SuccessfulResponse<Group>>(
        `${url ?? groups.domain}/api/v1/groups/create`,
        {
          name,
          start_date: startDate,
          expiration_date: expirationDate,
          description: '',
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data.data;
    },

    getDetails: async (id) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      const { data } = await instance.get<SuccessfulResponse<Group>>(
        `${url ?? groups.domain}/api/v1/groups/${id}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data.data;
    },

    updateGroupDetails: async ({ groupId, ...options }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.put(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/modify`,
        {
          name: options.name,
          description: options.description,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    deleteGroup: async (id) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    addFiles: async ({ files, groupId }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      const fileIds = Object.keys(files);

      await instance.post(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/create`,
        {
          files: fileIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removeFile: async ({ fileId, groupId }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            files: [fileId],
          },
        }
      );
    },

    inviteUsers: async ({ emails, groupId }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.post(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/users/create`,
        {
          users: emails,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removeUser: async ({ id, groupId }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/users/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            users: [id],
          },
        }
      );
    },

    leaveGroup: async ({ id }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${id}/leave/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    updateGroupPicture: async ({ file, groupId }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');
      const data = new FormData();
      data.append('image', file);

      await instance.post(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/image`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    changeMemberPermission: async ({ groupId, member, permission }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.patch(
        `${url ?? groups.domain}/api/v1/users/${member}/groups/patch`,
        {
          group_id: groupId,
          role: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    reactivateGroup: async ({
      expirationDate,
      groupId,
      restorePermissions,
    }) => {
      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.put(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/reactivate`,
        {
          restore_permissions: restorePermissions,
          new_expiration_date: expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    updateFilePermissions: async ({ fileId, groupId, newPermissions }) => {
      const permissions = Object.keys(newPermissions).filter(
        (key) => newPermissions[key as keyof FilePermissions]
      );

      const accessToken = await getRangedAccessToken('groups');
      const instance = getRangedInstance('groups');

      await instance.patch(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/update_permissions`,
        {
          file_id: fileId,
          new_permissions: permissions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },
  };
};

export default getGroupsService;
