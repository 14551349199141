import { useQuery } from '@tanstack/react-query';

import { fileResponseToFile, getFilesApi } from '@app/services/files/api';
import { File } from '@app/services/files/types';

const useFileDetails = (fileId: string) => {
  const { getFileDetails } = getFilesApi();

  const query = useQuery({
    queryFn: () => getFileDetails(fileId),
    queryKey: ['fileDetails', fileId],
    select: (data) => {
      const file: File | undefined = data
        ? fileResponseToFile(data.data)
        : undefined;

      return file;
    },
  });

  return query;
};

export default useFileDetails;
