import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import ErrorIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import InfiniteLoadSelector from '@components/infinite-load-select';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import { useOrganizationContext } from '../hooks/useOrganizationContext';
import useOrganizationInfiniteUsers from '../hooks/useOrganizationInfiniteUsers';
import useTransferOrganizationOwnership from '../hooks/useTransferOrganizationOwnership';

interface OrganizationTransferOwnershipModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OrganizationTransferOwnershipModal = ({
  onClose,
  isOpen,
}: OrganizationTransferOwnershipModalProps) => {
  const { t } = useTranslation();
  const organization = useOrganizationContext();

  const withCanClose = useCloseRequestModal(false, () => {});
  const { displayGlobal } = useAlert();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useOrganizationInfiniteUsers(organization.id);
  const {
    isPending,
    isError,
    mutateAsync: transferOwnership,
    reset,
  } = useTransferOrganizationOwnership();

  const { formState, control, handleSubmit } = useForm({
    defaultValues: {
      newOwner: '',
    },
  });

  const transfer = useCallback(
    async ({ newOwner }: { newOwner: string }) => {
      if (organization) {
        await transferOwnership({
          newUserId: newOwner,
          organizationId: organization.id,
        });
        displayGlobal({
          type: 'success',
          text: t(
            'organization.overview.dangerZone.transferOwnership.transferWarningModal.success'
          ),
        });
        onClose();
        await queryClient.invalidateQueries({ queryKey: ['organizations'] });
      }
    },
    [displayGlobal, onClose, organization, queryClient, t, transferOwnership]
  );

  const content = useMemo(() => {
    if (!organization) {
      return null;
    }

    if (isPending) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center">
          <ErrorIcon width={128} height={128} />
          <p className="mx-lg my-sm text-center text-xl">
            <Trans i18nKey="organization.overview.dangerZone.transferOwnership.transferWarningModal.error">
              Oops! There was an error and you didn't delete the organization.
              <span className="cursor-pointer underline" onClick={reset}>
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    const handleLoadMore = async () => {
      if (!isFetching && hasNextPage) {
        await fetchNextPage();
      }
    };

    return (
      <form
        className="px-screen flex flex-col"
        onSubmit={handleSubmit(transfer)}
      >
        <p className="mt-sm text-base">
          {t(
            'organization.overview.dangerZone.transferOwnership.transferWarningModal.description'
          )}
        </p>
        <div className="pt-sm flex flex-1 flex-col">
          <Controller
            name="newOwner"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InfiniteLoadSelector
                variant="standard"
                inputProps={{ className: '!py-2' }}
                {...field}
                isLoading={isLoading || isFetching}
                onEndReached={handleLoadMore}
                options={
                  data?.users.map((user) => ({
                    label: user.name,
                    value: user.user_id,
                  })) ?? []
                }
              />
            )}
          />
        </div>
        <div className="mt-md gap-x-sm flex self-end">
          <Button variant="outlined" onClick={withCanClose(onClose)}>
            {t(
              'organization.overview.dangerZone.transferOwnership.transferWarningModal.cancel'
            )}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
          >
            {t(
              'organization.overview.dangerZone.transferOwnership.transferWarningModal.transfer'
            )}
          </Button>
        </div>
      </form>
    );
  }, [
    control,
    data?.users,
    fetchNextPage,
    formState.isValid,
    handleSubmit,
    hasNextPage,
    isError,
    isFetching,
    isLoading,
    isPending,
    onClose,
    organization,
    reset,
    t,
    transfer,
    withCanClose,
  ]);

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="py-screen mx-auto my-auto !flex w-4/12 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!pr-screen !absolute self-end p-0"
        />
        <Typography className="!mb-sm !pl-screen !text-2xl !font-medium">
          {t(
            'organization.overview.dangerZone.transferOwnership.transferWarningModal.title'
          )}
        </Typography>
        {content}
      </Box>
    </Modal>
  );
};

export default OrganizationTransferOwnershipModal;
