import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { OrganizationUserInviteRequest } from '@app/services/organizations/types';
import { UserResponse } from '@app/services/users/types';
import CalendarIcon from '@assets/organizations/calendar.svg?react';
import UserAvatar from '@components/user-avatar';

interface OrganizationNewUserInformationProps {
  user?: UserResponse;
  newUser: OrganizationUserInviteRequest;
  onInvite: () => void;
  onGoBack: () => void;
}

const OrganizationNewUserInformation = ({
  user: propsUser,
  newUser,
  onInvite,
  onGoBack,
}: OrganizationNewUserInformationProps) => {
  const { t } = useTranslation();

  const user = propsUser ?? newUser.email;

  return (
    <div>
      <div className="mb-md flex flex-1 items-center">
        {typeof user !== 'string' ? (
          <UserAvatar className="mr-sm" user={user} />
        ) : null}
        <div>
          <p className="text-2xl font-bold">
            {typeof user !== 'string' ? user?.name : user}
          </p>
          <p className="text-black/60">
            {typeof user !== 'string' ? user.email : null}
          </p>
        </div>
      </div>
      <div>
        <p className="text-lg">
          {t('organization.users.inviteUsersModal.review.accessPeriod')}
        </p>
        <div className="mb-lg mt-sm flex flex-1 items-center">
          <CalendarIcon />
          <p className="ml-sm text-lg">
            {newUser.accessExpiration
              ? t(
                  'organization.users.inviteUsersModal.review.period_expiration',
                  {
                    grant: DateTime.fromISO(
                      newUser.accessGrant
                    ).toLocaleString(),
                    expiration: DateTime.fromISO(
                      newUser.accessExpiration
                    ).toLocaleString(),
                    interpolation: { escapeValue: false },
                  }
                )
              : t('organization.users.inviteUsersModal.review.period', {
                  grant: DateTime.fromISO(newUser.accessGrant).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
          </p>
        </div>
      </div>
      <div className="float-right">
        <Button onClick={onGoBack} variant="outlined" className="!mr-sm">
          {t('organization.users.inviteUsersModal.review.back')}
        </Button>
        <Button onClick={onInvite} variant="contained">
          {t('organization.users.inviteUsersModal.review.invite')}
        </Button>
      </div>
    </div>
  );
};

export default OrganizationNewUserInformation;
