import {
  AddFilesForm,
  File,
  FilePermissions,
  Group,
  Member,
  NewGroupRequest,
  PermissionLevel,
} from '@app/services/groups/types';
import { Paginated } from '@filot/types/api';

import mock from '../../../../../mock/groups.json';

import { GroupsApi } from './types';

const getGroupsMock = (): GroupsApi => {
  const get = async () =>
    new Promise<Paginated<Group>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: mock.groups as unknown as Group[],
            message: '',
            pagination: {
              links: { self: '' },
              page: 1,
              page_size: 5,
              pages: 1,
              total: 6,
            },
            success: true,
          });
        }
      }, 1000);
    });

  const getDetails = async (id: string) =>
    new Promise<Group>((res, rej) => {
      const groupDetails = mock.groups?.find((group) => group.id === id);
      setTimeout(() => {
        if (!groupDetails) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(groupDetails as unknown as Group);
        }
      }, 1000);
    });

  const updateFilePermissions = (options: {
    groupId: string;
    fileId: string;
    newPermissions: FilePermissions;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        const fileToUpdate = mock.groups
          .find(({ id }) => options.groupId === id)
          ?.files.find(({ id }) => options.fileId === id);
        if (!fileToUpdate || Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          fileToUpdate.permissions = options.newPermissions;
          res();
        }
      }, 1000);
    });

  const removeFile = (options: { groupId: string; fileId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        const fileToUpdate = mock.groups
          .find(({ id }) => options.groupId === id)
          ?.files.find(({ id }) => options.fileId === id);
        if (!fileToUpdate || Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const createGroup = (form: NewGroupRequest) =>
    new Promise<Group>((res, rej) => {
      setTimeout(() => {
        if (form.name.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const newGroup: Group = {
            ...form,
            files: [],
            id: 'fdsafas',
            totalFiles: 0,
            users: { 'Gabriel Yamamoto': PermissionLevel.MEMBER },
            permission: PermissionLevel.ADMIN,
            description: 'new group description',
            expirationDate: undefined,
            status: 'active',
          };
          (mock.groups as unknown as Group[]).push(newGroup);
          res(newGroup);
        }
      }, 1000);
    });

  const getFiles = (options: { groupId: string; page: number }) =>
    new Promise<File[]>((res, rej) => {
      const generateRandomFiles = (num: number): File[] => {
        const files: File[] = [];

        for (let i = 0; i <= num; i++) {
          const extension =
            Math.random() < 0.3 ? '' : Math.random() < 0.3 ? '.txt' : '.pdf';
          const file: File = {
            id: `File ${i}`,
            name: `Document${String.fromCharCode(65 + i)}${extension}`,
            permissions: {
              write: Math.random() < 0.5,
              read: Math.random() < 0.5,
              comment: Math.random() < 0.5,
            },
          };

          files.push(file);
        }

        return files;
      };

      const generatedFile = generateRandomFiles(60);
      setTimeout(() => {
        const group = mock.groups.find(({ id }) => options.groupId === id);
        if (group) {
          if (options.page === 0) {
            res(generatedFile.slice(0, 50));
          } else {
            res(generatedFile.slice(50));
          }
        } else {
          rej(new Error('MOCK_ERROR'));
        }
      }, 1000);
    });

  const bulkEdit = (_: {
    groupId: string;
    fileIds: string[];
    permissions: FilePermissions;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getMembers = (_: string) =>
    new Promise<Member[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(mock.members as Member[]);
        }
      }, 1000);
    });

  const changeMemberPermission = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const userHasAccount = (email: string) =>
    new Promise<boolean>((res, rej) => {
      setTimeout(() => {
        if (email.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else if (email.includes('out')) {
          res(false);
        } else {
          res(true);
        }
      }, 1000);
    });

  const inviteUsers = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const removeUser = ({ id }: { id: string; groupId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.members = mock.members.filter((member) => member.id !== id);
          res();
        }
      }, 1000);
    });

  const addFiles = ({ groupId }: { files: AddFilesForm; groupId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const groupDetails = mock.groups?.find(
            (group) => group.id === groupId
          );
          groupDetails?.files.push({
            id: 'added_file',
            name: 'Added_file.pdf',
            permissions: {
              comment: true,
              read: true,
              write: false,
            },
          });
          res();
        }
      }, 1000);
    });

  const deleteGroup = (groupId: string) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.groups = mock.groups.filter((group) => group.id !== groupId);
          res();
        }
      }, 1000);
    });

  const getGroupsAdmin = () =>
    new Promise<Group[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const groups = (mock.groups as unknown as Group[]).filter(
            (group) =>
              group.permission === PermissionLevel.ADMIN ||
              group.permission === PermissionLevel.OWNER
          );
          res(groups);
        }
      }, 1000);
    });

  const updateGroupDetails = ({
    groupId,
    ...form
  }: {
    groupId: string;
    name?: string;
    description?: string;
    startDate?: string;
    expirationDate?: string | null;
    restorePermissions?: boolean;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.1) {
          rej(new Error('MOCK_ERROR'));
        } else {
          let groupToEdit = (mock.groups as unknown as Group[]).find(
            (group) => group.id === groupId
          );
          if (groupToEdit) {
            groupToEdit = {
              ...groupToEdit,
              ...form,
              expirationDate: form.expirationDate ?? undefined,
            };
          }
          res();
        }
      }, 1000);
    });

  const leaveGroup = ({ id }: { id: string; newOwnerEmail?: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.groups = mock.groups.filter((group) => group.id !== id);
          res();
        }
      }, 1000);
    });

  const updateGroupPicture = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const reactivateGroup = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  return {
    get,
    getDetails,
    updateFilePermissions,
    removeFile,
    createGroup,
    getFiles,
    bulkEdit,
    getMembers,
    changeMemberPermission,
    userHasAccount,
    inviteUsers,
    removeUser,
    addFiles,
    deleteGroup,
    getGroupsAdmin,
    updateGroupDetails,
    leaveGroup,
    updateGroupPicture,
    reactivateGroup,
  };
};

export default getGroupsMock;
