import { PanInfo } from 'framer-motion';
import { useMemo, useRef } from 'react';

import { useBreadcrumb } from '@app/hooks/useBreadcrumb';

export type MoveableElements = {
  fileId: string;
  fileName: string;
  folderId: string;
  folderPath: string;
  clientX?: number;
  clientY?: number;
};

interface UseMoveableToFolderOptions {
  elementId: string;
  elementName: string;
  onFileMove: (elements: MoveableElements) => void;
  onFolderHover: (hoveringFolder: MoveableElements) => void;
}

const useMoveableToFolder = ({
  elementId,
  elementName,
  onFileMove,
  onFolderHover,
}: UseMoveableToFolderOptions) => {
  const hoveringFolder = useRef<MoveableElements | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const { folders } = useBreadcrumb();
  const currentPath = useMemo(() => folders.join('/'), [folders]);

  const handleDrag = (
    event: Pick<PointerEvent, 'clientX' | 'clientY'>,
    info: Pick<PanInfo, 'point'>
  ) => {
    const folderElements = document.querySelectorAll('.folderCard');
    let isInsideOtherElement: Element | null = null;

    folderElements.forEach((element) => {
      if (element.id === elementId) {
        return;
      }
      const folderElementRect = element.getBoundingClientRect();

      if (
        event.clientX <= folderElementRect.right &&
        event.clientX >= folderElementRect.left &&
        event.clientY <= folderElementRect.bottom &&
        event.clientY >= folderElementRect.top
      ) {
        isInsideOtherElement = element;
      }
    });
    if (isInsideOtherElement) {
      if (!timeoutId.current) {
        timeoutId.current = setTimeout(() => {
          if (hoveringFolder.current) {
            onFolderHover(hoveringFolder.current);
            hoveringFolder.current = null;
          }
        }, 1000);
      }

      hoveringFolder.current = {
        fileId: elementId,
        fileName: '',
        folderId: (isInsideOtherElement as Element).id,
        folderPath: `${currentPath}/${(isInsideOtherElement as Element).getAttribute('title')}`,
        clientX: info.point.x,
        clientY: info.point.y,
      };
    } else {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
      hoveringFolder.current = null;
    }
  };

  const handleDragEnd = (event: Pick<PointerEvent, 'clientX' | 'clientY'>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    const folderElements = document.querySelectorAll(
      '.folderCard, .folderListItem'
    );
    let isInsideOtherElement = false;

    folderElements.forEach((element) => {
      if (element.id === elementId) {
        return;
      }
      const folderElementRect = element.getBoundingClientRect();

      if (
        event.clientX <= folderElementRect.right &&
        event.clientX >= folderElementRect.left &&
        event.clientY <= folderElementRect.bottom &&
        event.clientY >= folderElementRect.top
      ) {
        const folderPath = element.classList.contains('folderListItem')
          ? `/${element.getAttribute('title') ?? ''}`
          : `${currentPath}/${element.getAttribute('title')}`;
        onFileMove({
          fileName: elementName,
          fileId: elementId,
          folderId: element.id,
          folderPath,
        });
        isInsideOtherElement = true;
      }
    });

    return isInsideOtherElement;
  };

  return {
    dragging: handleDrag,
    dragEnded: handleDragEnd,
  };
};

export default useMoveableToFolder;
