import { TextField, Button, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { ClipboardEventHandler } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isValidEmail } from '@app/utils/email';

export type OrganizationUserBulkInviteFormInputs = {
  emails: string;
  accessGrant: DateTime;
  accessExpiration: DateTime | null;
};

const OrganizationUserBulkInviteForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, control, formState, watch } =
    useForm<OrganizationUserBulkInviteFormInputs>({
      defaultValues: {
        emails: '',
        accessGrant: DateTime.now(),
        accessExpiration: null,
      },
    });
  const accessGrant = watch('accessGrant');

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = (e) => {
    const clipboard = e.clipboardData.getData('text');
    if (clipboard !== '') {
      navigate('bulk-invite', {
        state: { emails: clipboard },
      });
    }
  };

  const onSubmit = (form: OrganizationUserBulkInviteFormInputs) => {
    navigate('bulk-invite', {
      state: {
        emails: form.emails,
        accessGrant: form.accessGrant.toISODate(),
        accessExpiration: form.accessExpiration?.toISODate() ?? null,
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      role="form"
      className="flex flex-1 flex-col justify-between"
    >
      <div className="flex flex-col">
        <Controller
          name="emails"
          control={control}
          rules={{
            validate: (values) =>
              values.split(',').every((email) => isValidEmail(email)),
          }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              {...field}
              onPaste={handlePaste}
              multiline
              minRows={3}
              label={
                <p>
                  <Trans i18nKey="organization.users.inviteUsersModal.form.emails">
                    Users' e-mail
                    <span className="text-gray-400">(comma-separated)</span>
                  </Trans>
                </p>
              }
              className="!mb-md"
            />
          )}
        />

        <FormControl fullWidth className="mb-4">
          <Controller
            name="accessGrant"
            control={control}
            rules={{
              validate: (value) =>
                value !== null &&
                value.isValid &&
                value.diffNow('days').days > -1,
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  disablePast
                  slotProps={{ textField: { variant: 'standard' } }}
                  label={t(
                    'organization.users.inviteUsersModal.form.accessGrant'
                  )}
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="accessExpiration"
            control={control}
            rules={{
              validate: (value) =>
                value === null ||
                (value.isValid && accessGrant.diff(value, 'days').days < 0),
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  minDate={accessGrant}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: t(
                        'organization.users.inviteUsersModal.form.expiration.message'
                      ),
                    },
                  }}
                  label={
                    <p>
                      <Trans i18nKey="organization.users.inviteUsersModal.form.expiration.label">
                        Access expiration
                        <span className="text-gray-400">(optional)</span>
                      </Trans>
                    </p>
                  }
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
        disabled={!formState.isValid}
      >
        {t('organization.users.inviteUsersModal.form.review')}
      </Button>
    </form>
  );
};

export default OrganizationUserBulkInviteForm;
