import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import Loading from '@components/loading.tsx';

import App from './App.tsx';
import { initSentry } from './config/sentry.ts';
import { login, postLogin } from './services/auth/login.ts';
import { isRunningOnElectron } from './utils/environment.ts';

import './config/i18n.ts';
import './index.css';

initSentry();
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const startApp = (root?: ReactDOM.Root) => {
  void postLogin();
  if (root) {
    return root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }

  return ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

if (isRunningOnElectron()) {
  startApp();
  window.appWindow.removeLoading();
  window.appWindow.maximize();
} else {
  const root = ReactDOM.createRoot(document.getElementById('root')!);
  root.render(
    <React.StrictMode>
      <div className="flex h-full w-full items-center justify-center">
        <Loading />
      </div>
    </React.StrictMode>
  );
  void login(() => startApp(root));
}
