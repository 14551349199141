import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import RightChevron from '@assets/files/right_chevron.svg?react';

/**
 * This const checks whether a point (x, y) is on the left or right side of a line formed by two points
 * (px, py) and (qx, qy).
 * @param {number} px first point x
 * @param {number} py  first point y
 * @param {number} qx second point x
 * @param {number} qy second point y
 * @param {number} rx point to check x
 * @param {number} ry point to check y
 * @returns {number} If the result is negative, the point is on the right side of the line. If positive,
 * it's on the left side. It helps us determine if a point is on the same side as a vertex of the triangle
 * when compared to its edges.
 */
const sign = (
  px: number,
  py: number,
  qx: number,
  qy: number,
  rx: number,
  ry: number
): number => (px - rx) * (qy - ry) - (qx - rx) * (py - ry);

// This const checks if a point (x, y) is inside a triangle formed by three points (x1, y1), (x2, y2), and (x3, y3).
const pointInTriangle = (
  currentMouseCoordinates: number[],
  triangleCoordinates: number[][]
) => {
  const [[x1, y1], [x2, y2], [x3, y3]] = triangleCoordinates;
  const [x, y] = currentMouseCoordinates;

  const b1 = sign(x, y, x1, y1, x2, y2) <= 0;
  const b2 = sign(x, y, x2, y2, x3, y3) <= 0;
  const b3 = sign(x, y, x3, y3, x1, y1) <= 0;

  // If all signs are the same (either all negative or all positive), the point is inside the triangle.
  return b1 === b2 && b2 === b3;
};

type Option = {
  text: string;
  icon: ReactNode;
  onClick?: () => void;
  separator?: boolean;
  menuLevel: number;
  nestedOptions?: Option[];
  disabled?: boolean;
};

export type Item = {
  text: string;
  icon: ReactNode;
  onClick?: () => void;
  separator?: boolean;
  nestedOptions?: Array<Omit<Item, 'menuLevel'>>;
  disabled?: boolean;
};

type NestedMenuProps = {
  items: Array<Omit<Item, 'menuLevel'>>;
  anchorEl: HTMLElement | null;
};

const NestedMenu = ({ items, anchorEl }: NestedMenuProps) => {
  const populateItems = useCallback(
    (itemsToPopulate: Item[], currentLevel: number = 0): Option[] =>
      itemsToPopulate.map((item) => ({
        ...item,
        menuLevel: currentLevel,
        nestedOptions: item.nestedOptions
          ? populateItems(item.nestedOptions, currentLevel + 1)
          : undefined,
      })),
    []
  );
  const options = useMemo(() => populateItems(items), [items, populateItems]);

  const calculateMenuLevels = useCallback(
    (menuItems: Option[], currentLevel: number = 0): number => {
      let maxLevel = currentLevel;

      menuItems.forEach((item) => {
        // Update the menuLevel
        item.menuLevel = currentLevel;

        // If the item has nestedOptions, recursively calculate their levels
        if (item.nestedOptions && item.nestedOptions.length > 0) {
          // Calculate the levels of nested items and update maxLevel
          const nestedLevel = calculateMenuLevels(
            item.nestedOptions,
            currentLevel + 1
          );
          maxLevel = Math.max(maxLevel, nestedLevel);
        }
      });

      return maxLevel + 1;
    },
    []
  );

  const menuLevels = useMemo(
    () => calculateMenuLevels(options),
    [calculateMenuLevels, options]
  );

  const [anchors, setAnchors] = useState<{
    elements: Array<null | HTMLElement>;
    options: Array<null | Option[]>;
  }>({
    elements: new Array(menuLevels).fill(null) as null[],
    options: new Array(menuLevels).fill(null) as null[],
  });

  const mouseEntered = useRef<Record<string, boolean>>({});
  const mouseLeftCoordinates = useRef<number[]>([]);
  const mouseIdleTimer = useRef<NodeJS.Timeout | null>(null);

  const handleOpen = useCallback(
    (target: HTMLElement, level = 0, nestedOptions = options) => {
      setAnchors((prevAnchors) => ({
        elements: prevAnchors.elements.map((element, index) =>
          index === level ? target : element
        ),
        options: prevAnchors.options.map((element, index) =>
          index === level ? nestedOptions : element
        ),
      }));
    },
    [options]
  );

  useEffect(() => {
    if (anchorEl) {
      handleOpen(anchorEl, 0, options);
    }
  }, [anchorEl, handleOpen, options]);

  const handleClose = (level: number) => {
    setAnchors((prevAnchors) => ({
      elements: prevAnchors.elements.map((element, index) =>
        index >= level ? null : element
      ),
      options: prevAnchors.options.map((element, index) =>
        index >= level ? null : element
      ),
    }));
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if ('button' in event && event.button === 2) {
      handleClose(0);
    }

    const optionWithoutNestedMenu = anchors.elements.every(
      (element) => !event.composedPath().includes(element!)
    );

    if (optionWithoutNestedMenu) {
      // handleClose(0);
    }
  };

  const handleClickOption = (option: Option) => {
    if (!option.nestedOptions) {
      handleClose(0);
    }
    option.onClick?.();
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option,
    optIndex: number
  ) => {
    let shouldComputeNestedMenuOpenLogic = true;
    const nestedMenu = document.querySelector(
      `#nested-menu-${option.menuLevel + 1}`
    );

    const computeNestedMenuLogic = () => {
      if (!mouseEntered.current[getId(option, optIndex)]) {
        mouseEntered.current[getId(option, optIndex)] = true;
        if (!option.nestedOptions) {
          handleClose(option.menuLevel + 1);
        } else if (
          option.nestedOptions &&
          anchors.options[option.menuLevel + 1] &&
          !option.nestedOptions.every(
            (val, i) =>
              val.text === anchors.options[option.menuLevel + 1]?.[i].text
          )
        ) {
          handleClose(option.menuLevel + 1);
          handleOpen(
            event.target as HTMLElement,
            option.menuLevel + 1,
            option.nestedOptions
          );
        } else {
          handleOpen(
            event.target as HTMLElement,
            option.menuLevel + 1,
            option.nestedOptions
          );
        }
      }
    };

    if (mouseLeftCoordinates.current.length > 0 && nestedMenu) {
      const { x, y, height } = nestedMenu.getBoundingClientRect();

      const currentMouseCoordinates = [event.clientX, -event.clientY];
      const virtualTriangleCoordinates = [
        [x, -y],
        [x, -(y + height)],
        [mouseLeftCoordinates.current[0], mouseLeftCoordinates.current[1]],
      ];

      if (
        pointInTriangle(currentMouseCoordinates, virtualTriangleCoordinates)
      ) {
        shouldComputeNestedMenuOpenLogic = false;
        if (mouseIdleTimer.current) {
          clearTimeout(mouseIdleTimer.current);
        }

        // if mouse is inside triangle and yet hasn't moved, we need to compute nestedMenu logic after a delay
        mouseIdleTimer.current = setTimeout(() => {
          computeNestedMenuLogic();
        }, 50);
      } else {
        shouldComputeNestedMenuOpenLogic = true;
      }
    }

    if (shouldComputeNestedMenuOpenLogic) {
      if (mouseIdleTimer.current) {
        clearTimeout(mouseIdleTimer.current);
      }
      computeNestedMenuLogic();
    }
  };

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option,
    optIndex: number
  ) => {
    mouseLeftCoordinates.current = [event.clientX, -event.clientY];

    if (mouseIdleTimer.current) {
      clearInterval(mouseIdleTimer.current);
    }
    mouseEntered.current[getId(option, optIndex)] = false;
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    option: Option
  ) => {
    if (option.nestedOptions) {
      if (event.key === 'ArrowRight' || event.key === 'Enter') {
        handleOpen(
          event.target as HTMLElement,
          option.menuLevel + 1,
          option.nestedOptions
        );
      }
    }
    if (event.key === 'ArrowLeft' && option.menuLevel > 0) {
      handleClose(option.menuLevel);
      anchors.elements[option.menuLevel]?.focus();
    }

    if (event.key === 'Escape') {
      handleClose(0);
    }
  };

  const getId = (option: Option, index: number) =>
    `${index}-${option.menuLevel}`;

  return anchors.elements.map((anchorElement, index) =>
    anchorElement ? (
      <Popper
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        key={`${anchorElement.innerText} menu`}
        role="menu"
        placement={index > 0 ? 'right-start' : 'bottom-start'}
        transition
        className="!z-[100]"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={handleClickAway}
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
              >
                <MenuList
                  autoFocusItem={Boolean(anchorElement)}
                  id={`nested-menu-${index}`}
                  aria-labelledby="nested-button"
                >
                  {(anchors.options[index] ?? []).map((option, optIndex) =>
                    option.separator ? (
                      <Divider className="!my-0" key={option.text} />
                    ) : (
                      <MenuItem
                        key={option.text}
                        aria-haspopup={
                          Array.isArray(option.nestedOptions) ?? undefined
                        }
                        aria-expanded={
                          option.nestedOptions
                            ? anchors.elements.some(
                                (element) => element?.innerText === option.text
                              )
                            : undefined
                        }
                        onClick={() => handleClickOption(option)}
                        onMouseMove={(event) =>
                          handleMouseMove(event, option, optIndex)
                        }
                        onMouseLeave={(event) =>
                          handleMouseLeave(event, option, optIndex)
                        }
                        onKeyDown={(event) => handleKeyDown(event, option)}
                        disabled={option.disabled}
                      >
                        <div className="flex items-center gap-x-3">
                          {option.icon}
                          <Typography>{option.text}</Typography>
                          {option.nestedOptions ? (
                            <RightChevron width={12} height={12} />
                          ) : null}
                        </div>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    ) : null
  );
};

export default NestedMenu;
