import { useMutation } from '@tanstack/react-query';

import { getNotesApi } from '@app/services/notes/api';

const useCreateNote = () => {
  const { createNote } = getNotesApi();
  const query = useMutation({
    mutationFn: createNote,
    mutationKey: ['create-note'],
  });

  return query;
};

export default useCreateNote;
