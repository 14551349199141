import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useOrganizationUsers = (organizationId: string, page: number) => {
  const { getUsers } = getOrganizationApi();

  const query = useQuery({
    queryFn: () => getUsers(organizationId, page),
    queryKey: ['organization-users', organizationId, page],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useOrganizationUsers;
