import { getParamsFromHash } from '@filot/auth';

const RangedCallback = () => {
  const hashString = location.hash;
  const hash = getParamsFromHash(hashString);

  if (hash.access_token) {
    window.parent.postMessage({ accessToken: hash.access_token });
  } else if (hash.error) {
    window.parent.postMessage({ error: hash.error });
  }

  return <></>;
};

export default RangedCallback;
