import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import useDebounce from '@app/hooks/useDebounce';
import { File, Group } from '@app/services/groups/types';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import useUpdateFilePermissions from './useUpdateFilePermissions';

interface UseManageUpdateFilePermissionsOptions {
  watch: UseFormWatch<{
    read: boolean;
    write: boolean;
    comment: boolean;
  }>;
  file: File;
  group: Group;
}

const useManageUpdateFilePermissions = ({
  watch,
  file,
  group,
}: UseManageUpdateFilePermissionsOptions) => {
  const [safeValues, setSafeValues] = useState(file.permissions);
  const watchRead = watch('read');
  const watchWrite = watch('write');
  const watchComment = watch('comment');
  const { canEditFiles } = useGroupPermissions(group.permission);

  const {
    mutateAsync: updateFilePermissions,
    isError: isUpdateFilePermissionError,
    isSuccess: isUpdateFilePermissionSuccess,
    reset: resetUpdateFilePermission,
  } = useUpdateFilePermissions();

  const debounce = useDebounce({
    value: JSON.stringify([watchRead, watchWrite, watchComment]),
    delay: 1000,
  });
  const [read, write, comment] = JSON.parse(debounce) as [
    boolean,
    boolean,
    boolean,
  ];

  useEffect(() => {
    if (isUpdateFilePermissionSuccess) {
      setSafeValues({ comment, read, write });
      resetUpdateFilePermission();
    }
  }, [
    comment,
    isUpdateFilePermissionSuccess,
    read,
    resetUpdateFilePermission,
    write,
  ]);

  useEffect(() => {
    if (!canEditFiles()) {
      return;
    }
    if (
      read !== file.permissions.read ||
      write !== file.permissions.write ||
      comment !== file.permissions.comment
    ) {
      void updateFilePermissions({
        groupId: group.id,
        fileId: file.id,
        newPermissions: {
          comment,
          write,
          read,
        },
      });
    }
  }, [
    comment,
    file.id,
    file.permissions.comment,
    file.permissions.read,
    file.permissions.write,
    updateFilePermissions,
    read,
    write,
    canEditFiles,
    group.id,
  ]);

  return {
    safeValues,
    isUpdateFilePermissionError,
    resetUpdateFilePermission,
  };
};

export default useManageUpdateFilePermissions;
