import { Range } from '@filot/types/api';
import { Env } from '@filot/types/env';

interface RangedAuthConfig {
  audience: string;
}

const configs: Record<Range, RangedAuthConfig> = {
  notes: {
    audience: 'https://notes.api.filot.io/',
  },
  organization: {
    audience: 'https://organizations.api.filot.io/',
  },
  groups: {
    audience: 'https://groups.api.filot.io/',
  },
  files: {
    audience: 'https://files.api.filot.io/',
  },
  users: {
    audience: 'https://users.api.filot.io/',
  },
  tags: {
    audience: 'https://tags.api.filot.io/',
  },
};
interface GetRangedAuthorizationUrlConfig {
  range: Range;
  env: Env;
  callbackUrl: string;
}

export const getRangedAuthorizationUrl = ({
  callbackUrl,
  env,
  range,
}: GetRangedAuthorizationUrlConfig) => {
  const { auth0 } = env;

  return (
    `${auth0.domain}/authorize?` +
    `audience=${configs[range].audience}&` +
    'response_type=token&' +
    'prompt=none&' +
    `client_id=${auth0.clientId}&` +
    `redirect_uri=${callbackUrl}`
  );
};

export const getParamsFromHash = (
  hash: string | null
): { [key: string]: string } => {
  const params: { [key: string]: string } = {};

  if (!hash) {
    return {};
  }

  // Remove the leading '#' if it exists
  if (hash.startsWith('#')) {
    hash = hash.substring(1);
  }

  // Split the hash string into key-value pairs
  const pairs = hash.split('&');

  // Iterate over each pair and split into key and value
  for (const pair of pairs) {
    const [key, value] = pair.split('=');

    // Decode the key and value and store in the params object
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return params;
};
