import { Box, Button, List, ListItem, Modal, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareFileRequest } from '@app/services/files/types';
import EditIcon from '@assets/files/edit.svg?react';
import CalendarIcon from '@assets/organizations/calendar.svg?react';
import Close from '@assets/shared/close.svg?react';
import FileIcon from '@components/file-icon';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

import { FileDetailsContext } from './file-details';
import ShareFileAccessRenderer from './share-file-access-renderer';

interface ShareFileConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onGoBack: () => void;
  shareParams: ShareFileRequest;
}

const ShareFileConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  shareParams,
  onGoBack,
}: ShareFileConfirmationModalProps) => {
  const { t } = useTranslation();
  const fileDetails = useContext(FileDetailsContext);

  const Content = () => {
    if (fileDetails) {
      return (
        <>
          <div className="flex flex-1 items-center justify-between">
            <Typography className="!text-2xl !font-medium">
              {t('fileList.fileDetails.inviteUsersModal.title')}
            </Typography>
          </div>
          <div className="mt-md">
            <div className="flex items-center justify-between">
              <div className="flex">
                <FileIcon type={fileDetails.type} width={35} height={35} />
                <Typography variant="h2" className="!ml-sm !text-2xl">
                  {fileDetails.fileName}
                </Typography>
              </div>
              <ShareFileAccessRenderer access={shareParams.access} />
            </div>
            <List className="!mt-md max-h-[25vh] overflow-y-auto border border-gray-300">
              {shareParams.users.map((user) => (
                <ListItem key={user} className="!px-md !py-sm !items-center">
                  <Typography>{user}</Typography>
                </ListItem>
              ))}
            </List>
            <div className="my-lg flex flex-1 items-center">
              <CalendarIcon width={18} height={18} />
              <p className="mx-sm text-lg">
                {shareParams.expirationDate
                  ? t(
                      'fileList.fileDetails.shareFileConfirmation.period_expiration',
                      {
                        grant: DateTime.fromISO(
                          shareParams.startDate
                        ).toLocaleString(),
                        expiration: DateTime.fromISO(
                          shareParams.expirationDate
                        ).toLocaleString(),
                        interpolation: { escapeValue: false },
                      }
                    )
                  : t('fileList.fileDetails.shareFileConfirmation.period', {
                      grant: DateTime.fromISO(
                        shareParams.startDate
                      ).toLocaleString(),
                      interpolation: { escapeValue: false },
                    })}
              </p>
              <PressableIcon
                icon={<EditIcon width={16} height={16} title="edit-dates" />}
                onClick={onGoBack}
              />
            </div>
            <div className="gap-x-sm flex justify-end">
              <Button variant="outlined" onClick={onGoBack}>
                {t('fileList.fileDetails.shareFileConfirmation.back')}
              </Button>
              <Button variant="contained" onClick={onSubmit}>
                {t('fileList.fileDetails.shareFileConfirmation.share')}
              </Button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <StopClickPropagation>
        <Box
          className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
          id="share-file-confirmation"
        >
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          <Content />
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default ShareFileConfirmationModal;
