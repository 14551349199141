/* v8 ignore start */
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import useContextMenu from '@app/hooks/useContextMenu';
import useErrorHandler from '@app/hooks/useErrorHandler';

import useCreateFolder from '../hooks/useCreateFolder';

import NewFolderModal from './new-folder-modal';

interface FileListContextMenuProps {
  anchorElement: HTMLElement | null;
}

const FileListContextMenu = ({ anchorElement }: FileListContextMenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mutateAsync, isPending, isError, reset } = useCreateFolder();
  const [isCreateFolderVisible, setIsCreateFolderVisible] = useState(false);
  const queryClient = useQueryClient();
  const { display, Alert } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      display();
      reset();
    },
  });
  const { setStep, updateStageConfig, pause, resume } = useAppJoyride();
  updateStageConfig(2, 1, {
    onNext: () => {
      setIsCreateFolderVisible(true);
      pause();
    },
  });

  const { ContextMenu } = useContextMenu(anchorElement, {
    items: [
      {
        onClick: () => {
          setIsCreateFolderVisible(true);
          setStep(2);
        },
        text: t('fileList.fileListContextMenu.createFolder'),
        icon: <></>,
      },
    ],
  });

  useEffect(() => {
    if (isCreateFolderVisible) {
      setTimeout(() => {
        /* v8 ignore next */
        resume();
      }, 400);
    }
  }, [isCreateFolderVisible, resume]);

  const handleCreateFolder = async ({ folderName }: { folderName: string }) => {
    setIsCreateFolderVisible(false);
    const match = location.pathname.match(/^\/files(\/.*)?$/);
    const currentLocation = match?.at(1) ?? '/';
    if (!isPending) {
      await mutateAsync({ folderName, location: currentLocation });
      await queryClient.invalidateQueries({ queryKey: ['files'] });
    }
  };

  return (
    <>
      <ContextMenu />
      <NewFolderModal
        isOpen={isCreateFolderVisible}
        onClose={() => setIsCreateFolderVisible(false)}
        onSubmit={handleCreateFolder}
      />
      <Alert severity="error">
        {t('fileList.fileListContextMenu.newFolderModal.error')}
      </Alert>
    </>
  );
};

export default FileListContextMenu;
