import { useMutation } from '@tanstack/react-query';

import { getUsersApi } from '@app/services/users/api';

const useUpdateProfile = () => {
  const { update } = getUsersApi();

  const mutation = useMutation({
    mutationFn: update,
    mutationKey: ['user-update'],
  });

  return mutation;
};

export default useUpdateProfile;
