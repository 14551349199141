import {
  Dispatch,
  ProviderProps,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { File } from '@app/services/files/types';

type DraggingFile = {
  initialX: number;
  initialY: number;
  file: File;
};

export const DraggingFileContext = createContext<null | DraggingFile>(null);
export const DraggingFileUpdateContext = createContext<
  Dispatch<SetStateAction<DraggingFile | null>>
>(() => {});

export const DraggingFileContextProvider = ({
  children,
}: Omit<ProviderProps<string[]>, 'value'>) => {
  const [draggingFile, setDraggingFile] = useState<null | DraggingFile>(null);

  return (
    <DraggingFileContext.Provider value={draggingFile}>
      <DraggingFileUpdateContext.Provider value={setDraggingFile}>
        {children}
      </DraggingFileUpdateContext.Provider>
    </DraggingFileContext.Provider>
  );
};

export const useDraggingFile = () => useContext(DraggingFileContext);
export const useUpdateDraggingFile = () =>
  useContext(DraggingFileUpdateContext);
