import { MenuItem, Select, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@app/hooks/useAuth';
import useUser from '@app/hooks/useUser';
import { FileAccessLevel } from '@app/services/files/types';
import WarningIcon from '@assets/shared/warning.svg?react';
import Loading from '@components/loading';
import UserAvatar from '@components/user-avatar';
import useUserByEmail from '@pages/organizations/hooks/useUserByEmail';

import useRevokeSharingPermissions from '../hooks/useRevokeSharingPermissions';
import useUpdateSharingPermissions from '../hooks/useUpdateSharingPermissions';

import { FileDetailsContext } from './file-details';

interface ManageAccessPeoplePersonProps {
  email?: string;
  /** User ID to get the information */
  userId?: string;
}

const ManageAccessPeoplePerson = ({
  email,
  userId,
}: ManageAccessPeoplePersonProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = useUserByEmail(email!, {
    enabled: email !== undefined,
    staleTime: 60 * 5 * 1000,
    retryOnMount: false,
  });
  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUser(userId, userId !== undefined);
  const { user: currentUser } = useAuth();
  const { mutateAsync: revokeSharingPermissions, isPending: isRevokePending } =
    useRevokeSharingPermissions();
  const { mutateAsync: updateSharingPermissions, isPending: isUpdatePending } =
    useUpdateSharingPermissions();
  const file = useContext(FileDetailsContext);
  const [currentPermission, setCurrentPermission] = useState('viewer');
  const queryClient = useQueryClient();
  const userData = user ?? data;

  const renderName = useCallback(
    (name: string, id: string) => {
      if (id === currentUser?.sub) {
        return t('fileList.fileDetails.manageAccessModal.form.people.youUser', {
          name,
        });
      }

      return name;
    },
    [t, currentUser?.sub]
  );

  if (isLoading || isLoadingUser) {
    return (
      <div className="py-sm px-screen flex flex-1 items-center justify-between">
        <p className="max-w-[80%] overflow-hidden text-ellipsis">
          {email ?? userId}
        </p>
        <Loading size={24} />
      </div>
    );
  }

  if (isError || isErrorUser) {
    return (
      <div className="py-sm px-screen flex flex-1 items-center justify-between">
        <p className="max-w-[80%] overflow-hidden text-ellipsis">
          {email ?? userId}
        </p>
        <Tooltip title={error?.message} arrow>
          <div className="flex items-center justify-center rounded-full bg-red-200 p-2">
            <WarningIcon title="warning" className="text-red-600" />
          </div>
        </Tooltip>
      </div>
    );
  }

  if (userData) {
    const handleChangePermission = async (
      newPermission: FileAccessLevel | 'revoke'
    ) => {
      if (!file) {
        return;
      }
      if (newPermission === 'revoke') {
        await revokeSharingPermissions({
          fileId: file.id,
          userId: userData.user_id,
        });
        await queryClient.invalidateQueries({
          queryKey: ['files-shared', file.id],
        });
      } else {
        await updateSharingPermissions({
          fileId: file.id,
          permission: newPermission,
          userId: userData.user_id,
        });
        setCurrentPermission(newPermission);
      }
    };

    return (
      <div className="px-screen flex justify-between">
        <div className="gap-x-sm flex items-center">
          <UserAvatar user={userData} />
          <div className="flex flex-col justify-between">
            <p className="font-medium">
              {renderName(userData.name, userData.user_id)}
            </p>
            <p className="text-sm text-gray-950/60">{userData.email}</p>
          </div>
        </div>
        <Select
          value={currentPermission}
          onChange={(e) =>
            handleChangePermission(e.target.value as FileAccessLevel | 'revoke')
          }
          variant="outlined"
          sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          disabled={isRevokePending || isUpdatePending}
        >
          <MenuItem value="viewer">Viewer</MenuItem>
          <MenuItem value="editor">Editor</MenuItem>
          <MenuItem value="commenter">Commenter</MenuItem>
          <MenuItem value="revoke">Revoke access</MenuItem>
        </Select>
      </div>
    );
  }
};

export default ManageAccessPeoplePerson;
