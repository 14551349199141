import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';

const StopClickPropagation = forwardRef<unknown, PropsWithChildren>(
  ({ children }, _ref) =>
    Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child as ReactElement, {
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
            if ('onClick' in child.props) {
              (
                child.props as { onClick: (e: React.MouseEvent) => void }
              ).onClick(e);
            }
          },
        });
      }

      return child;
    })
);

export default StopClickPropagation;
