import { Controller, useForm } from 'react-hook-form';

import ShareFileUserInput from './share-file-user-input';

interface ManageAccessFormProps {
  onEmailType: (email: string) => void;
}

const ManageAccessForm = ({ onEmailType }: ManageAccessFormProps) => {
  const { control } = useForm({
    defaultValues: {
      people: '',
    },
  });

  return (
    <form className="mt-md px-screen">
      <Controller
        control={control}
        name="people"
        rules={{
          validate: (value) => value.length > 0,
        }}
        render={({ field }) => (
          <ShareFileUserInput
            {...field}
            onEmailType={onEmailType}
            onGroupSelected={() => {}}
          />
        )}
      />
    </form>
  );
};

export default ManageAccessForm;
