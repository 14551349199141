import { Box, Button, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { User } from '@app/services/organizations/types';
import WarningIcon from '@assets/groups/warning.svg?react';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

interface RemoveUserFromOrganizationConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onSubmit: () => void;
  onGoBack: () => void;
  onTryAgain: () => void;
  isError: boolean;
  isPending: boolean;
}

const RemoveUserFromOrganizationConfirmationModal = ({
  isOpen,
  onClose,
  user,
  onSubmit,
  onGoBack,
  isError,
  isPending,
  onTryAgain,
}: RemoveUserFromOrganizationConfirmationModalProps) => {
  const { t } = useTranslation();
  const withCanClose = useCloseRequestModal(isPending);

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-xl font-medium">
            <Trans i18nKey="organization.users.table.removeConfirmation.error">
              Oops! There was an error removing the user from organization.
              <span className="cursor-pointer underline" onClick={onTryAgain}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        <div className="mt-md flex flex-1 justify-center">
          <WarningIcon width={120} height={120} />
        </div>
        <p className="my-md text-center text-2xl font-medium">
          {t('organization.users.table.removeConfirmation.text', {
            name: `${user.name}`,
          })}
        </p>
        <div className="gap-x-sm flex self-end">
          <Button variant="outlined" onClick={() => onGoBack()}>
            {t('organization.users.table.removeConfirmation.cancel')}
          </Button>
          <Button variant="contained" onClick={() => onSubmit()}>
            {t('organization.users.table.removeConfirmation.remove')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      className="flex"
      id="tag-editor"
      open={isOpen}
      onClose={withCanClose(onClose)}
    >
      <Box className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('organization.users.table.removeConfirmation.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default RemoveUserFromOrganizationConfirmationModal;
