import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppLocation } from '@app/hooks/useAppLocation';
import { useBreadcrumb } from '@app/hooks/useBreadcrumb';
import {
  useDraggingFile,
  useUpdateDraggingFile,
} from '@app/hooks/useDraggingFile';
import useMoveFile from '@app/hooks/useMoveFile';
import { File } from '@app/services/files/types';
import FileCard from '@components/file-card';
import FollowMouse from '@components/follow-mouse';
import FolderCard from '@pages/files/components/folder-card';
import { MoveableElements } from '@pages/files/hooks/useMoveableToFolder';

interface UseDragFilesOptions {
  refetch: () => Promise<void>;
  files: File[] | undefined;
}

const useDragFiles = ({ files, refetch }: UseDragFilesOptions) => {
  const { mutateAsync: moveFiles } = useMoveFile();
  const setDraggingFile = useUpdateDraggingFile();
  const navigate = useNavigate();
  const { folders } = useBreadcrumb();
  const draggingFile = useDraggingFile();
  const location = useAppLocation();

  useEffect(() => {
    const handleMouseup = async (event: MouseEvent) => {
      const folder = folders.at(-1);
      const elementAtPointer = document.elementFromPoint(
        event.clientX,
        event.clientY
      );

      if (
        draggingFile?.file.id &&
        folder &&
        (!elementAtPointer?.classList.contains('folderCard') ||
          elementAtPointer?.id === draggingFile.file.id)
      ) {
        setDraggingFile(null);
        await moveFiles({
          fileId: draggingFile.file.id,
          newPath: `${folders.join('/')}/${draggingFile.file.fileName}`,
        });
        await refetch();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('mouseup', handleMouseup);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      document.removeEventListener('mouseup', handleMouseup);
    };
  }, [
    draggingFile?.file.id,
    draggingFile?.file.fileName,
    folders,
    location.state?.folderId,
    moveFiles,
    refetch,
    setDraggingFile,
  ]);

  const handleFileMove = async (elements: MoveableElements) => {
    setDraggingFile(null);
    await moveFiles({
      fileId: elements.fileId,
      newPath: `${elements.folderPath}/${elements.fileName}`,
    });
    await refetch();
  };

  const handleNavigate = ({
    folderId,
    fileId,
    clientX,
    clientY,
  }: MoveableElements) => {
    const folder = document.getElementById(folderId);
    const fileToDrag = files?.find((file) => file.id === fileId);
    if (fileToDrag && clientX && clientY) {
      setDraggingFile({
        initialX: clientX,
        initialY: clientY,
        file: fileToDrag,
      });
    }
    if (folder?.innerText) {
      navigate(`${location.pathname}/${folder.innerText}`, {
        state: { folderId },
      });
    }
  };

  const DraggingFile = () => {
    if (!draggingFile || !draggingFile.file) {
      return null;
    }
    if (draggingFile.file.type === 'folder') {
      return (
        <FollowMouse
          initialX={draggingFile.initialX}
          initialY={draggingFile.initialY}
        >
          <FolderCard folder={draggingFile?.file} />
        </FollowMouse>
      );
    }

    return (
      <FollowMouse
        initialX={draggingFile.initialX}
        initialY={draggingFile.initialY}
      >
        <FileCard file={draggingFile?.file} />
      </FollowMouse>
    );
  };

  return {
    onFileMove: handleFileMove,
    onNavigate: handleNavigate,
    DraggingFile,
  };
};

export default useDragFiles;
