import { TablePagination as MUITablePagination, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TablePaginationProps {
  page: number;
  onPageChange: (newPage: number) => void;
  total: number;
  isPlaceholderData?: boolean;
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  rowsPerPage?: number;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
  colSpan?: number;
}

const TablePagination = ({
  page,
  onPageChange,
  isPlaceholderData = false,
  total,
  rowsPerPageOptions = [],
  rowsPerPage = 5,
  onRowsPerPageChange,
  colSpan = 5,
}: TablePaginationProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <MUITablePagination
        colSpan={colSpan}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={(event) =>
          onRowsPerPageChange?.(parseInt(event.target.value, 10))
        }
        onPageChange={(_, newPage) => onPageChange(newPage)}
        labelRowsPerPage={t('components.tablePagination.rows')}
        labelDisplayedRows={(paginationInfo) =>
          t('components.tablePagination.records', {
            first: paginationInfo.from,
            last: paginationInfo.to,
            total: paginationInfo.count,
          })
        }
        slotProps={{
          actions: {
            nextButton: {
              disabled: isPlaceholderData || (page + 1) * rowsPerPage >= total,
            },
          },
        }}
      />
    </TableRow>
  );
};

export default TablePagination;
