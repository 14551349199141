import { useQuery } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

interface UseWorkingVersionsOptions {
  fileId: string;
}

const useWorkingVersions = ({ fileId }: UseWorkingVersionsOptions) => {
  const { getWorkingVersions } = getFilesApi();
  const query = useQuery({
    queryFn: () => getWorkingVersions(fileId),
    queryKey: ['files-versions-workingVersions', fileId],
  });

  return query;
};

export default useWorkingVersions;
