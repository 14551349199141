import { getEnv } from '@app/config/env';

import getUsersMock from './mock';
import getUsersService from './service';
import { UsersApi } from './types';

const getUsersApi = (): UsersApi => {
  if (getEnv().mockApiCalls) {
    return getUsersMock();
  }

  return getUsersService();
};

export { getUsersApi };
