import { useTranslation } from 'react-i18next';

import LockIcon from '@assets/files/unlock.svg?react';

const ManageAccessGeneralAccess = () => {
  const { t } = useTranslation();

  return (
    <div className="px-screen">
      <p className="text-xl">
        {t('fileList.fileDetails.manageAccessModal.form.generalAccess.title')}
      </p>
      <div className="gap-x-sm my-sm flex items-center">
        <div className="flex items-center justify-center rounded-full bg-slate-400/30 p-2">
          <LockIcon width={18} height={18} className="text-black/70" />
        </div>
        <div className="flex flex-col">
          <p className="font-medium">Restricted</p>
          <p className="text-sm text-black/60">
            Only people with access can open with the link
          </p>
        </div>
      </div>
    </div>
  );
};

export default ManageAccessGeneralAccess;
