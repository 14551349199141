import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { AddFilesForm, File } from '@app/services/groups/types';
import FileIcon from '@components/file-icon';

interface GroupAddFilesPermissionTableRowProps {
  file: File;
}

const GroupAddFilesPermissionTableRow = ({
  file,
}: GroupAddFilesPermissionTableRowProps) => {
  const { control } = useFormContext<AddFilesForm>();

  return (
    <TableRow key={file.name}>
      <TableCell>
        <div className="flex items-center gap-sm">
          <FileIcon type={file.type!} width={26} height={26} />
          <Typography>{file.name}</Typography>
        </div>
      </TableCell>
      <TableCell className="!text-center">
        <Controller
          name={`${file.id}.permissions.read`}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
      </TableCell>
      <TableCell className="!text-center">
        <Controller
          name={`${file.id}.permissions.write`}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
      </TableCell>
      <TableCell className="!text-center">
        <Controller
          name={`${file.id}.permissions.comment`}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

export default GroupAddFilesPermissionTableRow;
