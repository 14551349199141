import { useState, useEffect } from 'react';

import NestedMenu, { Item } from '@components/nested-menu';

interface UseContextMenuOptions {
  items: Item[];
}

const useContextMenu = (
  ref: HTMLElement | null,
  { items }: UseContextMenuOptions
) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    /* v8 ignore start */
    const handleContextMenu = (event: MouseEvent) => {
      if (menuOpen) {
        setMenuOpen(false);

        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setMenuOpen(true);

      const virtualElement = {
        getBoundingClientRect: () => ({
          top: event.clientY,
          left: event.clientX,
          width: 0,
          height: 0,
        }),
      };

      setAnchorEl(virtualElement as HTMLElement);
    };
    /* v8 ignore stop */

    const handleClick = () => {
      setMenuOpen(false);
    };

    ref?.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('click', handleClick);

    return () => {
      ref?.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('click', handleClick);
    };
  }, [menuOpen, ref]);

  const ContextMenu = () => {
    if (!menuOpen) {
      return null;
      /* v8 ignore start */
    }

    return <NestedMenu items={items} anchorEl={anchorEl} />;
    /* v8 ignore stop */
  };

  return { ContextMenu };
};

export default useContextMenu;
