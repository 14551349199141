import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useMoveFileToTrash from '@app/hooks/useMoveFileToTrash';
import { File } from '@app/services/files/types';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface MoveToTrashAlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: File;
}

const MoveToTrashAlertModal = ({
  isOpen,
  onClose,
  file,
}: MoveToTrashAlertModalProps) => {
  const { t } = useTranslation();
  const { isError, mutateAsync: moveFileToTrash } = useMoveFileToTrash();
  const queryClient = useQueryClient();
  const { displayGlobal } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      displayGlobal({
        text: t('fileCardContextMenu.moveToTrashModal.error'),
        type: 'error',
      });
    },
  });

  const handleMoveToTrash = async () => {
    onClose();
    await moveFileToTrash(file.id);
    await queryClient.invalidateQueries({ queryKey: ['files'] });
  };

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-small-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!mb-sm !text-2xl">
          {t('fileCardContextMenu.moveToTrashModal.title')}
        </Typography>
        <p>
          {t('fileCardContextMenu.moveToTrashModal.disclaimer', {
            fileName: file.fileName,
          })}
        </p>
        <div className="mt-md gap-x-sm flex justify-end">
          <Button variant="outlined" onClick={onClose}>
            {t('fileCardContextMenu.moveToTrashModal.cancel')}
          </Button>
          <Button variant="contained" onClick={handleMoveToTrash}>
            {t('fileCardContextMenu.moveToTrashModal.move')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default MoveToTrashAlertModal;
