import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useEditTags = () => {
  const { upsertTags } = getTagsApi();
  const query = useMutation({
    mutationFn: upsertTags,
    mutationKey: ['tags-upsert'],
  });

  return query;
};

export default useEditTags;
