import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeletedFile, File } from '@app/services/files/types';
import Breadcrumb from '@components/breadcrumb';
import Loading from '@components/loading';
import FileList from '@pages/files/components/file-list';

import DeletedFileDetails from './components/deleted-file-details';
import useDeletedFiles from './hooks/useDeletedFiles';

const Trash = () => {
  const navigate = useNavigate();
  const [fileDetail, setFileDetail] = useState<DeletedFile | null>(null);
  const { isLoading, data } = useDeletedFiles();

  const handleDoubleClick = () => {};

  const handleFolderNavigate = ({ path }: File) => {
    navigate(`/files${path}`);
  };

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading color="primary" />
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row overflow-x-hidden" data-tour="trash">
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex justify-between">
          <Breadcrumb />
        </div>
        <div className="gap-md p-md flex flex-1 flex-col">
          <FileList
            files={data}
            onFileClick={setFileDetail}
            onFolderClick={handleFolderNavigate}
            onFileDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
      {fileDetail ? (
        <DeletedFileDetails
          key={fileDetail.id}
          file={fileDetail}
          onClose={() => setFileDetail(null)}
        />
      ) : null}
    </div>
  );
};

export default Trash;
