import { InputBase, InputBaseProps } from '@mui/material';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const TextField = forwardRef<HTMLInputElement, InputBaseProps>(
  ({ className, ...props }, ref) => {
    const componentClass = twMerge(
      'rounded-sm bg-white px-4 py-2 drop-shadow-md',
      className
    );

    return <InputBase className={componentClass} ref={ref} {...props} />;
  }
);

export default TextField;
