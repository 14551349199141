import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import { OrganizationUserInviteRequest } from '@app/services/organizations/types';
import ErrorIcon from '@assets/organizations/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import useInviteToOrganization from '../hooks/useInviteToOrganization';
import { useOrganizationContext } from '../hooks/useOrganizationContext';
import useOrganizationUsers from '../hooks/useOrganizationUsers';

import OrganizationUserInviteModal from './organization-user-invite-modal';
import OrganizationUsersTable from './organization-users-table';

const OrganizationsUser = () => {
  const { t } = useTranslation();
  const organization = useOrganizationContext();

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const { data, isLoading, isError, refetch, isPlaceholderData } =
    useOrganizationUsers(organization.id, page);
  const {
    mutateAsync: invite,
    isError: isInviteError,
    isPending: isInviting,
    reset,
  } = useInviteToOrganization();
  const { display: displaySuccess, Alert: AlertSuccess } = useAlert();

  const handleInvite: SubmitHandler<OrganizationUserInviteRequest> = async (
    params
  ) => {
    await invite(params);
    setIsInviteModalVisible(false);
    displaySuccess();
  };

  const Content = () => {
    if (isLoading) {
      return (
        <div className="flex h-32 flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="my-lg flex flex-1 flex-col items-center justify-center">
          <ErrorIcon className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            <Trans i18nKey="organization.users.error">
              Oops! We could not load your organization's users
              <span
                className="cursor-pointer text-lg underline"
                onClick={() => refetch()}
              >
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        <div className="float-right -mt-16">
          <Button
            variant="contained"
            onClick={() => setIsInviteModalVisible(true)}
          >
            {t('organization.users.invite')}
          </Button>
        </div>
        <OrganizationUsersTable
          users={data?.data}
          isPlaceholderData={isPlaceholderData}
          onPageChange={setPage}
          total={data?.pagination.total ?? 0}
          page={page}
        />
      </>
    );
  };

  return (
    <div>
      <Content />
      <OrganizationUserInviteModal
        isOpen={isInviteModalVisible}
        onClose={() => setIsInviteModalVisible(false)}
        onInvite={handleInvite}
        isInviteError={isInviteError}
        isInviting={isInviting}
        onTryAgain={reset}
      />
      <AlertSuccess>{t('organization.users.success')}</AlertSuccess>
    </div>
  );
};

export default OrganizationsUser;
