import { Box, Button, FormControl, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserInput from '@components/users-input';

import { useUserInputContext } from '../hooks/useUserInputContext';

interface InviteNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: { users: string[]; allFromOrganization: boolean }) => void;
}

const InviteNewUserModal = ({
  isOpen,
  onClose,
  onSubmit,
}: InviteNewUserModalProps) => {
  const { t } = useTranslation();

  const { isAvailable } = useUserInputContext();
  const { handleSubmit, control, formState, reset, setValue } = useForm<{
    users: string[];
    allFromOrganization: boolean;
  }>({
    defaultValues: {
      users: [],
      allFromOrganization: true,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box
        className="mx-auto my-auto !flex w-tags-editor flex-col rounded bg-white p-screen shadow-md"
        id="group-invite"
      >
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <div className="flex flex-1 items-center justify-between">
          <Typography className="!text-lg">
            {t('organization.users.inviteUsersModal.title')}
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className="!my-md">
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <UserInput
                  {...field}
                  onUserFromOutside={() =>
                    setValue('allFromOrganization', false)
                  }
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className="float-right"
            disabled={!formState.isDirty || !isAvailable}
          >
            {t('organization.users.inviteUsersModal.invite')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default InviteNewUserModal;
