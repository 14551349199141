import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useUpdateFilePermissions = () => {
  const { updateFilePermissions } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: updateFilePermissions,
    mutationKey: ['updateFilePermissions'],
  });

  return mutation;
};

export default useUpdateFilePermissions;
