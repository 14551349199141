import { Typography } from '@mui/material';
import { DragEventHandler, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { sizes } from '@app/config/theme';
import EmptyImage from '@assets/files/empty.svg?react';
import Breadcrumb from '@components/breadcrumb';

import Droppable from './droppable';
import FileListContextMenu from './file-list-context-menu';

interface EmptyProps {
  onDrop: DragEventHandler<HTMLSpanElement>;
}

const Empty = ({ onDrop }: EmptyProps) => {
  const { t } = useTranslation();
  const contextAnchor = useRef<HTMLDivElement | null>(null);
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div className="flex flex-1 flex-col" ref={contextAnchor}>
      <div className="flex justify-between">
        <Breadcrumb />
      </div>
      <div className="m-md flex flex-1">
        <Droppable
          onDrop={onDrop}
          className="flex flex-1 flex-col items-center justify-center"
        >
          <div className="flex flex-col items-center justify-center">
            <EmptyImage width={sizes.xxl} height={sizes.xxl} />
            <Typography fontSize={20}>{t('fileList.empty.default')}</Typography>
          </div>
        </Droppable>
      </div>
      <FileListContextMenu anchorElement={contextAnchor.current} />
    </div>
  );
};

export default Empty;
