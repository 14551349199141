import { List, ListItem, ListItemText } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
// eslint-disable-next-line max-len
import { OrganizationUserBulkInviteFormInputs } from '@pages/organizations/components/organization-user-bulk-invite-form';

import useEmailList from '../hooks/useEmailList';

const EmailList = () => {
  const { t } = useTranslation();
  const { setValue, control } =
    useFormContext<OrganizationUserBulkInviteFormInputs>();

  const emails = useWatch({ control, name: 'emails' });
  const { emailList, validateEmail } = useEmailList(emails);

  const handleEmailDelete = (id: number) => {
    setValue(
      'emails',
      emailList
        .filter((value) => value.id !== id)
        .map((value) => value.email)
        .join(', ')
    );
  };

  return (
    <List className="!my-md max-h-[40vh] overflow-y-auto border border-gray-300 !p-0">
      {emailList.map(({ email, id }) => {
        const { classes, error } = validateEmail(email);

        return (
          <ListItem key={id} className="!border-b !py-4 last:!border-b-0">
            <ListItemText
              primary={email.trim()}
              secondary={
                error ? (
                  <p className="m-0 text-sm font-light">
                    {t(`organization.bulkInvite.email_errors.${error}`)}
                  </p>
                ) : null
              }
              className={classes}
              disableTypography
            />
            <PressableIcon
              icon={<CloseIcon width={14} height={14} title="remove-user" />}
              onClick={() => handleEmailDelete(id)}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default EmailList;
