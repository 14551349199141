import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { forwardRef, MouseEventHandler } from 'react';

interface PressableIconProps {
  icon: React.ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  altText?: string;
  className?: string;
}

const PressableIcon = forwardRef<HTMLButtonElement, PressableIconProps>(
  ({ icon, onClick, altText, className }, ref) => (
    <Tooltip title={altText} arrow>
      <IconButton ref={ref} className={className} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  )
);

export default PressableIcon;
