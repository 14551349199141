import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface UnsavedWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

const UnsavedWarningModal = ({
  onClose,
  isOpen,
  onDiscard,
}: UnsavedWarningModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="mx-auto my-auto !flex flex-col rounded bg-white p-screen shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <div className="flex flex-col items-center">
          <Typography className="!mt-md !text-xl !font-medium" variant="h1">
            {t('groups.addFiles.unsavedWarningModal.title')}
          </Typography>
        </div>
        <div className="mt-md flex gap-x-sm self-end">
          <Button onClick={onClose} variant="outlined">
            {t('groups.addFiles.unsavedWarningModal.goBack')}
          </Button>
          <Button onClick={onDiscard} variant="contained">
            {t('groups.addFiles.unsavedWarningModal.discard')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default UnsavedWarningModal;
