import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useFileContext from '../hooks/useFileContext';

import ClosedVersionsTable from './closed-versions-table';
import NewVersionModal from './new-version-modal';
import ProposedVersionsTable from './proposed-versions-table';
import WorkingVersionsTable from './working-versions-table';

const FileVersionsDetails = () => {
  const { t } = useTranslation();
  const file = useFileContext();

  const [isNewVersionModalOpen, setIsNewVersionModalOpen] = useState(false);

  if (!file) {
    return null;
  }

  return (
    <div
      className="p-md flex flex-1 flex-col overflow-auto"
      data-tour="versions-step-2"
    >
      <p className="text-2xl font-light">
        {t('fileVersions.currentVersion.title')}
      </p>
      <p className="mt-sm font-medium">{file.fileName}_v1</p>
      <p className="mt-1 text-sm text-gray-400">
        {t('fileVersions.currentVersion.created', {
          date: DateTime.fromMillis(file.lastModified).toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          interpolation: { escapeValue: false },
        })}
      </p>
      <p className="mt-1 text-sm text-gray-400">
        {t('fileVersions.currentVersion.accepted', {
          name: file.organizationName,
        })}
      </p>
      <Button
        variant="contained"
        onClick={() => setIsNewVersionModalOpen(true)}
        className="!mt-sm !max-w-72"
      >
        {t('fileVersions.createNewVersion')}
      </Button>
      <p className="mt-md text-2xl font-light">
        {t('fileVersions.proposedVersions.title')}
      </p>
      <span className="mt-sm">
        <ProposedVersionsTable />
      </span>
      <p className="mt-md text-2xl font-light">
        {t('fileVersions.workingVersions.title')}
      </p>
      <span className="mt-sm">
        <WorkingVersionsTable />
      </span>
      <p className="mt-md text-2xl font-light">
        {t('fileVersions.closedVersions.title')}
      </p>
      <span className="mt-sm">
        <ClosedVersionsTable />
      </span>
      <NewVersionModal
        isOpen={isNewVersionModalOpen}
        onClose={() => setIsNewVersionModalOpen(false)}
      />
    </div>
  );
};

export default FileVersionsDetails;
