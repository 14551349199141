import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useEditTag = () => {
  const { editTag } = getTagsApi();
  const query = useMutation({
    mutationFn: editTag,
    mutationKey: ['tag-edit'],
  });

  return query;
};

export default useEditTag;
