import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@components/loading-button';

import { useOrganizationContext } from '../hooks/useOrganizationContext';

export interface OrganizationOverviewFields {
  name: string;
  description: string;
}

interface OrganizationOverviewFormProps {
  onSubmit: (
    form: OrganizationOverviewFields,
    reset: UseFormReset<OrganizationOverviewFields>
  ) => void;
  isLoading: boolean;
}

const OrganizationOverviewForm = ({
  onSubmit,
  isLoading,
}: OrganizationOverviewFormProps) => {
  const { t } = useTranslation();
  const organization = useOrganizationContext();
  const { control, handleSubmit, formState, reset } =
    useForm<OrganizationOverviewFields>({
      defaultValues: {
        description: organization.metadata?.description ?? '',
        name: organization.name,
      },
    });

  const isButtonDisabled = useMemo(
    () =>
      !formState.isValid ||
      (!formState.dirtyFields.name && !formState.dirtyFields.description),
    [formState]
  );

  const submitForm = (form: OrganizationOverviewFields) => {
    onSubmit(form, reset);
  };

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="gap-y-md flex w-1/3 flex-col"
    >
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('organization.overview.basicInformation.name')}
            variant="standard"
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <TextField
            {...field}
            label={t('organization.overview.basicInformation.description')}
            multiline
            variant="standard"
            maxRows={4}
          />
        )}
      />
      <LoadingButton
        type="submit"
        variant="contained"
        className="self-end"
        disabled={isButtonDisabled}
        loading={isLoading}
      >
        {t('organization.overview.basicInformation.save')}
      </LoadingButton>
    </form>
  );
};

export default OrganizationOverviewForm;
