import { Button, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { AccessDates } from '@app/services/groups/types';

interface GroupDetailsEditDatesFormProps {
  initialStartDate: DateTime;
  initialExpirationDate: DateTime | null;
  onCancel: () => void;
  onSubmit: (form: AccessDates) => void;
}

const GroupDetailsEditDatesForm = ({
  initialStartDate,
  initialExpirationDate,
  onCancel,
  onSubmit,
}: GroupDetailsEditDatesFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState, watch } = useForm<AccessDates>({
    defaultValues: {
      startDate: initialStartDate,
      expirationDate: initialExpirationDate,
    },
  });
  const startDate = watch('startDate');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formState.isDirty, formState.isValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-md" role="form">
      <FormControl fullWidth>
        <Controller
          name="startDate"
          control={control}
          rules={{
            validate: (value) => value !== null && value.isValid,
          }}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                disabled={
                  initialStartDate.diffNow('days').days < 0 &&
                  (!initialExpirationDate ||
                    initialExpirationDate.diffNow('days').days > 0)
                }
                {...field}
                disablePast={
                  initialStartDate.diffNow('days').days >= 0 ||
                  (!!initialExpirationDate &&
                    initialExpirationDate.diffNow('days').days < 0)
                }
                slotProps={{
                  textField: { variant: 'standard' },
                }}
                label={t('groups.groupDetails.editDatesModal.accessGrant')}
                className="!mb-md"
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!mb-md">
        <Controller
          name="expirationDate"
          control={control}
          rules={{
            validate: (value) =>
              value === null ||
              (value.isValid && startDate.diff(value, 'days').days < 1),
          }}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                {...field}
                minDate={startDate}
                disablePast
                slotProps={{
                  textField: {
                    variant: 'standard',
                    helperText: t(
                      'groups.groupDetails.editDatesModal.accessExpiration.message'
                    ),
                  },
                }}
                label={
                  <p>
                    <Trans i18nKey="groups.groupDetails.editDatesModal.accessExpiration.label">
                      Expiration date
                      <span className="text-gray-400">(optional)</span>
                    </Trans>
                  </p>
                }
                className="!mb-md"
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      <div className="mt-sm gap-x-sm flex justify-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('groups.groupDetails.editDatesModal.cancel')}
        </Button>
        <Button variant="contained" type="submit" disabled={isButtonDisabled}>
          {t('groups.groupDetails.editDatesModal.save')}
        </Button>
      </div>
    </form>
  );
};

export default GroupDetailsEditDatesForm;
