import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import { useAppLocation } from '@app/hooks/useAppLocation';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
// eslint-disable-next-line max-len
import { OrganizationUserBulkInviteFormInputs } from '@pages/organizations/components/organization-user-bulk-invite-form';
import { useOrganizationContext } from '@pages/organizations/hooks/useOrganizationContext';

import BulkInviteForm from './components/bulk-invite-form';
import useBulkInvite from './hooks/useBulkInvite';

interface OrganizationUserBulkInviteFormState {
  emails: string;
  accessGrant: string;
  accessExpiration: string | null;
}

const OrganizationsBulkInvite = () => {
  const { t } = useTranslation();
  const { mutateAsync: invite, isError, isPending, reset } = useBulkInvite();
  const navigate = useNavigate();
  const { state } = useAppLocation<OrganizationUserBulkInviteFormState>();
  const organization = useOrganizationContext();
  const { displayGlobal } = useAlert();
  const form = useForm<OrganizationUserBulkInviteFormInputs>({
    defaultValues: {
      emails: state?.emails ?? '',
      accessGrant: state?.accessGrant
        ? DateTime.fromISO(state?.accessGrant)
        : DateTime.now(),
      accessExpiration: state?.accessExpiration
        ? DateTime.fromISO(state?.accessExpiration)
        : null,
    },
  });

  const handleSubmit = useCallback(
    async (params: OrganizationUserBulkInviteFormInputs) => {
      const accessGrantDate = params.accessGrant.toISODate();
      if (accessGrantDate) {
        await invite({
          accessExpiration: params.accessExpiration?.toISODate() ?? null,
          accessGrant: accessGrantDate,
          emails: params.emails.split(',').map((email) => email.trim()),
          organization,
        });
        navigate('/organizations');
        displayGlobal({
          text: t('organization.bulkInvite.success'),
          type: 'success',
        });
      }
    },
    [displayGlobal, invite, navigate, organization, t]
  );

  const content = useMemo(() => {
    if (isPending) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium">
            <Trans i18nKey="organization.bulkInvite.error">
              Oops! There was an error inviting users. Please
              <span className="cursor-pointer underline" onClick={reset}>
                review the data and try again
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <FormProvider {...form}>
        <BulkInviteForm onSubmit={handleSubmit} />
      </FormProvider>
    );
  }, [form, handleSubmit, isError, isPending, reset]);

  return (
    <div className="p-screen flex flex-1 flex-col">
      <h1 className="mb-lg text-3xl font-bold">
        {t('organization.bulkInvite.title')}
      </h1>
      {content}
    </div>
  );
};

export default OrganizationsBulkInvite;
