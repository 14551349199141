import { Dispatch, createContext, useContext, useReducer } from 'react';

import { File } from '@app/services/groups/types';
import { Action, Reducer } from '@filot/types/context';

type AddFileAction = Action<'ADD_FILE', File>;
type RemoveFileAction = Action<'REMOVE_FILE', string>;
type SetFilesAction = Action<'SET_FILES', File[]>;

type SelectedFilesActions = AddFileAction | RemoveFileAction | SetFilesAction;

export const SelectedFilesContext = createContext<File[]>([]);

export const SelectedFilesDispatchContext = createContext<
  Dispatch<SelectedFilesActions>
>(() => {});

const selectedFilesReducer: Reducer<File[], SelectedFilesActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'ADD_FILE':
      return [...state, action.payload];
    case 'REMOVE_FILE': {
      const newState = state.filter((file) => file.id !== action.payload);

      return newState;
    }
    case 'SET_FILES': {
      return action.payload;
    }
    default:
      return state;
  }
};

export const useSelectedFilesReducer = (): [
  File[],
  Dispatch<SelectedFilesActions>,
] => {
  const [state, dispatch] = useReducer(selectedFilesReducer, []);

  return [state, dispatch];
};

export const useSelectedFiles = () => useContext(SelectedFilesContext);
export const useUpdateSelectedFiles = () =>
  useContext(SelectedFilesDispatchContext);
