import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NewGroupOwner } from '@app/services/groups/types';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface NewGroupOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: NewGroupOwner) => void;
}

const NewGroupOwnerModal = ({
  isOpen,
  onClose,
  onSubmit,
}: NewGroupOwnerModalProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<NewGroupOwner>({
    defaultValues: { email: '' },
  });

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="mx-auto my-auto !flex flex-col rounded bg-white p-screen shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!mb-sm !text-2xl !font-medium">
          {t('groups.groupDetails.dangerZone.leaveGroup.newOwnerModal.title')}
        </Typography>
        <p className="text-base">
          {t(
            'groups.groupDetails.dangerZone.leaveGroup.newOwnerModal.description'
          )}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-md flex flex-col">
          <Controller
            name="email"
            control={control}
            rules={{
              validate: (email) =>
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email),
            }}
            render={({ field }) => (
              <TextField
                label={t(
                  'groups.groupDetails.dangerZone.leaveGroup.newOwnerModal.label'
                )}
                variant="standard"
                {...field}
              />
            )}
          />
          <div className="mt-md flex gap-x-sm self-end">
            <Button variant="outlined" onClick={onClose}>
              {t(
                'groups.groupDetails.dangerZone.leaveGroup.newOwnerModal.cancel'
              )}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
            >
              {t(
                'groups.groupDetails.dangerZone.leaveGroup.newOwnerModal.continue'
              )}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default NewGroupOwnerModal;
