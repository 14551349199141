import { SxProps, Theme } from '@mui/material';

import useColorHelper from '@app/hooks/useColorHelper';

const useAvatar = () => {
  const { stringToColor } = useColorHelper();

  const stringAvatar = (
    name: string,
    props?: SxProps<Theme>,
    source?: string
  ) =>
    !source
      ? {
          sx: {
            bgcolor: stringToColor(name).color,
            ...props,
          },
          children: `${
            name.split(' ')[0]?.[0]?.toLocaleUpperCase() ?? ''
          }${name.split(' ')[1]?.[0]?.toLocaleUpperCase() ?? ''}`,
        }
      : {
          slotProps: {
            img: {
              className: '!object-contain',
            },
          },
        };

  return { stringAvatar };
};

export default useAvatar;
