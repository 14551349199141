import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import { File, Group } from '@app/services/groups/types';
import TrashIcon from '@assets/shared/trash.svg?react';
import FileIcon from '@components/file-icon';
import PressableIcon from '@components/pressable-icon';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import useManageUpdateFilePermissions from '../hooks/useManageUpdateFilePermissions';

import GroupRemoveFileConfirmationModal from './group-remove-file-confirmation-modal';

interface GroupFilesTableRowProps {
  file: File;
  group: Group;
  readOnly?: boolean;
}

const GroupFilesTableRow = ({
  file,
  group,
  readOnly,
}: GroupFilesTableRowProps) => {
  const { t } = useTranslation();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      read: file.permissions.read,
      write: file.permissions.write,
      comment: file.permissions.comment,
    },
  });
  const { isUpdateFilePermissionError, safeValues, resetUpdateFilePermission } =
    useManageUpdateFilePermissions({
      watch,
      file,
      group,
    });

  const { canEditFiles } = useGroupPermissions(group.permission);

  const { display, Alert } = useAlert();
  useErrorHandler(isUpdateFilePermissionError, {
    callback: () => {
      setValue('read', safeValues.read);
      setValue('write', safeValues.write);
      setValue('comment', safeValues.comment);
      resetUpdateFilePermission();
      display();
    },
  });

  const handleRemoveFile = () => {
    setIsConfirmationModalVisible(true);
  };

  return (
    <TableRow key={file.name}>
      <TableCell>
        <div className="flex items-center gap-sm">
          <FileIcon type={file.type!} width={26} height={26} />
          <Typography>{file.name}</Typography>
        </div>
      </TableCell>
      {!readOnly ? (
        <>
          <TableCell className="!text-center">
            <Controller
              name="read"
              control={control}
              render={({ field }) => (
                <Checkbox
                  disabled={!canEditFiles()}
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          </TableCell>
          <TableCell className="!text-center">
            <Controller
              name="write"
              control={control}
              render={({ field }) => (
                <Checkbox
                  disabled={!canEditFiles()}
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          </TableCell>
          <TableCell className="!text-center">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Checkbox
                  disabled={!canEditFiles()}
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
            <Alert severity="error">{t('groups.groupFiles.table.error')}</Alert>
            <GroupRemoveFileConfirmationModal
              fileId={file.id}
              groupId={group.id}
              isOpen={isConfirmationModalVisible}
              onClose={() => setIsConfirmationModalVisible(false)}
            />
          </TableCell>
          <TableCell className="!text-center">
            <PressableIcon
              icon={<TrashIcon title="trash" />}
              onClick={handleRemoveFile}
            />
          </TableCell>
        </>
      ) : null}
    </TableRow>
  );
};

export default GroupFilesTableRow;
