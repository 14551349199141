import { useMutation } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useTransferOrganizationOwnership = () => {
  const { transferOwnership } = getOrganizationApi();
  const mutation = useMutation({
    mutationFn: transferOwnership,
    mutationKey: ['organization-transfer-ownership'],
  });

  return mutation;
};

export default useTransferOrganizationOwnership;
