import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useDeleteTag = () => {
  const { deleteTag } = getTagsApi();
  const query = useMutation({
    mutationFn: deleteTag,
    mutationKey: ['tag-delete'],
  });

  return query;
};

export default useDeleteTag;
