import { Button, FormControl, TextField } from '@mui/material';
import { KeyboardEventHandler, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Note } from '@app/services/files/types';

interface FileDetailsNewNoteFormProps {
  onSubmit: (form: Pick<Note, 'content'>) => void;
}

const FileDetailsNewNoteForm = ({ onSubmit }: FileDetailsNewNoteFormProps) => {
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { handleSubmit, control } = useForm<Pick<Note, 'content'>>({
    defaultValues: {
      content: '',
    },
  });

  const handleOpenForm = () => {
    setTimeout(() => {
      setIsFormVisible(true);
    }, 200);
  };

  const handleKeyPress: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      void handleSubmit(onSubmit)();
    }
  };

  return (
    <form onKeyDown={handleKeyPress} onSubmit={handleSubmit(onSubmit)}>
      {isFormVisible ? (
        <FormControl fullWidth className="!m-0">
          <Controller
            name="content"
            control={control}
            rules={{
              validate: (value) => value.length > 1,
            }}
            render={({ field }) => (
              <TextField
                multiline
                autoFocus
                fullWidth
                variant="outlined"
                className="!mb-0"
                {...field}
                label={t('fileList.fileDetails.notes.form.label')}
                placeholder={t('fileList.fileDetails.notes.form.placeholder')}
              />
            )}
          />
        </FormControl>
      ) : null}
      <div className="mt-sm flex justify-end">
        {isFormVisible ? (
          <Button type="submit">
            {t('fileList.fileDetails.notes.form.done')}
          </Button>
        ) : (
          <Button onClick={handleOpenForm} data-tour="preview-document-step-6">
            {t('fileList.fileDetails.notes.add')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default FileDetailsNewNoteForm;
