import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';
import TablePagination from '@components/table-pagination';

import YourGroupsTableRow from './your-groups-table-row';

interface YourGroupsTableProps {
  groups: Group[] | undefined;
  onChangePage: (newPage: number) => void;
  page: number;
  totalPages: number;
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

const YourGroupsTable = ({
  groups,
  onChangePage,
  page,
  rowsPerPage,
  totalPages,
  onRowsPerPageChange,
}: YourGroupsTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="groups-table">
        <colgroup>
          <col className="w-1/2" />
          <col />
          <col className="w-2/6" />
          <col className="w-1/4" />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t('groups.table.name')}</TableCell>
            <TableCell>{t('groups.table.owner')}</TableCell>
            <TableCell>{t('groups.table.users')}</TableCell>
            <TableCell>{t('groups.table.status.label')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {groups?.map((group) => (
            <YourGroupsTableRow key={group.id} group={group} />
          ))}
        </TableBody>
        <TableFooter>
          <TablePagination
            onPageChange={onChangePage}
            page={page}
            total={totalPages}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default YourGroupsTable;
