import { Alert } from '@mui/material';
import { motion } from 'framer-motion';
import { useContext } from 'react';

import { AlertContext } from '@app/hooks/useAlert';

const GlobalAlert = () => {
  const { text, type } = useContext(AlertContext);

  if (text && type) {
    return (
      <motion.div
        key="alert"
        initial={{ top: -20, opacity: 0 }}
        animate={{ top: 120, opacity: 1 }}
        exit={{ opacity: 0, top: -20 }}
        className="fixed left-1/2 -translate-x-1/2"
      >
        <Alert
          severity={type}
          sx={{
            zIndex: 10,
            marginTop: '2rem',
          }}
        >
          {text}
        </Alert>
      </motion.div>
    );
  } else {
    return null;
  }
};

export default GlobalAlert;
