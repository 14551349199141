import { FormControl, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { isValidEmail } from '@app/utils/email';
// eslint-disable-next-line max-len
import { OrganizationUserBulkInviteFormInputs } from '@pages/organizations/components/organization-user-bulk-invite-form';

import BulkInviteButtons from './bulk-invite-buttons';
import EmailList from './email-list';

interface BulkInviteFormProps {
  onSubmit: (form: OrganizationUserBulkInviteFormInputs) => void;
}

const BulkInviteForm = ({ onSubmit }: BulkInviteFormProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } =
    useFormContext<OrganizationUserBulkInviteFormInputs>();

  const accessGrant = useWatch({ control, name: 'accessGrant' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth className="mb-4">
        <Controller
          name="emails"
          control={control}
          rules={{
            required: true,
            validate: (value) =>
              value.split(',').every((email) => isValidEmail(email)),
          }}
          render={({ field }) => (
            <TextField
              multiline
              {...field}
              minRows={3}
              maxRows={4}
              label={
                <p>
                  <Trans i18nKey="organization.bulkInvite.form.emails">
                    Users' e-mail
                    <span className="text-gray-400">(comma-separated)</span>
                  </Trans>
                </p>
              }
            />
          )}
        />
      </FormControl>
      <EmailList />

      <div className="flex justify-between">
        <span>
          <FormControl fullWidth className="mb-4">
            <Controller
              name="accessGrant"
              control={control}
              rules={{
                validate: (value) =>
                  value !== null &&
                  value.isValid &&
                  value.diffNow('days').days > -1,
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    {...field}
                    disablePast
                    slotProps={{ textField: { variant: 'standard' } }}
                    label={t('organization.bulkInvite.form.accessGrant')}
                    className="!mb-md"
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              name="accessExpiration"
              control={control}
              rules={{
                validate: (value) =>
                  value === null ||
                  (value.isValid && accessGrant.diff(value, 'days').days < 0),
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    {...field}
                    minDate={accessGrant}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        helperText: t(
                          'organization.bulkInvite.form.expiration.message'
                        ),
                      },
                    }}
                    label={
                      <p>
                        <Trans i18nKey="organization.bulkInvite.form.expiration.label">
                          Access expiration
                          <span className="text-gray-400">(optional)</span>
                        </Trans>
                      </p>
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
        </span>
        <BulkInviteButtons />
      </div>
    </form>
  );
};

export default BulkInviteForm;
