import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Group } from '@app/services/groups/types';
import EmptyIcon from '@assets/groups/empty-groups.svg?react';
import TablePagination from '@components/table-pagination';

import GroupFilesTableRow from './group-files-table-row';

interface GroupFilesEditTableProps {
  page: number;
  onPageChange: (newPage: number) => void;
  isPlaceholderData: boolean;
  total: number;
  readOnly?: false;
  group: Group;
}

interface GroupFilesDisplayTableProps {
  page?: undefined;
  onPageChange?: undefined;
  isPlaceholderData?: undefined;
  total?: undefined;
  readOnly: true;
  group: Group;
}

type GroupFilesTableProps =
  | GroupFilesEditTableProps
  | GroupFilesDisplayTableProps;

const GroupFilesTable = ({
  onPageChange,
  page,
  isPlaceholderData,
  total,
  group,
  readOnly,
}: GroupFilesTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToFile = () => {
    navigate('/files');
  };

  return group.files.length === 0 ? (
    <div className="flex flex-col items-center justify-center">
      <EmptyIcon className="my-md" />
      <p className="my-md text-lg">{t('groups.groupFiles.table.empty.text')}</p>
      <Button variant="outlined" onClick={handleNavigateToFile}>
        {t('groups.groupFiles.table.empty.button')}
      </Button>
    </div>
  ) : (
    <TableContainer>
      <Table aria-label="files-details-table">
        <colgroup>
          <col className="w-2/4" />
          {!readOnly ? (
            <>
              <col className="w-1/6" />
              <col className="w-1/6" />
              <col className="w-1/6" />
              <col className="w-1/6" />
            </>
          ) : null}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t('groups.groupFiles.table.header.file')}</TableCell>
            {!readOnly ? (
              <>
                <TableCell className="!text-center">
                  {t('groups.groupFiles.table.header.read')}
                </TableCell>
                <TableCell className="!text-center">
                  {t('groups.groupFiles.table.header.write')}
                </TableCell>
                <TableCell className="!text-center">
                  {t('groups.groupFiles.table.header.comment')}
                </TableCell>
                <TableCell />
              </>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {group.files?.map((file) => (
            <GroupFilesTableRow
              group={group}
              key={file.id}
              file={file}
              readOnly={readOnly}
            />
          ))}
        </TableBody>
        {!readOnly && total > 5 ? (
          <TableFooter>
            <TablePagination
              onPageChange={onPageChange}
              isPlaceholderData={isPlaceholderData}
              page={page}
              total={total}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default GroupFilesTable;
