import { Modal, Box, Typography } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { Trans } from 'react-i18next';

import Error from '@assets/organizations/error.svg?react';
import Close from '@assets/shared/close.svg?react';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import NewOrganizationForm, {
  NewOrganizationFormInputs,
} from './new-organization-form';

interface NewOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: SubmitHandler<NewOrganizationFormInputs>;
  isCreating: boolean;
  isCreateError: boolean;
  onTryAgain: () => void;
}

const NewOrganizationModal = ({
  isOpen,
  onClose,
  onCreate,
  isCreateError,
  isCreating,
  onTryAgain,
}: NewOrganizationModalProps) => {
  const handleCreate: SubmitHandler<NewOrganizationFormInputs> = (params) => {
    onCreate(params);
  };

  const Content = () => {
    if (isCreating) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isCreateError) {
      return (
        <div className="mb-lg flex flex-1 flex-col items-center justify-center">
          <Error className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            <Trans i18nKey="organization.newOrganization.errorCreating">
              There was an error creating organization. Please
              <span
                className="cursor-pointer text-lg underline"
                onClick={onTryAgain}
              >
                try again
              </span>
            </Trans>
          </Typography>
        </div>
      );
    }

    return <NewOrganizationForm onSubmit={handleCreate} />;
  };

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-large-modal p-screen mx-auto my-auto !flex h-3/4 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="self-end"
        />
        <Content />
      </Box>
    </Modal>
  );
};

export default NewOrganizationModal;
