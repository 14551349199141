import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import WarningIcon from '@assets/groups/warning.svg?react';
import ErrorIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import useDeleteGroup from '@pages/groups/hooks/useDeleteGroup';

import { GroupDetailsContext } from '../group-details';

interface DeleteGroupWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteGroupWarningModal = ({
  onClose,
  isOpen,
}: DeleteGroupWarningModalProps) => {
  const { t } = useTranslation();
  const group = useContext(GroupDetailsContext);
  const navigate = useNavigate();

  const {
    mutateAsync: deleteGroup,
    isPending,
    isError,
    reset,
  } = useDeleteGroup();
  const withCanClose = useCloseRequestModal(isPending, reset);
  const { displayGlobal } = useAlert();
  const queryClient = useQueryClient();

  const { formState, control } = useForm({
    defaultValues: {
      groupName: '',
    },
  });

  const handleSubmit = useCallback(async () => {
    if (group) {
      await deleteGroup(group.id);
      displayGlobal({
        type: 'success',
        text: t(
          'groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.success'
        ),
      });
      await queryClient.invalidateQueries({ queryKey: ['groups'] });
      navigate('/groups');
      onClose();
    }
  }, [deleteGroup, displayGlobal, group, navigate, onClose, queryClient, t]);

  const content = useMemo(() => {
    if (!group) {
      return null;
    }

    if (isPending) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center">
          <ErrorIcon width={128} height={128} />
          <p className="mx-lg my-sm text-center text-xl">
            <Trans i18nKey="groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.error">
              Oops! There was an error and you didn't delete the group.
              <span className="cursor-pointer underline" onClick={reset}>
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        <div className="px-screen flex flex-col items-center">
          <WarningIcon width={180} height={180} />
          <p className="mt-md text-center text-base">
            <Trans i18nKey="groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.description">
              Are you sure you want to delete {{ groupName: group.name }}{' '}
              <span className="font-bold">forever</span>? This action cannot be
              undone.
            </Trans>
          </p>
        </div>
        <Divider className="!my-md" />
        <div className="px-screen flex flex-1 flex-col">
          <p className="mb-sm">
            {t(
              'groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.disclaimer',
              { groupName: group.name }
            )}
          </p>
          <Controller
            name="groupName"
            control={control}
            rules={{
              validate: (value) => value === group.name,
            }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                inputProps={{ className: '!py-2' }}
                {...field}
              />
            )}
          />
        </div>
        <div className="mt-md gap-x-sm px-screen flex self-end">
          <Button variant="outlined" onClick={withCanClose(onClose)}>
            {t(
              'groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.cancel'
            )}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!formState.isValid}
          >
            {t(
              'groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.delete'
            )}
          </Button>
        </div>
      </>
    );
  }, [
    control,
    formState.isValid,
    group,
    handleSubmit,
    isError,
    isPending,
    onClose,
    reset,
    t,
    withCanClose,
  ]);

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="py-screen mx-auto my-auto !flex w-4/12 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!pr-screen !absolute self-end p-0"
        />
        <Typography className="!mb-sm !pl-screen !text-2xl !font-medium">
          {t(
            'groups.groupDetails.dangerZone.deleteGroup.deleteWarningModal.title'
          )}
        </Typography>
        {content}
      </Box>
    </Modal>
  );
};

export default DeleteGroupWarningModal;
