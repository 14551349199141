import { useMutation } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useUpdateOrganization = () => {
  const { update } = getOrganizationApi();
  const mutation = useMutation({
    mutationFn: update,
    mutationKey: ['organization-update'],
  });

  return mutation;
};

export default useUpdateOrganization;
