import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import LoadingButton from '@components/loading-button';

import useDeleteTag from '../hooks/useDeleteTag';
import { useTagContext } from '../hooks/useTagContext';

const TagsDangerZone = () => {
  const { t } = useTranslation();
  const tag = useTagContext();
  const navigate = useNavigate();
  const { mutateAsync: deleteTag, reset, isPending } = useDeleteTag();
  const { display, Alert } = useAlert();

  const handleDelete = async () => {
    try {
      await deleteTag(tag.id);
      navigate('/tags');
    } catch (e) {
      display();
      reset();
    }
  };

  return (
    <div className="mt-lg">
      <h3 className="mb-md text-xl">{t('tags.tagDetails.dangerZone.title')}</h3>
      <div className="p-md gap-y-md flex flex-col rounded-sm border border-red-400">
        <div className="flex items-center justify-between">
          <div className="mr-md">
            <p className="text-lg font-bold">
              {t('tags.tagDetails.dangerZone.deleteTag.title')}
            </p>
            <p className="text-lg">
              {t('tags.tagDetails.dangerZone.deleteTag.description')}
            </p>
          </div>
          <LoadingButton
            variant="outlined"
            color="error"
            size="large"
            onClick={handleDelete}
            loading={isPending}
            className="!w-48"
          >
            {t('tags.tagDetails.dangerZone.deleteTag.button')}
          </LoadingButton>
        </div>
      </div>
      <Alert severity="error">
        {t('tags.tagDetails.dangerZone.deleteTag.error')}
      </Alert>
    </div>
  );
};

export default TagsDangerZone;
