import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface UserInputChipContextState {
  isAvailable: boolean;
  setIsAvailable: Dispatch<SetStateAction<boolean>>;
}

export const UserInputChipContext = createContext<UserInputChipContextState>({
  isAvailable: false,
  setIsAvailable: () => {},
});

export const UserInputChipContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [isAvailable, setIsAvailable] = useState(false);

  return (
    <UserInputChipContext.Provider value={{ isAvailable, setIsAvailable }}>
      {children}
    </UserInputChipContext.Provider>
  );
};

export const useUserInputContext = () => useContext(UserInputChipContext);
