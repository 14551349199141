import { PaginationOptions } from '@filot/types/api';

import notesMock from '../../../../../mock/notes.json';

import { NotesApi, NotesResponse } from './types';

const getNotesMock = (): NotesApi => {
  const getNotes = async (
    _paginationOptions: PaginationOptions,
    fileId: string
  ) =>
    new Promise<NotesResponse | null>((res, rej) => {
      const notes = notesMock as Record<string, NotesResponse>;
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          if (!notes[fileId]) {
            res(null);
          } else {
            res(notes[fileId]);
          }
        }
      }, 1000);
    });

  const deleteNote = async (_id: string) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const editNote = async (_params: { content: string; id: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const createNote = async ({ content }: { content: string; fileId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (content.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  return {
    getNotes,
    createNote,
    editNote,
    deleteNote,
  };
};

export default getNotesMock;
