import {
  Dispatch,
  ProviderProps,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { Action, Reducer } from '@filot/types/context';
import { User } from '@filot/types/user';

import useFromApp from './useFromApp';

type SetAccessTokenAction = Action<'SET_ACCESS_TOKEN', string>;
type SetRefreshTokenAction = Action<'SET_REFRESH_TOKEN', string>;
type SetUserAction = Action<'SET_USER', User>;
type UpdateUserAction = Action<'UPDATE_USER', Partial<User>>;

type AuthActions =
  | SetAccessTokenAction
  | SetRefreshTokenAction
  | SetUserAction
  | UpdateUserAction;

interface AuthState {
  user: User | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  user: null,
  accessToken: null,
  refreshToken: null,
};

export const AuthStateContext = createContext(initialState);

export const AuthDispatchContext = createContext<Dispatch<AuthActions>>(
  () => {}
);

const authReducer: Reducer<AuthState, AuthActions> = (state, action) => {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.payload,
      };
    case 'SET_REFRESH_TOKEN':
      return {
        ...state,
        refreshToken: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'UPDATE_USER':
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
export const AuthContextProvider = ({
  children,
}: Omit<ProviderProps<AuthState>, 'value'>) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { getProfile } = useFromApp();

  useEffect(() => {
    void (async () => {
      const profile = await getProfile();
      if (profile) {
        dispatch({
          type: 'SET_USER',
          payload: profile,
        });
      }
    })();
  }, [getProfile]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuth = () => useContext(AuthStateContext);
export const useUpdateAuth = () => useContext(AuthDispatchContext);
