import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OrganizationTransferOwnershipModal from './organization-transfer-ownership-modal';

const OrganizationDangerZone = () => {
  const { t } = useTranslation();
  const [isTransferOwnershipVisible, setIsTransferOwnershipVisible] =
    useState(false);

  const handleTransferOwnership = () => {
    setIsTransferOwnershipVisible(true);
  };

  return (
    <div>
      <p className="mb-md text-2xl">
        {t('organization.overview.dangerZone.title')}
      </p>
      <div className="p-md gap-y-md flex flex-col rounded-sm border border-red-400">
        <div className="flex items-center justify-between">
          <div className="mr-md">
            <p className="text-lg font-bold">
              {t('organization.overview.dangerZone.transferOwnership.title')}
            </p>
            <p className="text-lg">
              {t(
                'organization.overview.dangerZone.transferOwnership.description'
              )}
            </p>
          </div>
          <Button
            variant="outlined"
            color="error"
            size="large"
            onClick={handleTransferOwnership}
          >
            {t('organization.overview.dangerZone.transferOwnership.button')}
          </Button>
        </div>
      </div>
      <OrganizationTransferOwnershipModal
        isOpen={isTransferOwnershipVisible}
        onClose={() => setIsTransferOwnershipVisible(false)}
      />
    </div>
  );
};

export default OrganizationDangerZone;
