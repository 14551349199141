import { useQuery } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useGenerateDnsRecord = (domain: string) => {
  const { generateDnsRecord } = getOrganizationApi();

  const query = useQuery({
    queryFn: () => generateDnsRecord(domain),
    queryKey: ['organization-generate-dns-record', domain],
    enabled: false,
  });

  return query;
};

export default useGenerateDnsRecord;
