import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import WarningIcon from '@assets/groups/warning.svg?react';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface ConfirmRemoveUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  name: string;
}

const ConfirmRemoveUserModal = ({
  isOpen,
  onClose,
  onSubmit,
  name,
}: ConfirmRemoveUserModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="mx-auto my-auto !flex w-tags-editor flex-col rounded bg-white p-screen shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography variant="h2" className="!text-xl !font-medium">
          {t('groups.groupMembers.removeUserModal.title')}
        </Typography>
        <div className="flex flex-col items-center">
          <WarningIcon height={120} className="mt-lg" />
          <p className="mb-md mt-sm text-center text-lg font-medium">
            {t('groups.groupMembers.removeUserModal.text', { name })}
          </p>
          <Button variant="contained" onClick={onSubmit} className="self-end">
            {t('groups.groupMembers.removeUserModal.submit')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmRemoveUserModal;
