import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import PencilIcon from '@assets/groups/edit.svg?react';
import AddIcon from '@assets/shared/add.svg?react';
import PressableIcon from '@components/pressable-icon';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

interface GroupFilesHeaderProps {
  group: Group;
  onBack: () => void;
  onBulkEdit: () => void;
  onAddFilesClick: () => void;
}

const GroupFilesHeader = ({
  group,
  onBack,
  onBulkEdit,
  onAddFilesClick,
}: GroupFilesHeaderProps) => {
  const { t } = useTranslation();
  const { canAddFiles, canEditFiles } = useGroupPermissions(group.permission);

  return (
    <div className="mb-md flex flex-1 justify-between">
      <div className="flex items-center">
        <PressableIcon
          onClick={onBack}
          icon={<BackArrow title="back" className="text-black" />}
        />
        <Typography className="!ml-sm !mr-20 !text-3xl" variant="h2">
          {group.name}
        </Typography>
      </div>
      <div className="gap-x-sm flex">
        {canAddFiles() ? (
          <Button
            onClick={onAddFilesClick}
            variant="contained"
            startIcon={
              <AddIcon className="text-white" width={12} height={12} />
            }
            className="!px-md !shadow-none"
          >
            {t('groups.groupFiles.addFiles')}
          </Button>
        ) : null}
        {canEditFiles() ? (
          <Button
            onClick={onBulkEdit}
            variant="outlined"
            startIcon={
              <PencilIcon className="text-primary" width={16} height={16} />
            }
            className="!px-md"
          >
            {t('groups.groupFiles.bulkEdit')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default GroupFilesHeader;
