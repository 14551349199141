import { Avatar, IconButton } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { useAuth } from '@app/hooks/useAuth';
import useDebounce from '@app/hooks/useDebounce';
import { useSearch } from '@app/hooks/useSearch';
import HelpIcon from '@assets/layout/help.svg?react';
import TuneIcon from '@assets/layout/tune.svg?react';
import HelpDeskMenu from '@components/help-desk';
import Logo from '@components/logo';
import PressableIcon from '@components/pressable-icon';
import TextField from '@components/text-field';

import ProfilePopover from './profile-popover';

interface HeaderWithSearch {
  searchBar: true;
  onSearchChange: (value: string) => void;
  onAdvancedSearchClick: () => void;
}

interface HeaderWithoutSearch {
  searchBar: false;
  onSearchChange?: undefined;
  onAdvancedSearchClick?: undefined;
}

type HeaderProps = HeaderWithSearch | HeaderWithoutSearch;

const Header = ({
  onSearchChange,
  onAdvancedSearchClick,
  searchBar,
}: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [isHelpDeskOpen, setIsHelpDeskOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const search = useSearch();
  const debouncedSearch = useDebounce({
    value: searchText,
    delay: 500,
  });
  const { user } = useAuth();

  const isInTags = useMemo(
    () => location.pathname.startsWith('/tags'),
    [location.pathname]
  );
  const { advanceStep, updateStageConfig } = useAppJoyride();
  updateStageConfig(7, 3, {
    onNext: () => navigate('/files'),
  });

  const avatarRef = useRef<HTMLDivElement | null>(null);
  const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false);

  useEffect(() => {
    if (isInTags) {
      navigate('/tags');
    }

    onSearchChange?.(debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, isInTags, navigate]);

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  const placeholder = useMemo(
    () =>
      isInTags
        ? t('layout.header.search.placeholder.tags')
        : t('layout.header.search.placeholder.default'),
    [isInTags, t]
  );

  const handleLogoClick = () => {
    navigate('/files');
    advanceStep(7, 3);
    setSearchText('');
  };

  return (
    <header className="py-md bg-gray-100 px-0">
      <div className="px-screen flex items-center justify-between">
        <div className="absolute w-[calc(100%-35px)] text-center">
          {searchBar ? (
            <TextField
              placeholder={placeholder}
              className="w-1/2"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              endAdornment={
                !isInTags && (
                  <PressableIcon
                    icon={<TuneIcon title="advancedSearch" />}
                    onClick={onAdvancedSearchClick}
                    altText={t('layout.header.search.advancedSearch')}
                  />
                )
              }
            />
          ) : null}
        </div>
        <span
          data-testid="logo"
          className="z-50 cursor-pointer"
          onClick={handleLogoClick}
          data-tour="groups-step-4"
        >
          <Logo />
        </span>
        <div className="flex items-center gap-x-4">
          <span data-tour="conclusions-step-2">
            <PressableIcon
              icon={<HelpIcon />}
              altText={t('layout.header.help')}
              onClick={() => setIsHelpDeskOpen(true)}
            />
          </span>
          <IconButton
            onClick={() => setIsProfilePopoverOpen(true)}
            aria-controls={isProfilePopoverOpen ? 'profile-popover' : undefined}
            aria-expanded={isProfilePopoverOpen ? 'true' : undefined}
            aria-label="profile"
          >
            <Avatar
              className="cursor-pointer"
              src={user?.picture}
              ref={avatarRef}
            />
          </IconButton>
        </div>
      </div>
      <ProfilePopover
        anchorElement={avatarRef.current}
        isOpen={isProfilePopoverOpen}
        onClose={() => setIsProfilePopoverOpen(false)}
      />
      <HelpDeskMenu
        open={isHelpDeskOpen}
        onClose={() => setIsHelpDeskOpen(false)}
      />
    </header>
  );
};

export default Header;
