import { useCallback, useMemo } from 'react';

import * as WebLoginServices from '@app/services/auth/login';
import { isRunningOnElectron } from '@app/utils/environment';
import { User } from '@filot/types/user';

const useFromApp = () => {
  const isApp = useMemo(() => isRunningOnElectron(), []);

  const getProfile = useCallback((): Promise<User | null> => {
    if (isApp) {
      return window.auth.getProfile();
    }

    return Promise.resolve(WebLoginServices.getProfile());
  }, [isApp]);

  const logout = useCallback(() => {
    if (isApp) {
      return window.auth.logout();
    }

    return Promise.resolve(WebLoginServices.logout());
  }, [isApp]);

  return {
    isApp,
    getProfile,
    logout,
  };
};

export default useFromApp;
