import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAlert, { AlertControls } from '@app/hooks/useAlert';
import useContextMenu from '@app/hooks/useContextMenu';
import useDownloadFile from '@app/hooks/useDownloadFile';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useMoveFile from '@app/hooks/useMoveFile';
import useRenameFile from '@app/hooks/useRenameFile';
import { File } from '@app/services/files/types';
import SyncIcon from '@assets/files/cloud-sync.svg?react';
import DownloadIcon from '@assets/files/download.svg?react';
import EditIcon from '@assets/files/edit.svg?react';
import HistoryIcon from '@assets/files/history.svg?react';
import MoveToIcon from '@assets/files/move_to.svg?react';
import OpenWithIcon from '@assets/files/open_with.svg?react';
import ViewerIcon from '@assets/files/viewer.svg?react';
import ExternalIcon from '@assets/shared/link-external.svg?react';
import TrashIcon from '@assets/shared/trash.svg?react';

import Loading from './loading';
import MoveFileModal from './move-file-modal';
import MoveToTrashAlertModal from './move-to-trash-alert-modal';
import RenameFileModal from './rename-file-modal';

interface FileCardContextMenuProps {
  anchorElement: HTMLElement | null;
  file: File;
  syncAlertControls: AlertControls;
  syncMutationControls: UseMutationResult<
    string,
    Error,
    {
      fileId: string;
    },
    unknown
  >;
  onPreview: () => void;
}

const ICON_SIZE = 16;

const FileCardContextMenu = ({
  file,
  anchorElement,
  syncAlertControls,
  syncMutationControls,
  onPreview,
}: FileCardContextMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isMoveToModalVisible, setIsMoveToModalVisible] = useState(false);
  const [isMoveToTrashAlertVisible, setIsMoveToTrashAlertVisible] =
    useState(false);
  const {
    mutateAsync: renameFile,
    isError: isRenameFileError,
    reset: resetFileNameMutation,
  } = useRenameFile();
  const {
    mutateAsync: moveFile,
    isError: isMoveFileError,
    reset: resetMoveFileMutation,
  } = useMoveFile();
  const {
    mutateAsync: downloadFile,
    isError: isDownloadFileError,
    reset: resetDownloadFileMutation,
  } = useDownloadFile();
  const { displayGlobal } = useAlert();
  const queryClient = useQueryClient();
  useErrorHandler(isRenameFileError, {
    callback: () => {
      displayGlobal({
        text: t('fileCardContextMenu.renameFileModal.error'),
        type: 'error',
      });
      resetFileNameMutation();
    },
  });
  useErrorHandler(isMoveFileError, {
    callback: () => {
      displayGlobal({
        text: t('fileCardContextMenu.moveToModal.error'),
        type: 'error',
      });
      resetMoveFileMutation();
    },
  });
  useErrorHandler(syncMutationControls.isError, {
    callback: () => {
      displayGlobal({
        text: t('fileCardContextMenu.syncFiles.error'),
        type: 'error',
      });
      syncMutationControls.reset();
    },
  });
  useErrorHandler(isDownloadFileError, {
    callback: () => {
      displayGlobal({
        text: t('fileCardContextMenu.downloadFile.error'),
        type: 'error',
      });
      resetDownloadFileMutation();
    },
  });

  /* v8 ignore start */
  const handleManageVersions = () => {
    navigate(`/file/${file.id}/versions`);
  };

  const handleSyncWithCloud = async () => {
    syncAlertControls.display();
    try {
      await syncMutationControls.mutateAsync({ fileId: file.id });
      displayGlobal({
        text: t('fileCardContextMenu.syncFiles.success'),
        type: 'success',
      });
      syncMutationControls.reset();
    } finally {
      syncAlertControls.hide();
    }
  };

  const handleDownload = async () => {
    const download = await downloadFile(file.id);
    const arrayBuffer = await download.arrayBuffer();

    await window.file.save(arrayBuffer, download.name);
  };
  /* v8 ignore stop */

  const { ContextMenu } = useContextMenu(anchorElement, {
    items: [
      {
        text: t('fileCardContextMenu.preview'),
        icon: (
          <OpenWithIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
        nestedOptions: [
          {
            icon: (
              <ViewerIcon
                className="text-black"
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            ),
            onClick: onPreview,
            text: t('fileCardContextMenu.preview'),
          },
          {
            icon: (
              <ExternalIcon
                className="text-black"
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            ),
            text: t('fileCardContextMenu.edit'),
          },
        ],
      },
      {
        /* v8 ignore next */
        onClick: () => {},
        text: 'separator',
        icon: <></>,
        separator: true,
      },
      {
        /* v8 ignore next */
        onClick: () => setIsRenameModalVisible(true),
        text: t('fileCardContextMenu.rename'),
        icon: (
          <EditIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
      {
        /* v8 ignore next */
        onClick: () => setIsMoveToModalVisible(true),
        text: t('fileCardContextMenu.moveToFolder'),
        icon: (
          <MoveToIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
      {
        onClick: handleManageVersions,
        text: t('fileCardContextMenu.manageVersions'),
        icon: (
          <HistoryIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
      {
        onClick: handleSyncWithCloud,
        text: t('fileCardContextMenu.syncWithCloud'),
        icon: (
          <SyncIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
      {
        onClick: handleDownload,
        text: t('fileCardContextMenu.download'),
        icon: (
          <DownloadIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
      {
        /* v8 ignore next */
        onClick: () => setIsMoveToTrashAlertVisible(true),
        text: t('fileCardContextMenu.moveToTrash'),
        icon: (
          <TrashIcon
            className="text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        ),
      },
    ],
  });

  /* v8 ignore start */
  const handleRename = async ({ newName }: { newName: string }) => {
    setIsRenameModalVisible(false);
    await renameFile({ fileId: file.id, newName });
    await queryClient.invalidateQueries({ queryKey: ['files'] });
  };

  const handleMove = async (newPath: string) => {
    setIsMoveToModalVisible(false);
    await moveFile({ fileId: file.id, newPath: `${newPath}/${file.fileName}` });
    await queryClient.invalidateQueries({ queryKey: ['files'] });
  };
  /* v8 ignore stop */

  return (
    <>
      <ContextMenu />
      <RenameFileModal
        isOpen={isRenameModalVisible}
        /* v8 ignore next */
        onClose={() => setIsRenameModalVisible(false)}
        onSubmit={handleRename}
      />
      <MoveFileModal
        isOpen={isMoveToModalVisible}
        /* v8 ignore next 2 */
        onClose={() => setIsMoveToModalVisible(false)}
        onSubmit={({ newPath }) => handleMove(newPath)}
      />
      <MoveToTrashAlertModal
        isOpen={isMoveToTrashAlertVisible}
        onClose={() => setIsMoveToTrashAlertVisible(false)}
        file={file}
      />
      <syncAlertControls.Alert
        severity="info"
        icon={<Loading size={12} />}
        className="items-center"
      >
        {t('fileCardContextMenu.syncFiles.syncing')}
      </syncAlertControls.Alert>
    </>
  );
};

export default FileCardContextMenu;
