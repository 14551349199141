import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FileAccessLevel } from '@app/services/files/types';
import CommentIcon from '@assets/files/comment.svg?react';
import EditorIcon from '@assets/files/editor.svg?react';
import ViewerIcon from '@assets/files/viewer.svg?react';

interface ShareFileAccessRendererProps {
  access: FileAccessLevel;
}

const ShareFileAccessRenderer = ({ access }: ShareFileAccessRendererProps) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (access === 'comment') {
      return {
        Icon: CommentIcon,
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.commenter'
        ),
      };
    }
    if (access === 'read') {
      return {
        Icon: ViewerIcon,
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.viewer'
        ),
      };
    }
    if (access === 'write') {
      return {
        Icon: EditorIcon,
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.editor'
        ),
      };
    }
  }, [access, t]);

  if (!content) {
    return null;
  }

  return (
    <div className="gap-x-sm flex items-center">
      <content.Icon width={18} height={18} className="text-black" />
      <p className="!text-base">{content.text}</p>
    </div>
  );
};

export default ShareFileAccessRenderer;
