import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useMergeTags = () => {
  const { mergeTags } = getTagsApi();

  const mutation = useMutation({
    mutationFn: mergeTags,
    mutationKey: ['tags-merge'],
  });

  return mutation;
};

export default useMergeTags;
