import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';
import { PaginationOptions } from '@filot/types/api';

const useYourGroups = (
  paginationOptions: PaginationOptions,
  userId?: string
) => {
  const { get } = getGroupsApi();

  const query = useQuery({
    queryFn: () => get(paginationOptions, userId),
    queryKey: ['groups', paginationOptions, userId],
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
  });

  return query;
};

export default useYourGroups;
