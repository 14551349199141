import { FormControl, Select, MenuItem } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SortOption } from '@app/services/files/types';
import SortAsc from '@assets/files/sort_asc.svg?react';
import SortDesc from '@assets/files/sort_desc.svg?react';
import PressableIcon from '@components/pressable-icon';

export interface SortProps {
  direction: 'asc' | 'desc';
  onDirectionChange: (newDirection: SortProps['direction']) => void;
  sortBy: SortOption;
  onSelectOption: (option: SortOption) => void;
}

const Sort = ({
  direction,
  sortBy,
  onDirectionChange,
  onSelectOption,
}: SortProps) => {
  const { t } = useTranslation();

  const directionIcon = useMemo(() => {
    if (direction === 'asc') {
      return <SortAsc title="asc" />;
    }

    return <SortDesc title="desc" />;
  }, [direction]);

  const switchDirection = (oldDirection: SortProps['direction']) => {
    if (oldDirection === 'asc') {
      return 'desc';
    }

    return 'asc';
  };

  return (
    <div className="pr-screen pt-md flex items-center justify-end">
      <PressableIcon
        icon={directionIcon}
        onClick={() => onDirectionChange(switchDirection(direction))}
      />
      <FormControl variant="standard" className="!ml-md w-40">
        <Select<SortOption>
          labelId="select-label"
          id="select"
          inputProps={{ 'data-testid': 'sort-by-input' }}
          value={sortBy}
          onChange={(e) => onSelectOption(e.target.value as SortOption)}
        >
          <MenuItem value="fileName">{t('fileList.sortOptions.name')}</MenuItem>
          <MenuItem value="type">{t('fileList.sortOptions.type')}</MenuItem>
          <MenuItem value="creation">
            {t('fileList.sortOptions.creation')}
          </MenuItem>
          <MenuItem value="organizationName">
            {t('fileList.sortOptions.organization')}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Sort;
