import { ClickAwayListener, Divider, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { createContext, forwardRef } from 'react';

import { DeletedFile } from '@app/services/files/types';
import FileIcon from '@components/file-icon';

import DeletedFileDetailsButtons from './deleted-file-details-buttons';
import DeletedFileDetailsInfo from './deleted-file-details-info';

// import DeletedFileDetailsVersions from './file-details-versions';

interface DeletedFileDetailsProps {
  file: DeletedFile;
  onClose: () => void;
}

export const DeletedFileDetailsContext = createContext<null | DeletedFile>(
  null
);

const DeletedFileDetails = ({ file, onClose }: DeletedFileDetailsProps) => {
  const Content = forwardRef<HTMLDivElement, unknown>((_, ref) => (
    <div ref={ref}>
      <DeletedFileDetailsContext.Provider value={file}>
        <div className="flex flex-row items-end">
          <FileIcon type={file.type} width={42} height={42} />
          <Typography className="!ml-md !mb-1 !text-xl">
            {file.fileName}
          </Typography>
        </div>
        <DeletedFileDetailsButtons
          onRestoreFile={() => {}}
          onDeleteForever={() => {}}
        />
        <Divider className="!my-sm" />
        <DeletedFileDetailsInfo file={file} />
      </DeletedFileDetailsContext.Provider>
    </div>
  ));

  const handleClickAway = ({ target }: MouseEvent | TouchEvent) => {
    if (
      target instanceof Element &&
      target.tagName.toLocaleLowerCase() !== 'body'
    ) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        id="file-details"
        data-testid="file-details"
        initial={{ translateX: 200 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 200 }}
        transition={{ type: 'keyframes' }}
        key={file.id}
        className="p-sm float-right flex h-full flex-col overflow-y-auto bg-white shadow-black drop-shadow-xl"
        style={{ width: 'min(33.33%, 30rem)' }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Content />
        </ClickAwayListener>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeletedFileDetails;
