import { useMutation } from '@tanstack/react-query';

import { getNotesApi } from '@app/services/notes/api';

const useDeleteNote = () => {
  const { deleteNote } = getNotesApi();
  const mutation = useMutation({
    mutationFn: deleteNote,
    mutationKey: ['delete-note'],
  });

  return mutation;
};

export default useDeleteNote;
