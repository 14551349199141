import { motion, useMotionValue, useTransform } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

interface FollowMouseProps {
  children: ReactNode;
  initialX?: number;
  initialY?: number;
}

const FollowMouse = ({
  children,
  initialX = 0,
  initialY = 0,
}: FollowMouseProps) => {
  const mouseX = useMotionValue(initialX);
  const mouseY = useMotionValue(initialY);

  const offsetX = useTransform(mouseX, (x) => x - initialX);
  const offsetY = useTransform(mouseY, (y) => y - initialY);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      mouseX.set(event.clientX);
      mouseY.set(event.clientY);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseX, mouseY]);

  return (
    <motion.div
      data-testid="follow-mouse"
      style={{
        position: 'absolute',
        top: initialY,
        left: initialX,
        x: offsetX,
        y: offsetY,
      }}
    >
      {children}
    </motion.div>
  );
};

export default FollowMouse;
