import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useInviteToGroup = () => {
  const { inviteUsers } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: inviteUsers,
    mutationKey: ['groups-invite-users'],
  });

  return mutation;
};

export default useInviteToGroup;
