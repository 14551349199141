import {
  Dispatch,
  ProviderProps,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export const SearchContext = createContext('');

export const SetSearchContext = createContext<Dispatch<SetStateAction<string>>>(
  () => {}
);

export const SearchContextProvider = ({
  children,
}: Omit<ProviderProps<string[]>, 'value'>) => {
  const [search, setSearch] = useState('');

  return (
    <SetSearchContext.Provider value={setSearch}>
      <SearchContext.Provider value={search}>{children}</SearchContext.Provider>
    </SetSearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
export const useSetSearch = () => useContext(SetSearchContext);
