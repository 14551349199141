import { Avatar, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth } from '@app/hooks/useAuth';
import TrashIcon from '@assets/shared/trash.svg?react';
import useAvatar from '@pages/files/hooks/useAvatar';

const ProfilePhoto = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { stringAvatar } = useAvatar();

  if (!user) {
    return null;
  }

  return (
    <div className="my-lg">
      <Typography variant="h2" className="!text-xl !font-normal">
        {t('profile.profilePhoto.title')}
      </Typography>
      <div className="mt-sm flex items-center gap-x-md">
        <Avatar
          className="!h-24 !w-24"
          src={user.picture}
          {...stringAvatar(user.name)}
        />
        <div className="flex flex-col gap-y-2">
          <Button variant="contained">
            {t('profile.profilePhoto.change')}
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <TrashIcon className="text-primary" width={12} height={12} />
            }
          >
            {t('profile.profilePhoto.remove')}
          </Button>
        </div>
      </div>
      <p className="mt-sm text-sm font-medium tracking-wider text-black/60">
        <Trans>
          Recommended photo size is 256 <>&times;</> 256 px
        </Trans>
      </p>
    </div>
  );
};

export default ProfilePhoto;
