/**
 * Utility class for handling cookies.
 */
class Cookies {
  /**
   * Get a cookie by name.
   * @param {string} name - The name of the cookie to retrieve.
   * @returns {string | null} The value of the cookie, or null if the cookie is not found.
   */
  static get(name: string): string | null {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`
      )
    );

    return matches ? decodeURIComponent(matches[1]) : null;
  }

  /* eslint-disable max-len */
  /**
   * Set a cookie.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {string} options - Optional settings for the cookie (expires, path, domain, secure).
   * @param {number | Date | string} [options.expires] - Expiration time of the cookie. Can be a number (in seconds), a Date object, or a string representing a date.
   * @param {string} [options.path] - The URL path the cookie is accessible from. Default is '/'.
   * @param {string} [options.domain] - The domain the cookie is available to. Default is the domain of the current document.
   * @param {boolean} [options.secure] - If true, the cookie will only be sent over secure (HTTPS) connections.
   */
  /* eslint-enable max-len */
  static set(
    name: string,
    value: string,
    options: {
      expires?: number | Date | string;
      path?: string;
      domain?: string;
      secure?: boolean;
    } = { secure: true }
  ): void {
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (options.expires) {
      let expires = options.expires;
      if (typeof expires == 'number') {
        const date = new Date();
        date.setTime(date.getTime() + expires * 1000);
        expires = date;
      }
      if (expires instanceof Date) {
        updatedCookie += '; expires=' + expires.toUTCString();
      } else {
        updatedCookie += '; expires=' + expires;
      }
    }

    if (options.path) {
      updatedCookie += '; path=' + options.path;
    }

    if (options.domain) {
      updatedCookie += '; domain=' + options.domain;
    }

    if (options.secure) {
      updatedCookie += '; secure';
    }

    document.cookie = updatedCookie;
  }

  /**
   * Delete a cookie by name.
   * @param {string} name - The name of the cookie to delete.
   * @param {string} [path] - The path of the cookie to delete (optional).
   */
  static delete(name: string, path: string = '/'): void {
    this.set(name, '', { expires: -1, path });
  }
}

export default Cookies;
