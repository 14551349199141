import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NewGroupOwner, PermissionLevel } from '@app/services/groups/types';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import { GroupDetailsContext } from '../group-details';

import DeleteGroupWarningModal from './delete-group-warning-modal';
import LeavingGroupWarningModal from './leave-group-warning-modal';
import NewGroupOwnerModal from './new-group-owner-modal';

const GroupDangerZone = () => {
  const { t } = useTranslation();
  const group = useContext(GroupDetailsContext);
  const { canDeleteGroup } = useGroupPermissions(group?.permission);

  const [isNewOwnerVisible, setIsNewOwnerVisible] = useState(false);
  const [isLeavingConfirmationVisible, setIsLeavingConfirmationVisible] =
    useState(false);
  const [newOwnerEmail, setNewOwnerEmail] = useState<null | string>(null);
  const [isDeletingConfirmationVisible, setIsDeletingConfirmationVisible] =
    useState(false);

  if (!group) {
    return null;
  }

  const handleLeave = () => {
    if (group.permission === PermissionLevel.OWNER) {
      setIsNewOwnerVisible(true);

      return;
    }

    setIsLeavingConfirmationVisible(true);
  };

  const handleDelete = () => {
    setIsDeletingConfirmationVisible(true);
  };

  const handleNewOwnerSubmit = ({ email }: NewGroupOwner) => {
    setNewOwnerEmail(email);
    setIsNewOwnerVisible(false);
    setIsLeavingConfirmationVisible(true);
  };

  return (
    <div>
      <p className="mb-md text-2xl">
        {t('groups.groupDetails.dangerZone.title')}
      </p>
      <div className="p-md gap-y-md flex flex-col rounded-sm border border-red-400">
        <div className="flex items-center justify-between">
          <div className="mr-md">
            <p className="text-lg font-bold">
              {t('groups.groupDetails.dangerZone.leaveGroup.title')}
            </p>
            <p className="text-lg">
              {t('groups.groupDetails.dangerZone.leaveGroup.description')}
            </p>
          </div>
          <Button
            variant="outlined"
            color="error"
            size="large"
            onClick={handleLeave}
            className="!w-48"
          >
            {t('groups.groupDetails.dangerZone.leaveGroup.button')}
          </Button>
        </div>
        {canDeleteGroup() ? (
          <div className="flex items-center justify-between">
            <div className="mr-md">
              <p className="text-lg font-bold">
                {t('groups.groupDetails.dangerZone.deleteGroup.title')}
              </p>
              <p className="text-lg">
                <Trans i18nKey="groups.groupDetails.dangerZone.deleteGroup.description">
                  The group will be deleted permanently. This action
                  <span className="font-bold"> cannot</span> be undone.
                </Trans>
              </p>
            </div>
            <Button
              variant="outlined"
              color="error"
              size="large"
              onClick={handleDelete}
              className="!w-48"
            >
              {t('groups.groupDetails.dangerZone.deleteGroup.button')}
            </Button>
          </div>
        ) : null}
      </div>
      <NewGroupOwnerModal
        isOpen={isNewOwnerVisible}
        onClose={() => setIsNewOwnerVisible(false)}
        onSubmit={handleNewOwnerSubmit}
      />
      <LeavingGroupWarningModal
        isOpen={isLeavingConfirmationVisible}
        onClose={() => setIsLeavingConfirmationVisible(false)}
        newOwnerEmail={newOwnerEmail ?? undefined}
      />
      <DeleteGroupWarningModal
        isOpen={isDeletingConfirmationVisible}
        onClose={() => setIsDeletingConfirmationVisible(false)}
      />
    </div>
  );
};

export default GroupDangerZone;
