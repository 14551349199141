import { useQuery } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useUserHasAccount = (email: string) => {
  const { userHasAccount } = getGroupsApi();
  const query = useQuery({
    queryFn: () => userHasAccount(email),
    queryKey: ['userHasAccount', email],
  });

  return query;
};

export default useUserHasAccount;
