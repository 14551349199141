import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useDeleteGroup = () => {
  const { deleteGroup } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: deleteGroup,
    mutationKey: ['delete-group'],
  });

  return mutation;
};

export default useDeleteGroup;
