import { Avatar, AvatarProps } from '@mui/material';
import {
  ChangeEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import useAvatar from '@pages/files/hooks/useAvatar';

import Loading from './loading';

interface UpdateAvatarProps extends AvatarProps {
  picture?: string;
  name: string;
  onUpdate: ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
}

const UpdateAvatar = ({
  name,
  picture,
  onUpdate,
  isLoading = false,
  ...props
}: UpdateAvatarProps) => {
  const { t } = useTranslation();
  const { stringAvatar } = useAvatar();
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const fileInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isLoading) {
      setIsUpdateVisible(false);
    }
  }, [isLoading]);

  const updateOverlayClasses = useMemo(
    () =>
      twMerge(
        'absolute bg-black/30 top-0 rounded-full flex items-center backdrop-blur-sm px-sm cursor-pointer',
        props.className
      ),
    [props.className]
  );

  const handleUploadClick = () => {
    if (isLoading) {
      return;
    }
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    document.getElementById('picture-upload')?.click();
  };

  return (
    <div
      className="relative"
      onClick={handleUploadClick}
      data-testid="update-avatar"
    >
      <input
        type="file"
        id="picture-upload"
        onChange={onUpdate}
        ref={fileInput}
        onClick={(e) => e.stopPropagation()}
        accept="image/*"
        className="hidden"
        data-testid="picture-upload"
      />
      <Avatar
        src={picture}
        {...(picture ? {} : stringAvatar(name))}
        onMouseEnter={() => setIsUpdateVisible(true)}
        onMouseLeave={() => setIsUpdateVisible(false)}
        {...props}
      />
      {isUpdateVisible || isLoading ? (
        <div
          className={updateOverlayClasses}
          onMouseEnter={() => setIsUpdateVisible(true)}
          onMouseLeave={() => setIsUpdateVisible(false)}
        >
          {isLoading ? (
            <div className="flex flex-1 justify-center">
              <Loading className="!text-white" />
            </div>
          ) : (
            <p className="text-center text-sm text-white">
              {t('components.updateAvatar.change')}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default UpdateAvatar;
