import { useMutation } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useBulkInvite = () => {
  const { bulkInvite } = getOrganizationApi();

  const mutation = useMutation({
    mutationFn: bulkInvite,
    mutationKey: ['organization-bulk-invite'],
  });

  return mutation;
};

export default useBulkInvite;
