import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enus from '../i18n/enus';

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    resources: {
      'en-US': { translation: enus },
    },
  })
  .catch((_e) => 0);
