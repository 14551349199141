import { useCallback, useEffect, useState } from 'react';

const useSafeValue = <T>(initialValue: T) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [safeValue, setSafeValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setSafeValue(initialValue);
      setCurrentValue(initialValue);
    }
  }, [initialValue]);

  const updateCurrentValue = useCallback((value: T) => {
    setCurrentValue(value);
  }, []);

  const resetToSafeValue = useCallback(() => {
    setCurrentValue(safeValue);
  }, [safeValue]);

  const updateSafeValue = useCallback((value: T) => {
    setSafeValue(value);
    setCurrentValue(value);
  }, []);

  return {
    updateSafeValue,
    updateCurrentValue,
    currentValue,
    resetToSafeValue,
  };
};

export default useSafeValue;
