const useCloseRequestModal = (isPending: boolean, reset?: () => void) => {
  const withCanClose =
    <T extends unknown[]>(callback: (...params: T) => void, ...params: T) =>
    () => {
      if (!isPending) {
        reset?.();
        callback(...params);
      }
    };

  return withCanClose;
};

export default useCloseRequestModal;
