import { getEnv } from '@app/config/env';

import getNotesMock from './mock';
import { getNotesService } from './service';
import { NotesApi } from './types';

const getNotesApi = (): NotesApi => {
  if (getEnv().mockApiCalls) {
    return getNotesMock();
  }

  return getNotesService();
};

export { getNotesApi };
