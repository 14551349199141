import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Group } from '@app/services/groups/types';

import useGroupPermissions from '../hooks/useGroupPermissions';

interface GroupsContextMenuProps {
  group: Group;
  anchorElement: HTMLElement | null;
  onClose: () => void;
  isOpen: boolean;
}

const GroupsContextMenu = ({
  group,
  anchorElement,
  onClose,
  isOpen,
}: GroupsContextMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canEditGroup } = useGroupPermissions(group.permission);

  const withClose = (callback: () => void) => () => {
    onClose();
    callback();
  };

  const handleDetailsClick = () => {
    navigate(`${group.id}`);
  };

  const handleManageFilesClick = () => {
    navigate(`${group.id}/files`);
  };

  const handleManageMembersClick = () => {
    navigate(`${group.id}/members`, {
      state: {
        group,
      },
    });
  };

  return (
    <Menu
      id="groups-context-menu"
      anchorEl={anchorElement}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'groups-context-menu',
      }}
    >
      {canEditGroup() ? (
        <MenuItem onClick={withClose(handleDetailsClick)}>
          {t('groups.contextMenu.groupDetails')}
        </MenuItem>
      ) : null}
      <MenuItem onClick={withClose(handleManageFilesClick)}>
        {t('groups.contextMenu.manageFiles')}
      </MenuItem>
      <MenuItem onClick={withClose(handleManageMembersClick)}>
        {t('groups.contextMenu.manageMembers')}
      </MenuItem>
    </Menu>
  );
};

export default GroupsContextMenu;
