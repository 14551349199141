import { SVGProps } from 'react';

import HomeFilledIcon from '@assets/layout/filled/home_filled.svg?react';
import TagsFilledIcon from '@assets/layout/filled/label_filled.svg?react';
import GroupsAltFilledIcon from '@assets/layout/filled/people_filled.svg?react';
import RecentFilledIcon from '@assets/layout/filled/schedule_filled.svg?react';
import FavoriteFilledIcon from '@assets/layout/filled/star_filled.svg?react';
import TrashFilledIcon from '@assets/layout/filled/trash_filled.svg?react';
import HomeIcon from '@assets/layout/home.svg?react';
import TagsIcon from '@assets/layout/label.svg?react';
import OrganizationsIcon from '@assets/layout/organizations.svg?react';
import GroupsAltIcon from '@assets/layout/people.svg?react';
import RecentIcon from '@assets/layout/schedule.svg?react';
import FavoriteIcon from '@assets/layout/star.svg?react';
import TrashIcon from '@assets/layout/trash.svg?react';
import { RoutesNames } from '@filot/types/navigator';

interface IconProps extends SVGProps<SVGSVGElement> {
  name: RoutesNames;
  filled?: boolean;
}

const useFilledIcon = () => {
  const iconsMap = new Map([
    ['home', HomeIcon],
    ['tags', TagsIcon],
    ['groups', GroupsAltIcon],
    ['recent', RecentIcon],
    ['favorites', FavoriteIcon],
    ['trash', TrashIcon],
    ['organizations', OrganizationsIcon],
    ['home_filled', HomeFilledIcon],
    ['tags_filled', TagsFilledIcon],
    ['groups_filled', GroupsAltFilledIcon],
    ['recent_filled', RecentFilledIcon],
    ['favorites_filled', FavoriteFilledIcon],
    ['trash_filled', TrashFilledIcon],
    ['organizations_filled', OrganizationsIcon],
  ]);

  const Icon = ({ name, filled = false, ...props }: IconProps) => {
    const icon = iconsMap.get(`${name}${filled ? '_filled' : ''}`);
    if (icon) {
      return icon({ ...props, title: name });
    }
  };

  return Icon;
};

export default useFilledIcon;
