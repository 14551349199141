import { useTranslation } from 'react-i18next';

import { FilePermissions } from '@app/services/groups/types';

import BulkEditHeader from './bulk-edit-header';
import BulkEditPermissionsForm from './bulk-edit-permissions-form';

interface BulkEditSetPermissionsProps {
  onBack: () => void;
  onNext: (permissions: FilePermissions) => void;
}

const BulkEditSetPermissions = ({
  onBack,
  onNext,
}: BulkEditSetPermissionsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <BulkEditHeader
        onBack={onBack}
        title={t('groups.bulkEditing.setPermissions.title')}
      />
      <div>
        <BulkEditPermissionsForm onSubmit={onNext} />
      </div>
    </div>
  );
};

export default BulkEditSetPermissions;
