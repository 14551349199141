import { useQuery } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';
import { File } from '@app/services/groups/types';

interface UseGroupFilesOptions {
  groupId: string;
  page: number;
}

const useGroupFiles = ({ groupId, page }: UseGroupFilesOptions) => {
  const { getFiles } = getGroupsApi();
  const query = useQuery({
    queryFn: () => getFiles({ page, groupId }),
    queryKey: ['files', page, groupId],
    staleTime: 5 * 60 * 1000,
  });

  const files: File[] | undefined = query.data?.map((file) => ({
    ...file,
    type: file.name?.split('.').at(1) ?? 'folder',
  }));

  return { ...query, data: files };
};

export default useGroupFiles;
