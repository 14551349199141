import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Joyride, { Props as JoyrideProps, Styles } from 'react-joyride';

import useAppJoyride from '@app/hooks/useAppJoyride';
import useDefaultHandler, {
  StageJoyrideProps,
} from '@app/hooks/useDefaultJoyrideHandler';

const useDefaultStyles = (): Partial<Styles> => {
  const {
    palette: { primary, text },
  } = useTheme();

  return {
    options: {
      primaryColor: primary.main,
      textColor: text.primary,
    },
  };
};

const useDefaultProps = (): Partial<JoyrideProps> => {
  const defaultStyles = useDefaultStyles();
  const { t } = useTranslation();

  return {
    styles: defaultStyles,
    continuous: true,
    hideCloseButton: true,
    showSkipButton: true,
    disableOverlayClose: true,
    locale: {
      last: t('joyride.buttons.next'),
      back: t('joyride.buttons.back'),
      skip: t('joyride.buttons.skip'),
      next: t('joyride.buttons.next'),
    },
  };
};

export const DashboardJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      steps={[
        {
          content: t('joyride.dashboard.step_1'),
          target: '[data-tour="dashboard-step-1"]',
          disableBeacon: true,
          placement: 'right',
        },
        {
          content: t('joyride.dashboard.step_2'),
          target: '[data-tour="files"]',
          disableBeacon: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const DocumentsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      spotlightClicks
      callback={withDefaultHandler()}
      stepIndex={step}
      steps={[
        {
          content: t('joyride.documents.step_1'),
          target: '[data-tour="documents-step-1"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.documents.step_2'),
          target: '[data-tour="files"]',
          disableBeacon: true,
          hideBackButton: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const FoldersJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      spotlightClicks
      callback={withDefaultHandler()}
      stepIndex={step}
      steps={[
        {
          content: t('joyride.folders.step_1'),
          target: '[data-tour="creating-folder-step-1"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.folders.step_2'),
          target: '[data-tour="files"]',
          disableBeacon: true,
          hideBackButton: true,
        },
        {
          content: t('joyride.folders.step_3'),
          target: '[data-tour="creating-folder-step-3"]',
          disableBeacon: true,
          hideBackButton: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const OrganizeDocumentsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      spotlightClicks
      callback={withDefaultHandler()}
      stepIndex={step}
      steps={[
        {
          content: t('joyride.organizeDocuments.step_1'),
          target: '[data-tour="files"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.organizeDocuments.step_2'),
          target: '[data-tour="files"]',
          disableBeacon: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const PreviewDocumentsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      stepIndex={step}
      steps={[
        {
          content: t('joyride.previewDocuments.step_1'),
          target: '[data-tour="preview-document-step-1"]',
          disableBeacon: true,
          spotlightClicks: true,
        },
        {
          content: t('joyride.previewDocuments.step_2'),
          target: '[data-tour="preview-document-step-2"]',
          disableBeacon: true,
          disableScrolling: true,
          hideBackButton: true,
          placement: 'left',
        },
        {
          content: t('joyride.previewDocuments.step_3'),
          target: '[data-tour="preview-document-step-3"]',
          disableBeacon: true,
          disableScrolling: true,
          placement: 'left',
        },
        {
          content: t('joyride.previewDocuments.step_4'),
          target: '[data-tour="preview-document-step-4"]',
          disableBeacon: true,
          disableScrolling: true,
          placement: 'left',
        },
        {
          content: t('joyride.previewDocuments.step_5'),
          target: '[data-tour="preview-document-step-5"]',
          disableBeacon: true,
          placement: 'left',
        },
        {
          content: t('joyride.previewDocuments.step_6'),
          target: '[data-tour="preview-document-step-6"]',
          disableBeacon: true,
          disableScrolling: true,
          hideBackButton: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const VersionsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      spotlightClicks
      stepIndex={step}
      steps={[
        {
          content: t('joyride.versions.step_1'),
          target: '[data-tour="versions-step-1"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.versions.step_2'),
          target: '[data-tour="versions-step-2"]',
          disableBeacon: true,
          disableScrolling: true,
          hideBackButton: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const TagsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      spotlightClicks
      stepIndex={step}
      steps={[
        {
          content: t('joyride.tags.step_1'),
          target: '[data-tour="tags-step-1"]',
          disableBeacon: true,
          placement: 'top',
        },
        {
          content: t('joyride.tags.step_2'),
          target: '[data-tour="tags-step-2"]',
          disableBeacon: true,
          hideBackButton: true,
        },
        {
          content: t('joyride.tags.step_3'),
          target: '[data-tour="tags-step-3"]',
          disableBeacon: true,
          hideBackButton: true,
        },
        {
          content: t('joyride.tags.step_4'),
          target: '[data-tour="tags-step-4"]',
          disableBeacon: true,
          disableScrolling: true,
          hideBackButton: true,
        },
        {
          content: t('joyride.tags.step_5'),
          target: '[data-tour="tags-step-5"]',
          disableBeacon: true,
          disableScrolling: true,
          hideBackButton: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const GroupsJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const { currentStage, isRunning, isOnboarding, step } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      spotlightClicks
      stepIndex={step}
      steps={[
        {
          content: t('joyride.groups.step_1'),
          target: '[data-tour="groups-step-1"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.groups.step_2'),
          target: '[data-tour="groups-step-2"]',
          disableBeacon: true,
          hideBackButton: true,
        },
        {
          content: t('joyride.groups.step_3'),
          target: '[data-tour="groups-step-3"]',
          disableBeacon: true,
          disableScrolling: true,
        },
        {
          content: t('joyride.groups.step_4'),
          target: '[data-tour="groups-step-4"]',
          disableBeacon: true,
          disableScrolling: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};

export const ConclusionJoyride = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const {
    currentStage,
    isRunning,
    isOnboarding,
    setOnboardingFinished,
    setStageConfig,
  } = useAppJoyride();
  const withDefaultHandler = useDefaultHandler({ stage, nextStage });
  const props = useDefaultProps();
  const { t } = useTranslation();
  setStageConfig(8, [
    {},
    {},
    {
      onNext: setOnboardingFinished,
    },
  ]);

  return (
    <Joyride
      {...props}
      callback={withDefaultHandler()}
      steps={[
        {
          content: t('joyride.conclusion.step_1'),
          placement: 'center',
          target: 'body',
          disableBeacon: true,
        },
        {
          content: t('joyride.conclusion.step_2'),
          target: '[data-tour="conclusions-step-2"]',
          disableBeacon: true,
        },
        {
          content: t('joyride.conclusion.step_3'),
          placement: 'center',
          target: 'body',
          disableBeacon: true,
        },
      ]}
      run={currentStage === stage && isRunning && isOnboarding}
    />
  );
};
