import { Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@app/hooks/useAuth';
import Error from '@assets/organizations/error.svg?react';
import Fail from '@assets/organizations/fail-creation.svg?react';
import Pending from '@assets/organizations/pending-creation.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import Empty from './components/empty';
import OrganizationTabs from './components/organization-tabs';
import useOrganization from './hooks/useOrganization';
import { OrganizationContext } from './hooks/useOrganizationContext';

const Organizations = () => {
  const { user } = useAuth();
  const { data, isError, isLoading, refetch } = useOrganization(user?.sub);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/files');
  };

  const handleContact = () => {
    alert('E-mail');
  };

  const Content = () => {
    if (isLoading) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="mb-lg flex flex-1 flex-col items-center justify-center">
          <Error className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            <Trans i18nKey="organization.error">
              Oops! We could not load your organization.
              <span
                className="cursor-pointer text-lg underline"
                onClick={() => refetch()}
              >
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (data === null) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Empty />
        </div>
      );
    }

    if (data?.status === 'pending') {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <Pending className="mb-lg" />
          <Typography className="!mb-md !text-2xl !font-semibold">
            {t('organization.newOrganization.pending.title')}
          </Typography>
          <Typography className="!mb-md">
            {t('organization.newOrganization.pending.text')}
          </Typography>
          <Button onClick={handleGoBack} variant="outlined">
            {t('organization.newOrganization.pending.goBack')}
          </Button>
        </div>
      );
    }

    if (data?.status === 'error') {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <Fail />
          <Typography className="!mb-md !text-2xl !font-semibold">
            {t('organization.newOrganization.error.title')}
          </Typography>
          <div className="gap-x-md flex">
            <Button onClick={handleGoBack} variant="outlined">
              {t('organization.newOrganization.error.goBack')}
            </Button>
            <Button onClick={handleContact} variant="contained">
              {t('organization.newOrganization.error.contactUs')}
            </Button>
          </div>
        </div>
      );
    }

    if (data?.status === 'created') {
      return (
        <div className="p-screen w-full">
          <OrganizationContext.Provider
            value={{ ...data, picture: data.branding?.logo_url }}
          >
            <OrganizationTabs />
          </OrganizationContext.Provider>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-1">
      <Content />
    </div>
  );
};

export default Organizations;
