import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useSharedFile = (sharedFileId: string) => {
  const { getSharedFile } = getFilesApi();

  const query = useQuery({
    queryFn: () => getSharedFile(sharedFileId),
    queryKey: ['files-shared', sharedFileId],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useSharedFile;
