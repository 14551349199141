import { List, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { sizes } from '@app/config/theme';
import { File } from '@app/services/files/types';
import NoResults from '@assets/files/no_results.svg?react';

import FolderCard from './folder-card';
import SearchResultItem from './search-result-item';

interface SearchResultsProps {
  results: File[];
  onFileClick?: (file: File) => void;
  onFileDoubleClick?: (file: File) => void;
  onFolderClick?: (folder: File) => void;
}

const SearchResults = ({
  results = [],
  onFileClick,
  onFileDoubleClick,
  onFolderClick,
}: SearchResultsProps) => {
  const { t } = useTranslation();

  if (results.every(({ type }) => type === 'folderDescription')) {
    return (
      <div className="flex flex-1 flex-col items-center">
        <NoResults width={sizes.xxl} height={sizes.xxl} />
        <Typography
          fontSize={20}
          className="!mt-screen !font-semibold !text-gray-700"
        >
          {t('fileList.searchResults.noResults.title')}
        </Typography>
        <Typography fontSize={14} className="!mt-sm !text-gray-500">
          {t('fileList.searchResults.noResults.text')}
        </Typography>
      </div>
    );
  }

  return (
    <List>
      {results?.some((file) => file.type === 'folder') ? (
        <motion.div
          layout
          className="grid-rows-auto mb-md grid-cols-file-list grid gap-8"
        >
          <AnimatePresence>
            {results?.map((file) =>
              file.type === 'folder' ? (
                <FolderCard
                  folder={file}
                  key={file.id}
                  onClick={onFolderClick}
                />
              ) : null
            )}
          </AnimatePresence>
        </motion.div>
      ) : null}
      {results?.some(
        (file) => file.type !== 'folder' && file.type !== 'folderDescription'
      ) ? (
        <motion.div layout>
          <AnimatePresence>
            {results.map((file) =>
              file.type !== 'folder' && file.type !== 'folderDescription' ? (
                <SearchResultItem
                  file={file}
                  key={file.id}
                  onFileClick={onFileClick}
                  onFileDoubleClick={onFileDoubleClick}
                />
              ) : null
            )}
          </AnimatePresence>
        </motion.div>
      ) : null}
    </List>
  );
};

export default SearchResults;
