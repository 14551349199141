import * as Sentry from '@sentry/electron/renderer';
import {
  init as reactInit,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { isRunningOnElectron } from '@app/utils/environment';

import { getEnv } from './env';

export const initSentry = () => {
  if (isRunningOnElectron()) {
    Sentry.init(
      {
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
      },
      () =>
        reactInit({
          environment: getEnv().mode,
          enabled: getEnv().mode !== 'development',
          dsn: 'https://24b2a1b4564c40f2579a31a6bb8daecd@o4507708409577472.ingest.us.sentry.io/4507818880794624',
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        })
    );
  } else {
    reactInit({
      environment: getEnv().mode,
      enabled: getEnv().mode !== 'development',
      dsn: 'https://e73c23b38b4ea5dd4357c4e77e2cd5d5@o4507708409577472.ingest.us.sentry.io/4507818892066816',
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,

      tracePropagationTargets: [/^\//, /^https:\/\/app.filot\.io/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
