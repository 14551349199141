import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import {
  ConclusionJoyride,
  DashboardJoyride,
  DocumentsJoyride,
  FoldersJoyride,
  GroupsJoyride,
  OrganizeDocumentsJoyride,
  PreviewDocumentsJoyride,
  TagsJoyride,
} from '@app/config/steps';
import * as Storage from '@app/utils/storage';

const noop = () => {};

const NEED_ONBOARDING = 'need_onboarding';

export interface StageConfig {
  onNext?: () => void;
  onPrevious?: () => void;
  onStepEnter?: () => void;
}

interface AppJoyrideReturn {
  start: () => Promise<void>;
  pause: () => void;
  resume: () => void;
  isRunning: boolean;
  isOnboarding: boolean;
  currentStage: number | null;
  setCurrentStage: (stage: number) => void;
  stages: StageConfig[][];
  setStageConfig: (stage: number, config: StageConfig[]) => void;
  updateStageConfig: (stage: number, step: number, config: StageConfig) => void;
  setStep: (step: number) => void;
  step: number;
  advanceStep: (stage?: number, step?: number) => void;
  retreatStep: (stage?: number, step?: number) => void;
  setOnboardingFinished: () => void;
  onStageFinished: () => void;
  startFromStage: (stage: number) => void;
  setOnStageFinished: (newFunction: () => void) => void;
  isSingleRun: boolean;
}

const AppJoyrideContext = createContext<AppJoyrideReturn>({
  currentStage: null,
  isRunning: false,
  isOnboarding: false,
  pause: noop,
  resume: noop,
  /* v8 ignore next */
  start: () => Promise.resolve(),
  setCurrentStage: noop,
  stages: [],
  setStageConfig: noop,
  updateStageConfig: noop,
  setStep: noop,
  step: 0,
  advanceStep: noop,
  retreatStep: noop,
  setOnboardingFinished: noop,
  onStageFinished: noop,
  startFromStage: noop,
  setOnStageFinished: noop,
  isSingleRun: false,
});

export const AppJoyrideProvider = ({ children }: PropsWithChildren) => {
  const [isRunning, setIsRunning] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [currentStage, setCurrentStage] = useState<null | number>(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [isSingleRun, setIsSingleRun] = useState(false);

  const stages = useRef<StageConfig[][]>([]);
  const onStageFinished = useRef<() => void>(noop);

  const start = useCallback(async () => {
    const needOnboarding = await Storage.getItem(NEED_ONBOARDING);

    if (needOnboarding === 'false') {
      return;
    }
    setCurrentStage(0);
    setIsOnboarding(true);
    setIsRunning(true);
  }, []);

  const startFromStage = useCallback((stage: number) => {
    setCurrentStage(stage);
    setIsSingleRun(true);
    setIsOnboarding(true);
    setIsRunning(true);
  }, []);

  const setOnStageFinished = useCallback((newFunction: () => void) => {
    onStageFinished.current = newFunction;
  }, []);

  const pause = useCallback(() => {
    setIsRunning(false);
  }, []);

  const resume = useCallback(() => {
    setIsRunning(true);
  }, []);

  const setStageConfig = useCallback((stage: number, config: StageConfig[]) => {
    stages.current[stage] = config;
  }, []);

  const updateStageConfig = useCallback(
    (stage: number, step: number, config: StageConfig) => {
      if (stages.current[stage]) {
        stages.current[stage][step] = config;
      }
    },
    []
  );

  const setOnboardingFinished = () => {
    setIsRunning(false);
    setIsOnboarding(false);
    void Storage.setItem(NEED_ONBOARDING, 'false');
  };

  const advanceStep = useCallback(
    (stage?: number, step?: number) => {
      if (stage !== undefined && currentStage !== stage) {
        return;
      }
      if (step !== undefined && stepIndex !== step) {
        return;
      }
      setStepIndex((currentStep) => currentStep + 1);
    },
    [currentStage, stepIndex]
  );

  const retreatStep = useCallback(
    (stage?: number, step?: number) => {
      if (stage !== undefined && currentStage !== stage) {
        return;
      }
      if (step !== undefined && stepIndex !== step) {
        return;
      }
      setStepIndex((currentStep) => currentStep - 1);
    },
    [currentStage, stepIndex]
  );

  return (
    <AppJoyrideContext.Provider
      value={{
        currentStage,
        isRunning,
        isOnboarding,
        start,
        pause,
        resume,
        setCurrentStage,
        stages: stages.current,
        setStageConfig,
        setStep: setStepIndex,
        step: stepIndex,
        updateStageConfig,
        advanceStep,
        retreatStep,
        setOnboardingFinished,
        onStageFinished: onStageFinished.current,
        startFromStage,
        setOnStageFinished,
        isSingleRun,
      }}
    >
      {children}
      <DashboardJoyride stage={0} />
      <DocumentsJoyride stage={1} />
      <FoldersJoyride stage={2} />
      <OrganizeDocumentsJoyride stage={3} />
      <PreviewDocumentsJoyride stage={4} nextStage={6} />
      {/* <VersionsJoyride stage={5} /> */}
      <TagsJoyride stage={6} />
      <GroupsJoyride stage={7} />
      <ConclusionJoyride stage={8} />
    </AppJoyrideContext.Provider>
  );
};

const useAppJoyride = () => useContext(AppJoyrideContext);

export default useAppJoyride;
