import { Button, ButtonProps } from '@mui/material';
import { useMemo } from 'react';

import Loading from './loading';

type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

const LoadingButton = ({ loading = false, ...props }: LoadingButtonProps) => {
  const loadingProps = useMemo<ButtonProps>(() => {
    if (loading) {
      return {
        endIcon: <Loading color="grey" size={18} />,
        disabled: true,
      };
    }

    return {};
  }, [loading]);

  return <Button {...props} {...loadingProps} />;
};

export default LoadingButton;
