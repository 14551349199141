import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';

import GroupAddFilesPermissionTableTableRow from './group-add-files-permission-table-row';

interface GroupAddFilesPermissionTableProps {
  files: Group['files'];
}

const GroupAddFilesPermissionTable = ({
  files,
}: GroupAddFilesPermissionTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="files-details-table">
        <colgroup>
          <col className="w-2/4" />
          <col className="w-1/6" />
          <col className="w-1/6" />
          <col className="w-1/6" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t('groups.groupFiles.table.header.file')}</TableCell>
            <TableCell className="!text-center">
              {t('groups.groupFiles.table.header.read')}
            </TableCell>
            <TableCell className="!text-center">
              {t('groups.groupFiles.table.header.write')}
            </TableCell>
            <TableCell className="!text-center">
              {t('groups.groupFiles.table.header.comment')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <GroupAddFilesPermissionTableTableRow key={file.id} file={file} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupAddFilesPermissionTable;
