import { Box, Button, FormControl, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import TextField from '@components/text-field';

interface RenameFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: { newName: string }) => void;
}

const RenameFileModal = ({
  isOpen,
  onClose,
  onSubmit,
}: RenameFileModalProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState, reset } = useForm<{
    newName: string;
  }>();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-small-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-lg">
          {t('fileCardContextMenu.renameFileModal.title')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className="!mb-md">
            <Controller
              name="newName"
              control={control}
              render={({ field }) => (
                <TextField
                  className="mt-md border"
                  {...field}
                  placeholder={t(
                    'fileCardContextMenu.renameFileModal.placeholder'
                  )}
                />
              )}
              defaultValue=""
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className="float-right"
            disabled={!formState.isDirty}
          >
            {t('fileCardContextMenu.renameFileModal.submit')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default RenameFileModal;
