import { Button, Typography, List, ListItem } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { File, FilePermissions } from '@app/services/groups/types';
import ErrorIcon from '@assets/groups/bulk-edit-error.svg?react';
import EditIcon from '@assets/groups/edit.svg?react';
import LoadingIcon from '@assets/groups/loading.svg';
import SuccessIcon from '@assets/shared/success.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import FileIcon from '@components/file-icon';

import BulkEditHeader from './bulk-edit-header';

interface BulkEditReviewProps {
  files: File[];
  permissions: FilePermissions;
  onSubmit: () => void;
  onBack: () => void;
  isError: boolean;
  isPending: boolean;
  onTryAgain: () => void;
  allSelected: boolean;
  isSuccess: boolean;
}

const BulkEditReview = ({
  files,
  onSubmit,
  permissions,
  onBack,
  isError,
  isPending,
  onTryAgain,
  allSelected,
  isSuccess,
}: BulkEditReviewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const permissionsString = useMemo(() => {
    const permissionsConceded = [];
    if (permissions.read) {
      permissionsConceded.push('read');
    }
    if (permissions.write) {
      permissionsConceded.push('write');
    }
    if (permissions.comment) {
      permissionsConceded.push('comment');
    }
    if (permissionsConceded.length === 0) {
      permissionsConceded.push('no');
    }

    return permissionsConceded.join(', ');
  }, [permissions]);

  const Content = () => {
    if (isError) {
      return (
        <div className="flex h-[50vh] flex-1 flex-col items-center justify-center">
          <ErrorIcon />
          <Typography className="!mt-24 !text-lg !font-medium">
            <Trans i18nKey="groups.bulkEditing.review.error">
              Oops! There's was an error updating the group.
              <span className="cursor-pointer underline" onClick={onTryAgain}>
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (isPending) {
      return (
        <div className="mt-lg flex flex-1 flex-col items-center justify-center">
          <img width={280} height={280} src={LoadingIcon} />
          <Typography className="!mt-lg !text-2xl !font-medium">
            {t('groups.bulkEditing.review.loading')}
          </Typography>
        </div>
      );
    }

    if (isSuccess) {
      return (
        <div className="mt-44 flex flex-col items-center justify-center">
          <SuccessIcon />
          <Typography className="!mt-md !text-2xl !font-medium">
            {t('groups.bulkEditing.review.success.title', {
              count: files.length,
            })}
          </Typography>
          <div className="gap-x-md mt-md flex">
            <Button
              variant="outlined"
              onClick={() => navigate(`/groups/${groupId}/files`)}
            >
              {t('groups.bulkEditing.review.success.manageFiles')}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(`/groups/${groupId}`)}
            >
              {t('groups.bulkEditing.review.success.group')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        {allSelected ? null : (
          <List className="!mt-lg max-h-[30vh] overflow-y-auto border border-gray-300">
            {files.map((file) => (
              <ListItem key={file.id} className="!px-md !py-sm !items-center">
                <FileIcon
                  width={26}
                  height={26}
                  className="mr-md"
                  type={file.type ?? 'pdf'}
                />
                <Typography>{file.name}</Typography>
              </ListItem>
            ))}
          </List>
        )}
        <div className="my-md flex flex-1 justify-end">
          <Button variant="contained" className="!px-lg" onClick={onSubmit}>
            {t('groups.bulkEditing.review.finish')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div>
      <BulkEditHeader
        onBack={onBack}
        title={t('groups.bulkEditing.review.title')}
      />
      <div className="!mt-lg flex items-center">
        <EditIcon width={20} height={20} />
        <Typography className="!pl-md !text-2xl" data-testid="review-title">
          {allSelected ? (
            <Trans
              i18nKey="groups.bulkEditing.review.editing_all"
              count={files.length}
            >
              Editing
              <span className="!text-3xl !font-bold">all</span>
              file to have
              <span className="!text-3xl !font-bold">
                <>{{ permissions: permissionsString }}</>
              </span>
              permissions
            </Trans>
          ) : (
            <Trans
              i18nKey="groups.bulkEditing.review.editing"
              count={files.length}
            >
              Editing
              <span className="!text-3xl !font-bold">
                <>{{ count: files.length }}</>
              </span>
              file to have
              <span className="!text-3xl !font-bold">
                <>{{ permissions: permissionsString }}</>
              </span>
              permissions
            </Trans>
          )}
        </Typography>
      </div>
      <Content />
    </div>
  );
};

export default BulkEditReview;
