import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import useAppJoyride from '@app/hooks/useAppJoyride';
import BugIcon from '@assets/layout/bug.svg?react';
import RequestIcon from '@assets/layout/note-stack-add.svg?react';
import CloseIcon from '@assets/shared/close.svg?react';
import ExternalIcon from '@assets/shared/link-external.svg?react';
import PlayIcon from '@assets/shared/play.svg?react';

import PressableIcon from './pressable-icon';

interface HelpDeskMenuProps {
  open: boolean;
  onClose: () => void;
}

const HelpDeskMenu = ({ open, onClose }: HelpDeskMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { startFromStage, setOnStageFinished, setOnboardingFinished } =
    useAppJoyride();

  const handleHowTo = (stage: number) => {
    navigate('/files');
    setOnStageFinished(() => {
      /* v8 ignore next */
      setOnboardingFinished();
    });
    startFromStage(stage);
  };

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box className="!py-screen !w-[20vw]" onClick={onClose}>
        <span className="mr-md flex justify-end">
          <PressableIcon
            icon={
              <CloseIcon
                className="text-black"
                width={12}
                height={12}
                title="close"
              />
            }
            onClick={onClose}
          />
        </span>
        <List>
          <Typography
            variant="h2"
            className="!mb-md !mx-screen !text-2xl !font-medium"
          >
            {t('components.helpDesk.contactUs.title')}
          </Typography>
          <ListItem disablePadding>
            <Link
              to="https://filot.io/report-a-bug/"
              target="_blank"
              className="flex-1"
            >
              <ListItemButton className="!py-sm !px-screen">
                <ListItemIcon className="!mr-sm !min-w-min">
                  <BugIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('components.helpDesk.contactUs.reportABug')}
                />
                <ExternalIcon width={12} height={12} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link
              to="https://filot.io/request-a-feature/"
              target="_blank"
              className="flex-1"
            >
              <ListItemButton className="!py-sm !px-screen">
                <ListItemIcon className="!mr-sm !min-w-min">
                  <RequestIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('components.helpDesk.contactUs.requestNewFeature')}
                />
                <ExternalIcon width={12} height={12} />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <Typography
            variant="h2"
            className="!my-md !mx-screen !text-2xl !font-medium"
          >
            {t('components.helpDesk.howTo.title')}
          </Typography>
          <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(2)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t('components.helpDesk.howTo.createFolders')}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(3)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t('components.helpDesk.howTo.moveDocuments')}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(4)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t(
                  'components.helpDesk.howTo.downloadAndAddNotesToDocuments'
                )}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(5)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t('components.helpDesk.howTo.useVersioning')}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(6)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t('components.helpDesk.howTo.tagDocuments')}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="!py-sm !px-screen !justify-between"
              onClick={() => handleHowTo(7)}
            >
              <ListItemText
                className="!max-w-[70%]"
                primary={t('components.helpDesk.howTo.shareDocuments')}
              />
              <PlayIcon width={12} height={12} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default HelpDeskMenu;
