import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { File } from '@app/services/files/types';
import TablePagination from '@components/table-pagination';

import TagDetailsFilesTableRow from './tag-details-files-table-row';

interface TagDetailsFilesTableProps {
  page: number;
  onPageChange: (newPage: number) => void;
  isPlaceholderData: boolean;
  total: number;
  files: File[];
  onFileClick?: (file: File) => void;
}

const TagDetailsFilesTable = ({
  onPageChange,
  page,
  isPlaceholderData,
  total,
  files,
  onFileClick,
}: TagDetailsFilesTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="files-details-table">
        <colgroup>
          <col className="w-2/5" />
          <col className="w-1/5" />
          <col className="w-2/5" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t('tags.tagDetails.filesTable.file')}</TableCell>
            <TableCell>{t('tags.tagDetails.filesTable.owner')}</TableCell>
            <TableCell>{t('tags.tagDetails.filesTable.sharedWith')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TagDetailsFilesTableRow
              key={file.id}
              file={file}
              onClick={() => onFileClick?.(file)}
            />
          ))}
        </TableBody>
        {total > 5 ? (
          <TableFooter>
            <TablePagination
              onPageChange={onPageChange}
              isPlaceholderData={isPlaceholderData}
              page={page}
              total={total}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default TagDetailsFilesTable;
