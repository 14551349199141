import { useCallback, useState } from 'react';

import { File, SortOption } from '@app/services/files/types';

export type UseSortResult = {
  direction: 'asc' | 'desc';
  onDirectionChange: (newDirection: 'asc' | 'desc') => void;
  sort: SortOption;
  onSortOptionChange: (sort: SortOption) => void;
  sortFiles: (files?: File[]) => File[];
};

const useSort = () => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [sort, setSort] = useState<SortOption>('fileName');

  const sortFiles = useCallback(
    (files?: File[]) => {
      const compareFunction = (fileA: File, fileB: File): number => {
        if (fileA[sort] === fileB[sort]) {
          if (sort !== 'fileName' && fileA.fileName !== fileB.fileName) {
            return fileA.fileName.localeCompare(fileB.fileName);
          }
          if (sort !== 'creation' && fileA.creation !== fileB.creation) {
            return fileA.creation - fileB.creation;
          }
          if (
            sort !== 'organizationName' &&
            fileA.organizationName !== fileB.organizationName
          ) {
            return fileA.organizationName.localeCompare(fileB.organizationName);
          }
          if (fileA.type !== fileB.type) {
            return fileA.type.localeCompare(fileB.type);
          }

          return 0;
        }

        return direction === 'asc'
          ? fileA[sort] > fileB[sort]
            ? 1
            : -1
          : fileB[sort] > fileA[sort]
            ? 1
            : -1;
      };

      return files?.sort(compareFunction) ?? [];
    },
    [direction, sort]
  );

  return {
    direction,
    onDirectionChange: setDirection,
    sort,
    onSortOptionChange: setSort,
    sortFiles,
  };
};

export default useSort;
