import { useQuery } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

interface UseProposedVersionsOptions {
  fileId: string;
}

const useProposedVersions = ({ fileId }: UseProposedVersionsOptions) => {
  const { getProposedVersions } = getFilesApi();
  const query = useQuery({
    queryFn: () => getProposedVersions(fileId),
    queryKey: ['files-versions-proposedVersions', fileId],
  });

  return query;
};

export default useProposedVersions;
