import { CallBackProps } from 'react-joyride';

import useAppJoyride from './useAppJoyride';

export interface StageJoyrideProps {
  stage: number;
  nextStage?: number;
}

const useDefaultHandler = ({
  stage,
  nextStage = stage + 1,
}: StageJoyrideProps) => {
  const {
    setCurrentStage,
    stages,
    setStep,
    currentStage,
    setOnboardingFinished,
    onStageFinished,
  } = useAppJoyride();

  const onFinishStage = () => {
    onStageFinished();
    setCurrentStage(nextStage);
    setStep(0);
  };

  const withDefaultHandler =
    (callback?: (data: CallBackProps) => void) => (data: CallBackProps) => {
      if (stage !== currentStage) {
        return;
      }
      const callbackStep = data.index;
      if (!stages[stage]) {
        stages[stage] = [];
      }
      switch (data.action) {
        case 'skip':
          setOnboardingFinished();
          break;
        case 'next':
          if (data.type === 'step:after') {
            if (stages[stage][callbackStep]) {
              stages[stage][callbackStep].onNext?.();
            }
            if (data.controlled) {
              setStep(callbackStep + 1);
            }
            if (callbackStep + 1 === data.size && data.controlled) {
              onFinishStage();
            }
          }
          if (data.type === 'step:before') {
            if (stages[stage][callbackStep]) {
              stages[stage][callbackStep].onStepEnter?.();
            }
          }
          break;
        case 'prev':
          if (data.type === 'step:after') {
            if (stages[stage][callbackStep]) {
              stages[stage][callbackStep].onPrevious?.();
            }
            if (data.controlled) {
              setStep(callbackStep - 1);
            }
          }
      }
      if (data.type === 'tour:end') {
        onFinishStage();
      }
      callback?.(data);
    };

  return withDefaultHandler;
};

export default useDefaultHandler;
