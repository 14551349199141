import { TextField, Button, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

export type OrganizationUserInviteFormInputs = {
  email: string;
  accessGrant: DateTime;
  accessExpiration: DateTime | null;
};

interface OrganizationUserInviteFormProps {
  onSubmit: SubmitHandler<OrganizationUserInviteFormInputs>;
  organizationDomain: string;
}

const OrganizationUserInviteForm = ({
  onSubmit,
  organizationDomain,
}: OrganizationUserInviteFormProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, formState, watch } =
    useForm<OrganizationUserInviteFormInputs>({
      defaultValues: {
        email: '',
        accessGrant: DateTime.now(),
        accessExpiration: null,
      },
    });
  const accessGrant = watch('accessGrant');

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      role="form"
      className="flex flex-1 flex-col justify-between"
    >
      <div className="flex flex-col">
        <Controller
          name="email"
          control={control}
          rules={{
            validate: (email) => {
              const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

              return emailRegex.test(`${email}@${organizationDomain}`);
            },
          }}
          render={({ field }) => (
            <TextField
              variant="standard"
              {...field}
              label={t('organization.users.inviteUsersModal.form.email')}
              className="!mb-md"
              InputProps={{
                endAdornment: <p>@{organizationDomain}</p>,
              }}
            />
          )}
        />

        <FormControl fullWidth className="mb-4">
          <Controller
            name="accessGrant"
            control={control}
            rules={{
              validate: (value) =>
                value !== null &&
                value.isValid &&
                value.diffNow('days').days > -1,
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  disablePast
                  slotProps={{ textField: { variant: 'standard' } }}
                  label={t(
                    'organization.users.inviteUsersModal.form.accessGrant'
                  )}
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="accessExpiration"
            control={control}
            rules={{
              validate: (value) =>
                value === null ||
                (value.isValid && accessGrant.diff(value, 'days').days < 0),
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  minDate={accessGrant}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: t(
                        'organization.users.inviteUsersModal.form.expiration.message'
                      ),
                    },
                  }}
                  label={
                    <p>
                      <Trans i18nKey="organization.users.inviteUsersModal.form.expiration.label">
                        Access expiration
                        <span className="text-gray-400">(optional)</span>
                      </Trans>
                    </p>
                  }
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
        disabled={!formState.isValid}
      >
        {t('organization.users.inviteUsersModal.form.review')}
      </Button>
    </form>
  );
};

export default OrganizationUserInviteForm;
