import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useRemoveFile = () => {
  const { removeFile } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: removeFile,
    mutationKey: ['groups-remove-file'],
  });

  return mutation;
};

export default useRemoveFile;
