import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useRenameFile = () => {
  const { renameFile } = getFilesApi();

  const query = useMutation({
    mutationFn: renameFile,
    mutationKey: ['renameFile'],
  });

  return query;
};

export default useRenameFile;
