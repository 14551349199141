import {
  MenuItem,
  Select,
  CircularProgress,
  FormControl,
  SelectProps,
} from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Option {
  value: string | number;
  label: string;
}

type InfiniteLoadSelectorProps = Omit<
  SelectProps<Option['value']>,
  'value' | 'onChange'
> & {
  options: Option[];
  value: Option['value'];
  onChange: (newValue: Option['value']) => void;
  onEndReached: () => void;
  isLoading?: boolean;
};

const InfiniteLoadSelector = forwardRef<
  HTMLDivElement,
  InfiniteLoadSelectorProps
>(({ options, onEndReached, isLoading, onChange, value, ...props }, ref) => {
  const { t } = useTranslation();

  /* v8 ignore start */
  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const bottomReached =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight + 50;
    if (bottomReached) {
      onEndReached();
    }
  };
  /* v8 ignore end */

  const handleChange: SelectInputProps<Option['value']>['onChange'] = (
    event
  ) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        disabled={isLoading}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 300 },
            onScroll: handleScroll,
          },
        }}
        inputProps={{
          'data-testid': 'select-input',
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {isLoading && (
          <MenuItem disabled className="gap-x-sm flex items-center">
            <CircularProgress size={20} />
            {t('components.infiniteLoadSelect.loading')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
});

export default InfiniteLoadSelector;
