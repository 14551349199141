import { useCallback } from 'react';

import useAdvancedSearch from '@app/hooks/useAdvancedSearch';
import { File } from '@app/services/files/types';

const useFilter = () => {
  const { parseToFilters } = useAdvancedSearch();

  const filterFiles = useCallback(
    (search: string, files: File[] = []) => {
      const filters = parseToFilters(search);
      const fileNameFilter = filters.find(
        (filter) => filter.name === 'fileName'
      );

      if (filters.length === 0) {
        return [];
      }

      if (filters.some((filter) => filter.name !== 'fileName')) {
        const typeFilter = filters.find((filter) => filter.name === 'type');
        const organizationNameFilter = filters.find(
          (filter) => filter.name === 'organizationName'
        );
        const pathFilter = filters.find((filter) => filter.name === 'path');

        return files.filter(
          ({ fileName, type, path, organizationName }) =>
            (!fileNameFilter ||
              fileName.match(new RegExp(fileNameFilter.value, 'gi'))) &&
            (!typeFilter || type.match(new RegExp(typeFilter.value, 'gi'))) &&
            (!organizationNameFilter ||
              organizationName.match(
                new RegExp(organizationNameFilter.value, 'gi')
              )) &&
            (!pathFilter || path.match(new RegExp(pathFilter.value, 'gi')))
        );
      }

      return files.filter(
        ({ fileName, type, path, organizationName }) =>
          fileName.match(new RegExp(fileNameFilter!.value, 'gi')) ||
          type.match(new RegExp(fileNameFilter!.value, 'gi')) ||
          path.match(new RegExp(fileNameFilter!.value, 'gi')) ||
          organizationName.match(new RegExp(fileNameFilter!.value, 'gi'))
      );
    },
    [parseToFilters]
  );

  return filterFiles;
};

export default useFilter;
