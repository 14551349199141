import {
  FormControl,
  Button,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAppNavigate from '@app/hooks/useAppNavigate';
import { ShareFile } from '@app/services/files/types';
import { File, Group } from '@app/services/groups/types';
import GroupsUserInput from '@components/groups-user-input';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

import { FileDetailsContext } from './file-details';

interface ShareFileFormProps {
  onSubmit: SubmitHandler<ShareFile>;
  initialUsers: string[] | undefined;
}

const ShareFileForm = ({ onSubmit, initialUsers }: ShareFileFormProps) => {
  const { setCurrentRoute } = useAppNavigate();
  const navigate = useNavigate();
  const { handleSubmit, control, formState, watch } = useForm<ShareFile>({
    defaultValues: {
      users: initialUsers ?? [],
      startDate: DateTime.now().startOf('day'),
      expirationDate: null,
      access: 'read',
    },
  });
  const startDate = watch('startDate');
  const { t } = useTranslation();
  const { isAvailable } = useUserInputContext();
  const file = useContext(FileDetailsContext);

  const handleGroupSelection = (group: Group) => {
    setCurrentRoute('groups');
    navigate(`/groups/${group.id}/files/add`, {
      state: {
        group,
        files: [
          {
            id: file?.id,
            name: file?.fileName,
            permissions: { comment: false, read: true, write: false },
            type: file?.type,
          },
        ] as File[],
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-md flex flex-1 flex-col justify-between"
    >
      <div>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="users"
            control={control}
            rules={{
              validate: (value) => value.length > 0,
            }}
            render={({ field }) => (
              <GroupsUserInput
                {...field}
                onUserFromOutside={() => {}}
                onGroupSelected={handleGroupSelection}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="startDate"
            control={control}
            rules={{
              validate: (value) =>
                value !== null &&
                value.isValid &&
                value.diffNow('days').days > -1,
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  disablePast
                  slotProps={{
                    textField: { variant: 'standard' },
                    popper: {
                      id: 'share-file-form',
                    },
                  }}
                  label={t(
                    'fileList.fileDetails.inviteUsersModal.form.accessGrant'
                  )}
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="expirationDate"
            control={control}
            rules={{
              validate: (value) =>
                value === null ||
                (value.isValid && startDate.diff(value, 'days').days < 0),
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  minDate={startDate}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: t(
                        'fileList.fileDetails.inviteUsersModal.form.accessExpiration.message'
                      ),
                    },
                    popper: {
                      id: 'share-file-form',
                    },
                  }}
                  label={
                    <p>
                      <Trans i18nKey="fileList.fileDetails.inviteUsersModal.form.accessExpiration.label">
                        Expiration date
                        <span className="text-gray-400">(optional)</span>
                      </Trans>
                    </p>
                  }
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <Typography className="!mb-sm !text-lg">
          {t('fileList.fileDetails.inviteUsersModal.form.generalAccess.label')}
        </Typography>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="access"
            control={control}
            render={({ field }) => (
              <Select
                variant="standard"
                {...field}
                MenuProps={{ id: 'share-file-form' }}
              >
                <MenuItem value="read">
                  {t(
                    'fileList.fileDetails.inviteUsersModal.form.generalAccess.viewer'
                  )}
                </MenuItem>
                <MenuItem value="comment">
                  {t(
                    'fileList.fileDetails.inviteUsersModal.form.generalAccess.commenter'
                  )}
                </MenuItem>
                <MenuItem value="write">
                  {t(
                    'fileList.fileDetails.inviteUsersModal.form.generalAccess.editor'
                  )}
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="!mt-md float-right self-end"
        type="submit"
        disabled={!formState.isValid || !isAvailable}
      >
        {t('fileList.fileDetails.inviteUsersModal.form.continue')}
      </Button>
    </form>
  );
};

export default ShareFileForm;
