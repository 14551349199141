import {
  Dispatch,
  ProviderProps,
  createContext,
  useContext,
  useReducer,
} from 'react';

import { Action, ActionWithoutPayload, Reducer } from '@filot/types/context';


type AddFolderAction = Action<'PUSH_FOLDER', string>;
type PopFolderAction = ActionWithoutPayload<'POP_FOLDER'>;
type SetFoldersAction = Action<'SET_FOLDERS', string[]>;

type BreadcrumbActions = AddFolderAction | PopFolderAction | SetFoldersAction;

interface BreadcrumbState {
  folders: string[];
}

const initialState: BreadcrumbState = {
  folders: [],
};

export const BreadcrumbStateContext = createContext(initialState);

export const BreadcrumbDispatchContext = createContext<
  Dispatch<BreadcrumbActions>
>(() => {});

const folderReducer: Reducer<BreadcrumbState, BreadcrumbActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'POP_FOLDER':
      return {
        ...state,
        folders: state.folders.slice(0, -1),
      };
    case 'PUSH_FOLDER':
      return {
        ...state,
        folders: [...state.folders, action.payload],
      };
    case 'SET_FOLDERS':
      return {
        ...state,
        folders: action.payload,
      };
    default:
      return state;
  }
};

export const BreadcrumbContextProvider = ({
  children,
}: Omit<ProviderProps<string[]>, 'value'>) => {
  const [state, dispatch] = useReducer(folderReducer, initialState);

  return (
    <BreadcrumbDispatchContext.Provider value={dispatch}>
      <BreadcrumbStateContext.Provider value={state}>
        {children}
      </BreadcrumbStateContext.Provider>
    </BreadcrumbDispatchContext.Provider>
  );
};

export const useBreadcrumb = () => useContext(BreadcrumbStateContext);
export const useUpdateBreadcrumb = () => useContext(BreadcrumbDispatchContext);
