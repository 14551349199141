import { Box, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

import { CreateTagRequest } from '@app/services/tags/types';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useCreateTag from '../hooks/useCreateTag';

import CreateTagForm from './create-tag-form';

interface CreateTagModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateTagModal = ({ isOpen, onClose }: CreateTagModalProps) => {
  const { t } = useTranslation();
  const { mutateAsync: createTag, isError, isPending, reset } = useCreateTag();
  const queryClient = useQueryClient();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = async (form: CreateTagRequest) => {
    await createTag(form);
    handleClose();
    await queryClient.invalidateQueries({ queryKey: ['tags'] });
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium">
            <Trans i18nKey="tags.createTagModal.error">
              Oops! There was an error creating new tag!
              <span className="cursor-pointer underline" onClick={reset}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <div className="mt-sm">
        <CreateTagForm onCancel={handleClose} onSubmit={handleSubmit} />
      </div>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={handleClose}>
      <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={handleClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('tags.createTagModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default CreateTagModal;
