import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAppNavigate from '@app/hooks/useAppNavigate';
import NotFoundImage from '@assets/404/not-found.svg?react';
import Logo from '@components/logo';

const NotFound = () => {
  const { navigate } = useAppNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col py-screen">
      <header className="flex justify-center">
        <Logo />
      </header>
      <div className="flex flex-1 items-center justify-between gap-x-lg px-[max(15vw,40px)] py-lg">
        <div className="flex-1">
          <h1 className="mb-lg text-5xl font-bold">{t('notFound.title')}</h1>
          <p className="text-3xl font-medium">{t('notFound.text')}</p>
          <p className="mt-lg text-lg font-bold">
            {t('notFound.possibleReasons.title')}
          </p>
          <ol className="mt-sm list-inside list-disc">
            <li className="text-lg">{t('notFound.possibleReasons.one')}</li>
            <li className="text-lg">{t('notFound.possibleReasons.two')}</li>
          </ol>
          <div className="mt-lg flex gap-x-sm">
            <Button
              variant="contained"
              onClick={() => navigate('home')}
              className="!w-32"
            >
              {t('notFound.buttons.home')}
            </Button>
            <Button className="!w-32" variant="outlined">
              {t('notFound.buttons.help')}
            </Button>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-end">
          <NotFoundImage width={380} height={380} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
