import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';
import InfoIcon from '@assets/groups/info.svg?react';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

import { useSelectedFiles } from '../hooks/useSelectedFiles';

interface AddFilesConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  group: Group;
}

const AddFilesConfirmationModal = ({
  onClose,
  isOpen,
  onSubmit,
  group,
}: AddFilesConfirmationModalProps) => {
  const { t } = useTranslation();
  const selectedFiles = useSelectedFiles();

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="mx-auto my-auto !flex w-tags-editor flex-col rounded bg-white p-screen shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <div className="flex flex-col items-center">
          <InfoIcon width={128} height={128} />
          <Typography className="!mt-md !text-2xl !font-medium" variant="h1">
            {t('groups.addFiles.confirmationModal.title', {
              count: selectedFiles.length,
              groupName: group.name,
            })}
          </Typography>
          <p className="text-lg">
            {t('groups.addFiles.confirmationModal.text')}
          </p>
        </div>
        <div className="mt-md flex gap-x-sm self-end">
          <Button onClick={onClose} variant="outlined">
            {t('groups.addFiles.confirmationModal.cancel')}
          </Button>
          <Button onClick={onSubmit} variant="contained">
            {t('groups.addFiles.confirmationModal.addFiles')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddFilesConfirmationModal;
