import { TextField, FormControl, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { NewGroup } from '@app/services/groups/types';
import UserInput from '@components/users-input';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

interface NewGroupFormProps {
  onSubmit: SubmitHandler<NewGroup>;
}

const NewGroupForm = ({ onSubmit }: NewGroupFormProps) => {
  const { handleSubmit, control, formState, watch } = useForm<NewGroup>({
    defaultValues: {
      name: '',
      users: [],
      startDate: DateTime.now().startOf('day'),
      expirationDate: null,
    },
  });
  const startDate = watch('startDate');
  const { t } = useTranslation();
  const { isAvailable } = useUserInputContext();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col justify-between"
    >
      <div>
        <Controller
          name="name"
          control={control}
          rules={{
            validate: (value) => value.length > 1,
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              variant="standard"
              className="!mb-md"
              {...field}
              label={t('groups.newGroupModal.name')}
            />
          )}
        />
        <FormControl fullWidth className="mb-4">
          <Controller
            name="users"
            control={control}
            rules={{
              validate: (value) => value.length > 0,
            }}
            render={({ field }) => (
              <UserInput {...field} onUserFromOutside={() => {}} />
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="startDate"
            control={control}
            rules={{
              validate: (value) =>
                value !== null &&
                value.isValid &&
                value.diffNow('days').days > -1,
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  disablePast
                  slotProps={{ textField: { variant: 'standard' } }}
                  label={t('groups.newGroupModal.startDate')}
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl fullWidth className="mb-4">
          <Controller
            name="expirationDate"
            control={control}
            rules={{
              validate: (value) =>
                value === null ||
                (value.isValid && startDate.diff(value, 'days').days < 0),
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  {...field}
                  minDate={startDate}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: t(
                        'groups.newGroupModal.expirationDate.message'
                      ),
                    },
                  }}
                  label={
                    <p>
                      <Trans i18nKey="groups.newGroupModal.expirationDate.label">
                        Expiration date
                        <span className="text-gray-400">(optional)</span>
                      </Trans>
                    </p>
                  }
                  className="!mb-md"
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
        disabled={!formState.isValid || !isAvailable}
      >
        {t('groups.newGroupModal.create')}
      </Button>
    </form>
  );
};

export default NewGroupForm;
