import { TextField } from '@mui/material';
import { forwardRef, useCallback, useEffect } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Loading from '@components/loading';

import useGenerateDnsRecord from '../hooks/useGenerateDnsRecord';

import { NewOrganizationFormInputs } from './new-organization-form';

interface DnsRecordFieldProps {
  domain: string;
  onChange: (dns: string) => void;
  control: Control<NewOrganizationFormInputs>;
}

const DnsRecordField = ({ domain, onChange, control }: DnsRecordFieldProps) => {
  const { t } = useTranslation();

  const {
    refetch: fetch,
    isLoading,
    isError,
    isSuccess,
  } = useGenerateDnsRecord(domain);

  const populateDns = useCallback(async () => {
    if (domain) {
      const { data } = await fetch();
      onChange(data!.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, fetch]);

  useEffect(() => {
    void populateDns();
  }, [populateDns]);

  const Render = forwardRef<
    HTMLInputElement,
    ControllerRenderProps<NewOrganizationFormInputs, 'dns'>
  >((field, ref) => {
    if (isLoading) {
      return (
        <div className="gap-y-sm flex flex-1 flex-col items-center">
          <Loading size={24} />
          <p className="text-sm">
            {t('organization.newOrganization.dns.creating')}
          </p>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="gap-y-sm flex flex-1 flex-col items-center">
          <p className="text-sm">
            <Trans i18nKey="organization.newOrganization.dns.error">
              Error generating DNS record! Please
              <span className="cursor-pointer underline" onClick={populateDns}>
                try again
              </span>
            </Trans>
          </p>
        </div>
      );
    }

    if (isSuccess) {
      return (
        <TextField
          variant="standard"
          {...field}
          ref={ref}
          InputProps={{
            readOnly: true,
          }}
          helperText={t('organization.newOrganization.dns.message')}
          label={
            <p>
              <Trans i18nKey="organization.newOrganization.dns.label">
                DNS record
                <span className="text-gray-400">
                  (to verify domain ownership)
                </span>
              </Trans>
            </p>
          }
          className="!mb-md"
        />
      );
    }
  });

  return (
    <Controller
      name="dns"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field }) => <Render {...field} />}
    />
  );
};

export default DnsRecordField;
