import { getEnv } from '@app/config/env';

import getOrganizationMock from './mock';
import getOrganizationService from './service';
import { OrganizationApi } from './types';

const getOrganizationApi = (): OrganizationApi => {
  if (getEnv().mockApiCalls) {
    return getOrganizationMock();
  }

  return getOrganizationService();
};

export { getOrganizationApi };
