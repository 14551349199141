import { useQuery } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useOrganization = (userId?: string) => {
  const { getOrganization } = getOrganizationApi();

  const query = useQuery({
    queryFn: () => getOrganization(userId),
    queryKey: ['organizations', userId],
    refetchOnMount: true,
  });

  return query;
};

export default useOrganization;
