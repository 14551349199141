import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BackArrow from '@assets/groups/back_arrow.svg?react';
import PressableIcon from '@components/pressable-icon';

interface BulkEditHeaderProps {
  title: string;
  onBack: () => void;
  onSelectAll?: (selected: boolean) => void;
  allSelected?: boolean;
}

const BulkEditHeader = ({
  onBack,
  title,
  onSelectAll,
  allSelected,
}: BulkEditHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <div className="gap-x-sm flex items-center">
        <PressableIcon
          onClick={onBack}
          icon={<BackArrow title="back" className="text-black" />}
        />
        <Typography className="!text-3xl" variant="h2">
          {title}
        </Typography>
      </div>
      {allSelected !== undefined && onSelectAll ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={allSelected}
              onChange={(ev) => onSelectAll(ev.target.checked)}
            />
          }
          label={
            <p className="!text-lg">
              {t('groups.bulkEditing.selectFiles.selectAll')}
            </p>
          }
        />
      ) : null}
    </div>
  );
};

export default BulkEditHeader;
