import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import { useAppLocation } from '@app/hooks/useAppLocation';
import { Group } from '@app/services/groups/types';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import PressableIcon from '@components/pressable-icon';

import { UpdateMemberRoleForm } from './components/group-members-permission-cell';
import GroupsMemberTable from './components/group-members-table';
import InviteNewUserModal from './components/invite-new-user-modal';
import WarningModal from './components/warning-modal';

const GroupMembers = () => {
  // const { groupId } = useParams();
  const {
    state: { group },
  } = useAppLocation<{ group: Group }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [shouldDisplayWarn, setShouldDisplayWarn] = useState(false);
  const [users, setUsers] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  // const { data, isLoading, isError, isPlaceholderData, refetch } =
  //   useGroupMembers({
  //     groupId: groupId!,
  //     page,
  //   });
  const { display, Alert } = useAlert();
  const formMethods = useForm<UpdateMemberRoleForm>();

  const handleInviteNext = (form: {
    users: string[];
    allFromOrganization: boolean;
  }) => {
    if (form.allFromOrganization) {
      setShouldDisplayWarn(false);
    } else {
      setShouldDisplayWarn(true);
    }
    setIsInviteModalVisible(false);
    setIsWarningModalVisible(true);
    setUsers(form.users);
  };

  const handleTryAgain = () => {
    setIsWarningModalVisible(false);
    setIsInviteModalVisible(true);
  };

  const handleInviteSuccess = () => {
    setIsWarningModalVisible(false);
    display();
  };

  const Content = () => (
    // if (isLoading) {
    //   return (
    //     <div className="flex h-56 flex-1 items-center justify-center">
    //       <Loading />
    //     </div>
    //   );
    // }

    // if (isError) {
    //   return (
    //     <div className="mt-56 self-center text-center">
    //       <ErrorIcon className="mb-md mx-auto" />
    //       <Typography className="!text-lg">
    //         <Trans i18nKey="groups.groupMembers.error">
    //           Oops! We could not load group's members.
    //           <span
    //             className="cursor-pointer underline"
    //             onClick={() => refetch()}
    //           >
    //             Try again?
    //           </span>
    //         </Trans>
    //       </Typography>
    //       <ErrorHelpReport />
    //     </div>
    //   );
    // }

    <>
      <div className="-mt-md float-right">
        <Button
          variant="contained"
          onClick={() => setIsInviteModalVisible(true)}
        >
          {t('organization.users.invite')}
        </Button>
      </div>
      <form>
        <FormProvider {...formMethods}>
          <GroupsMemberTable
            isPlaceholderData={false}
            members={group.users}
            onPageChange={setPage}
            page={page}
            total={10}
            permission={group.permission}
          />
        </FormProvider>
      </form>
    </>
  );

  return (
    <div className="p-screen w-full">
      <div className="gap-x-sm flex items-center">
        <PressableIcon
          onClick={() => navigate(-1)}
          icon={<BackArrow title="back" className="text-black" />}
        />
        <Typography variant="h2" className="!text-3xl">
          {t('groups.groupMembers.title', { groupName: group.name })}
        </Typography>
      </div>
      <Content />
      <Alert>{t('groups.groupMembers.warningModal.inviteSuccess')}</Alert>
      <InviteNewUserModal
        isOpen={isInviteModalVisible}
        onClose={() => setIsInviteModalVisible(false)}
        onSubmit={handleInviteNext}
      />
      <WarningModal
        isOpen={isWarningModalVisible}
        onClose={() => setIsWarningModalVisible(false)}
        users={users}
        onTryAgain={handleTryAgain}
        onSubmit={handleInviteSuccess}
        shouldDisplayWarn={shouldDisplayWarn}
      />
    </div>
  );
};

export default GroupMembers;
