import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import useUser from '@app/hooks/useUser';
import { DeletedFile } from '@app/services/files/types';

interface DeletedFileDetailsInfoProps {
  file: DeletedFile;
}

const InfoTitle = ({ children }: PropsWithChildren) => (
  <Typography className="!mt-sm !text-sm !font-bold">{children}</Typography>
);
const InfoValue = ({ children }: PropsWithChildren) => (
  <Typography className="!max-w-full overflow-hidden overflow-ellipsis !text-sm">
    {children}
  </Typography>
);

const DeletedFileDetailsInfo = ({ file }: DeletedFileDetailsInfoProps) => {
  const { t } = useTranslation();
  const { data: user } = useUser(file.id, true);

  return (
    <div className="mb-md">
      <Typography>{t('fileList.fileDetails.info.title')}</Typography>
      <InfoTitle>{t('trash.deletedFileDetails.info.deletion.title')}</InfoTitle>
      <InfoValue>
        {t('trash.deletedFileDetails.info.deletion.text', {
          name: user?.name ?? file.deletion.deletedBy,
          time: DateTime.fromISO(file.deletion.deletedAt).toLocaleString(),
          interpolation: {
            escapeValue: false,
          },
        })}
      </InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.type')}</InfoTitle>
      <InfoValue>{file.type.toLocaleUpperCase()}</InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.size')}</InfoTitle>
      <InfoValue>{file.size}</InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.owner')}</InfoTitle>
      <InfoValue>{file.organizationName}</InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.location')}</InfoTitle>
      <InfoValue>{file.path}</InfoValue>
    </div>
  );
};

export default DeletedFileDetailsInfo;
