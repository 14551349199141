import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface MoveFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: { newPath: string }) => void;
}

const MoveFileModal = ({ isOpen, onClose, onSubmit }: MoveFileModalProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState, reset } = useForm<{
    newPath: string;
  }>({
    defaultValues: {
      newPath: '/',
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-small-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-lg">
          {t('fileCardContextMenu.moveToModal.title')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className="!my-md">
            <Controller
              name="newPath"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                >
                  <MenuItem value="/">Root</MenuItem>
                  <MenuItem value="/AAAA folder">AAA Folder</MenuItem>
                  <MenuItem value="/FolderA">FolderA</MenuItem>
                  <MenuItem value="Mock error">Mock error</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className="float-right"
            disabled={!formState.isDirty}
          >
            {t('fileCardContextMenu.moveToModal.submit')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default MoveFileModal;
