import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MergeTagsRequest } from '@app/services/tags/types';
import MergeIcon from '@assets/tags/merge.svg?react';

interface MergeTagsConfirmationProps {
  mergeTagInfo: MergeTagsRequest;
  onCancel: () => void;
  onSubmit: () => void;
}

const MergeTagsConfirmation = ({
  mergeTagInfo,
  onCancel,
  onSubmit,
}: MergeTagsConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col items-center">
        <MergeIcon className="mb-md" />
        <p className="font-medium">
          {t('tags.mergeTagsModal.confirmation.title', {
            firstTag: mergeTagInfo.firstTag.name,
            secondTag: mergeTagInfo.secondTag.name,
            newTag: mergeTagInfo.newTagName,
          })}
        </p>
        <p className="text-sm">{t('tags.mergeTagsModal.confirmation.text')}</p>
      </div>
      <div className="gap-x-sm mt-md flex flex-1 justify-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('tags.mergeTagsModal.cancel')}
        </Button>
        <Button className="!px-md" variant="contained" onClick={onSubmit}>
          {t('tags.mergeTagsModal.merge')}
        </Button>
      </div>
    </>
  );
};

export default MergeTagsConfirmation;
