import { useQuery } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useFilePreview = (fileId: string) => {
  const { getFilePreview } = getFilesApi();

  const query = useQuery({
    queryFn: () => getFilePreview(fileId),
    queryKey: ['file-preview', fileId],
    select: ({ data }) => data,
  });

  return query;
};

export default useFilePreview;
