import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useDownloadFile = () => {
  const { download } = getFilesApi();

  const query = useMutation({
    mutationFn: download,
    mutationKey: ['file-download'],
  });

  return query;
};

export default useDownloadFile;
