import { useMemo } from 'react';

import LeftChevron from '@assets/groups/left-chevron.svg?react';
import PressableIcon from '@components/pressable-icon';

interface SelectFilesPathProps {
  path: string;
  onPathChange: (newPath: string) => void;
}

const SelectFilesPath = ({ path, onPathChange }: SelectFilesPathProps) => {
  const folders = useMemo(() => path.split('/'), [path]);
  const handleBackPath = () => {
    onPathChange(folders.slice(0, -1).join('/'));
  };

  return (
    <div className="mx-screen mt-md flex items-center">
      {folders.length > 1 ? (
        <PressableIcon
          icon={<LeftChevron title="back-folder" />}
          onClick={handleBackPath}
        />
      ) : null}
      <p className="ml-sm text-3xl" data-testid="folder-name">
        {path.split('/').at(-1)}
      </p>
    </div>
  );
};

export default SelectFilesPath;
