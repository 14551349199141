import { useEffect, useRef } from 'react';

const noop = () => {};

interface UseDoubleClickHandlerOptions {
  onClick: () => void;
  onDoubleClick: () => void;
  delay: number;
}

const useDoubleClickHandler = ({
  onClick,
  onDoubleClick,
  delay,
}: UseDoubleClickHandlerOptions) => {
  const cancelCallback = useRef(noop);
  const setCancelCallback = (newCancel: () => unknown) =>
    (cancelCallback.current = newCancel);

  useEffect(() => cancelCallback.current, []);

  const requestTimeout = (callback: () => unknown) => {
    const start = new Date().getTime();

    const loop = () => {
      const delta = new Date().getTime() - start;

      if (delta >= delay) {
        callback();
        setCancelCallback(noop);

        return;
      }

      const animationFrame = requestAnimationFrame(loop);
      setCancelCallback(() => cancelAnimationFrame(animationFrame));
    };

    const raf = requestAnimationFrame(loop);
    setCancelCallback(() => cancelAnimationFrame(raf));
  };

  const handleClick = () => {
    cancelCallback.current();
    requestTimeout(onClick);
  };

  const handleDoubleClick = () => {
    cancelCallback.current();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

export default useDoubleClickHandler;
