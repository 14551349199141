import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EmptyIcon from '@assets/tags/empty.svg?react';

const Empty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFileNavigate = () => {
    navigate('/files');
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <EmptyIcon />
      <p className="mt-md text-lg">{t('tags.empty.text')}</p>
      <p className="mb-lg text-2xl font-medium">{t('tags.empty.title')}</p>
      <Button
        variant="outlined"
        onClick={handleFileNavigate}
        className="!px-lg"
      >
        {t('tags.empty.button')}
      </Button>
    </div>
  );
};

export default Empty;
