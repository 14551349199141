import axios, { AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { getEnv } from '@app/config/env';
import Cookies from '@app/utils/cookies';
import { isRunningOnElectron } from '@app/utils/environment';
import {
  getRangedAccessToken,
  getRangedInstance,
} from '@app/utils/http-instance';
import { User } from '@filot/types/user';

const REFRESH_TOKEN = 'auth-r-t';

interface Token {
  access_token: string;
  id_token: string;
  refresh_token: string;
  token_type: 'Bearer';
  expires_in: number;
}

const authScope = 'openid profile offline_access';
const { clientId, domain } = getEnv().auth0;

const redirectUri = `${window.location.origin}/callback`;

let accessToken: null | string = null;
let profile: User | null = null;
let refreshToken: string | null = null;

export const getAccessToken = async () => {
  if (isRunningOnElectron()) {
    return await window.auth.getAccessToken();
  }

  return accessToken;
};

export const getProfile = () => profile;

export const getAuthenticationURL = () =>
  `${domain}/authorize?` +
  `audience=${domain}/api/v2/&` +
  `scope=${authScope}&` +
  'response_type=code&' +
  `client_id=${clientId}&` +
  `redirect_uri=${redirectUri}`;

export const refreshTokens = async () => {
  let currentRefreshToken = Cookies.get(REFRESH_TOKEN);

  if (currentRefreshToken) {
    const options = {
      method: 'POST',
      url: `${domain}/oauth/token`,
      headers: { 'content-type': 'application/json' },
      data: {
        grant_type: 'refresh_token',
        client_id: clientId,
        refresh_token: currentRefreshToken,
      },
    };
    try {
      const response = await axios<Token>(options);
      accessToken = response.data.access_token;
      profile = jwtDecode(response.data.id_token);
      currentRefreshToken = response.data.refresh_token;
      if (currentRefreshToken) {
        Cookies.set(REFRESH_TOKEN, currentRefreshToken);
      }
    } catch (error) {
      logout();
      throw error;
    }
  } else {
    throw new Error('No available refresh token.');
  }
};

export const loadTokens = async (callbackURL: string) => {
  const parsed = new URL(callbackURL);
  const code = parsed.searchParams.get('code');

  const exchangeOptions = {
    grant_type: 'authorization_code',
    client_id: clientId,
    code,
    redirect_uri: redirectUri,
  };

  const options: AxiosRequestConfig<string> = {
    method: 'POST',
    url: `${domain}/oauth/token`,
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify(exchangeOptions),
  };

  try {
    const response = await axios<Token>(options);

    accessToken = response.data.access_token;
    profile = jwtDecode(response.data.id_token);
    refreshToken = response.data.refresh_token;

    if (refreshToken) {
      Cookies.set(REFRESH_TOKEN, refreshToken);
    }
  } catch (error) {
    logout();
    throw error;
  }
};

export const login = async (callback: () => void) => {
  if (window.location.href.startsWith(redirectUri)) {
    await loadTokens(window.location.href);
    callback();
  } else {
    try {
      await refreshTokens();
      callback();
    } catch (error) {
      window.location.href = getAuthenticationURL();
    }
  }
};

interface PostLoginOptions {
  url?: string;
  id?: string;
}

export const postLogin = async ({ url, id }: PostLoginOptions = {}) => {
  const {
    filot: { users },
  } = getEnv();
  let userId = id;
  if (isRunningOnElectron()) {
    const user = await window.auth.getProfile();
    userId = user?.sub;
  }
  const usersAccessToken = await getRangedAccessToken('users');
  const instance = getRangedInstance('users');

  try {
    await instance.post(
      `${url ?? users.domain}/api/v1/users/${profile?.sub ?? userId}/post_login`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${usersAccessToken}`,
        },
      }
    );
  } catch (error) {
    // logout();
  }
};

export const logout = () => {
  Cookies.delete(REFRESH_TOKEN);
  accessToken = null;
  profile = null;
  refreshToken = null;
  window.location.href = getLogOutUrl();
};

export const getLogOutUrl = () => {
  const urlObject = new URL(redirectUri);

  return `${domain}/v2/logout?returnTo=${encodeURIComponent(
    `${urlObject.protocol}//${urlObject.host}`
  )}&client_id=${clientId}`;
};
