import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OrganizationUsersContextMenuProps {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onDeleteUser: () => void;
  isOpen: boolean;
}

const OrganizationUsersContextMenu = ({
  anchorElement,
  onClose,
  isOpen,
  onDeleteUser,
}: OrganizationUsersContextMenuProps) => {
  const { t } = useTranslation();

  const withClose = (callback: () => void) => () => {
    onClose();
    callback();
  };

  return (
    <Menu
      id="organization-users-context-menu"
      anchorEl={anchorElement}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'organization-users-context-menu',
      }}
    >
      <MenuItem onClick={withClose(onDeleteUser)}>
        {t('organization.users.table.contextMenu.removeUser')}
      </MenuItem>
    </Menu>
  );
};

export default OrganizationUsersContextMenu;
