import { Avatar, AvatarProps } from '@mui/material';

import useUser from '@app/hooks/useUser';
import useAvatar from '@pages/files/hooks/useAvatar';

interface UserAvatarUser {
  name: string;
  picture: string | null;
}

interface UserAvatarFromIdProps {
  id: string;
  user?: undefined;
}

interface UserAvatarFromUserProps {
  id?: undefined;
  user: UserAvatarUser;
}

type UserAvatarProps = AvatarProps &
  (UserAvatarFromIdProps | UserAvatarFromUserProps);

const UserAvatar = ({ id, user, ...props }: UserAvatarProps) => {
  const { data } = useUser(id, !!id);
  const { stringAvatar } = useAvatar();

  if (user) {
    return (
      <Avatar
        {...props}
        {...stringAvatar(user.name)}
        src={user.picture ?? undefined}
      />
    );
  }

  if (!data) {
    return <Avatar {...props} {...stringAvatar(id)} />;
  }

  return <Avatar {...props} {...stringAvatar(data.name)} src={data.picture} />;
};

export default UserAvatar;
