import { getEnv } from '@app/config/env';
import {
  getRangedAccessToken,
  getRangedInstance,
} from '@app/utils/http-instance';
import {
  Paginated,
  ServiceOptions,
  SuccessfulResponse,
} from '@filot/types/api';

import getTagsMock from './mock';
import { TagResponse, TagsApi } from './types';

const getTagsService = ({ url }: ServiceOptions = {}): TagsApi => {
  const {
    filot: { tags },
  } = getEnv();

  return {
    ...getTagsMock(),

    createTag: async ({ description, name, styling }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.post(
        `${url ?? tags.domain}/api/v1/tags/create`,
        {
          name,
          description,
          styling: {
            background_color: styling.backgroundColor,
            text_color: styling.textColor,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    get: async (paginationOptions, filters) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      const { data } = await instance.get<Paginated<TagResponse> | null>(
        `${url ?? tags.domain}/api/v1/tags/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
          params: {
            query: filters,
            page: paginationOptions.page,
            page_size: paginationOptions.rowsPerPage,
          },
        }
      );

      if (!data) {
        return {
          data: [],
          message: 'no tags found',
          pagination: {
            links: {
              self: '',
            },
            page: paginationOptions.page,
            page_size: paginationOptions.rowsPerPage,
            pages: 0,
            total: 0,
          },
          success: true,
        };
      }

      return data;
    },

    changeColors: async ({ background, id, text }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.put(
        `${url ?? tags.domain}/api/v1/tags/${id}/modify`,
        {
          styling: {
            background_color: background,
            text_color: text,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    editTag: async ({ id, description, name }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.put(
        `${url ?? tags.domain}/api/v1/tags/${id}/modify`,
        {
          ...(name ? { name } : {}),
          ...(description !== undefined ? { description } : {}),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    deleteTag: async (id) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.delete(`${url ?? tags.domain}/api/v1/tags/${id}/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });
    },

    mergeTags: async ({ firstTag, newTagName, secondTag }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      const { data } = await instance.post<SuccessfulResponse<TagResponse>>(
        `${url ?? tags.domain}/api/v1/tags/merge`,
        {
          merged_tag_name: newTagName,
          tags: [firstTag.id, secondTag.id],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );

      return data;
    },

    upsertTags: async ({ fileId, tagNames }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.post(
        `${url ?? tags.domain}/api/v1/files/${fileId}/tags`,
        {
          tags: tagNames,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    removeTag: async ({ fileId, tagId }) => {
      const accessToken = await getRangedAccessToken('tags');
      const instance = getRangedInstance('tags');

      await instance.delete(
        `${url ?? tags.domain}/api/v1/tags/${tagId}/files/${fileId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },
  };
};

export default getTagsService;
