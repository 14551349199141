import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Back from '@assets/groups/back_arrow.svg?react';
import PressableIcon from '@components/pressable-icon';
import Header from '@pages/layout/components/header';

import ProfileForm from './components/profile-form';
import ProfileOrganization from './components/profile-organization';
import ProfilePhoto from './components/profile-photo';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Header searchBar={false} />
      <div className="py-screen flex flex-1 flex-col overflow-auto px-[35vw]">
        <div className="gap-x-sm relative flex">
          <PressableIcon
            className="!absolute !-ml-12"
            onClick={() => navigate(-1)}
            icon={<Back title="back" className="text-black" />}
          />
          <Typography variant="h1" className="!text-3xl !font-bold">
            {t('profile.title')}
          </Typography>
        </div>
        <ProfilePhoto />
        <ProfileForm />
        <ProfileOrganization />
      </div>
    </>
  );
};

export default Profile;
