import {
  Menu,
  MenuItem,
  MenuProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ErrorIcon from '@assets/files/umbrella.svg?react';
import EmptyIcon from '@assets/tags/empty.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import TablePagination from '@components/table-pagination';

import useClosedVersions from '../hooks/useClosedVersions';
import useFileContext from '../hooks/useFileContext';

import WorkingClosedVersionsTableRow from './working-closed-versions-table-row';

const ClosedVersionsTable = () => {
  const file = useFileContext();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const {
    data: versions,
    isPlaceholderData,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useClosedVersions({
    fileId: file!.id,
    page,
  });

  const withClose =
    (callback: () => void, close?: MenuProps['onClose']) => () => {
      close?.({}, 'backdropClick');
      callback();
    };

  if (isLoading || (isError && isRefetching)) {
    return (
      <div className="mt-lg flex flex-1 justify-center">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="mt-lg gap-y-sm flex flex-1 flex-col items-center justify-center">
        <ErrorIcon />
        <p>
          <Trans i18nKey="fileVersions.closedVersions.error">
            There was an error loading closed versions.
            <span
              className="cursor-pointer underline"
              onClick={() => refetch()}
            >
              Try again?
            </span>
          </Trans>
        </p>
        <ErrorHelpReport />
      </div>
    );
  }

  if (versions) {
    if (versions.total === 0) {
      return (
        <div className="mt-sm flex flex-1 flex-col items-center">
          <EmptyIcon width={128} height={128} />
          <p className="mt-sm text-lg font-medium">
            {t('fileVersions.closedVersions.empty.title')}
          </p>
        </div>
      );
    }

    return (
      <TableContainer>
        <Table aria-label="files-details-table">
          <colgroup>
            <col className="w-1/2" />
            <col className="w-1/4" />
            <col className="w-1/4" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('fileVersions.closedVersions.table.name')}
              </TableCell>
              <TableCell className="!text-center">
                {t('fileVersions.closedVersions.table.closed')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.closedVersions.map((version) => (
              <WorkingClosedVersionsTableRow
                key={version.id}
                version={version}
                ContextMenu={(props) => (
                  <Menu {...props}>
                    <MenuItem onClick={withClose(() => {}, props.onClose)}>
                      {t('fileVersions.closedVersions.table.context.reopen')}
                    </MenuItem>
                    <MenuItem onClick={withClose(() => {}, props.onClose)}>
                      {t('fileVersions.closedVersions.table.context.download')}
                    </MenuItem>
                    <MenuItem onClick={withClose(() => {}, props.onClose)}>
                      {t('fileVersions.closedVersions.table.context.delete')}
                    </MenuItem>
                  </Menu>
                )}
              />
            ))}
          </TableBody>
          <TableFooter>
            {versions.total > 5 ? (
              <TablePagination
                onPageChange={setPage}
                page={page}
                total={versions.total}
                isPlaceholderData={isPlaceholderData}
              />
            ) : null}
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
};

export default ClosedVersionsTable;
