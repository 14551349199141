import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';

import GroupDetailsEditDatesModal from './group-details-edit-dates-modal';

interface GroupDetailsEditDatesProps {
  details: Group;
}

const GroupDetailsEditDates = ({ details }: GroupDetailsEditDatesProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dateText = useMemo(() => {
    const startDate = DateTime.fromISO(details.startDate);
    if (startDate.diffNow('day').days > 0) {
      return t('groups.groupDetails.accessNotReached', {
        date: startDate.toLocaleString(),
        interpolation: { escapeValue: false },
      });
    }
    if (!details.expirationDate) {
      return t('groups.groupDetails.neverExpires');
    }
    const expirationDate = DateTime.fromISO(details.expirationDate);
    if (expirationDate.diffNow('day').days < 0) {
      return t('groups.groupDetails.expired');
    }

    return t('groups.groupDetails.expires', {
      relative: expirationDate.toRelativeCalendar(),
    });
  }, [details.expirationDate, details.startDate, t]);

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>{dateText}</Button>
      <GroupDetailsEditDatesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        groupId={details.id}
        expirationDate={
          details.expirationDate
            ? DateTime.fromISO(details.expirationDate)
            : null
        }
        startDate={DateTime.fromISO(details.startDate)}
      />
    </>
  );
};

export default GroupDetailsEditDates;
