import { Button, List, ListItem } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { File } from '@app/services/groups/types';
import RightArrow from '@assets/shared/right-arrow.svg?react';
import TrashIcon from '@assets/shared/trash.svg?react';
import FileIcon from '@components/file-icon';
import PressableIcon from '@components/pressable-icon';

import { useSelectedFiles } from '../hooks/useSelectedFiles';

interface SelectedFilesListProps {
  onContinue: () => void;
  onDeselectFile: (file: File) => void;
}

const SelectedFilesList = ({
  onContinue,
  onDeselectFile,
}: SelectedFilesListProps) => {
  const { t } = useTranslation();
  const selectedFiles = useSelectedFiles();

  return (
    <AnimatePresence>
      <motion.div
        id="file-details"
        data-testid="file-details"
        initial={{ translateX: 200 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 200 }}
        transition={{ type: 'keyframes' }}
        className={`float-right flex h-full flex-col overflow-y-auto
         bg-slate-50 px-md py-screen shadow-black drop-shadow-xl`}
        style={{ width: 'max(25%, 25rem)' }}
      >
        <h2 className="text-3xl font-medium">
          {t('groups.addFiles.selectedFiles.title')}
        </h2>
        <List className="!mt-sm max-h-full overflow-y-auto border border-gray-300 !bg-white">
          {selectedFiles.map((selectedFile) => (
            <ListItem
              key={selectedFile.id}
              className="!items-center !justify-between !p-sm"
            >
              <div className="flex">
                <FileIcon
                  width={26}
                  height={26}
                  className="mr-md"
                  type={selectedFile.type!}
                />
                <p>{selectedFile.name}</p>
              </div>
              <PressableIcon
                icon={<TrashIcon className="text-black" title="trash" />}
                onClick={() => onDeselectFile(selectedFile)}
              />
            </ListItem>
          ))}
        </List>
        <div className="my-sm flex flex-1" />
        <Button
          onClick={onContinue}
          variant="contained"
          size="large"
          endIcon={<RightArrow className="!ml-sm" />}
        >
          {t('groups.addFiles.selectedFiles.button', {
            count: selectedFiles.length,
          })}
        </Button>
      </motion.div>
    </AnimatePresence>
  );
};

export default SelectedFilesList;
