import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePagination from '@app/hooks/usePagination';
import { Tag } from '@app/services/files/types';
import { MergeTagsRequest } from '@app/services/tags/types';

import useTags from '../hooks/useTags';

interface MergeTagsFormProps {
  onSubmit: (form: MergeTagsRequest) => void;
  onCancel: () => void;
  initialTag?: Tag;
}

const MergeTagsForm = ({
  onCancel,
  onSubmit,
  initialTag,
}: MergeTagsFormProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, formState, watch, setValue } =
    useForm<MergeTagsRequest>({
      defaultValues: {
        firstTag: initialTag,
        newTagName: '',
        secondTag: undefined,
      },
    });

  const firstTag = watch('firstTag');
  const secondTag = watch('secondTag');

  const { data: tags, isLoading } = usePagination({}, useTags);

  useEffect(() => {
    if (firstTag) {
      setValue('newTagName', firstTag.name);
    }
  }, [firstTag, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="firstTag"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Autocomplete
              options={tags ?? []}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              getOptionDisabled={(option) => option.id === secondTag?.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_e, data) => onChange(data)}
              value={firstTag ?? null}
              loading={isLoading}
              {...field}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label={t('tags.mergeTagsModal.firstTag')}
                />
              )}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="secondTag"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Autocomplete
              options={tags ?? []}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              getOptionDisabled={(option) => option.id === firstTag?.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_e, data) => onChange(data)}
              loading={isLoading}
              value={secondTag ?? null}
              {...field}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label={t('tags.mergeTagsModal.secondTag')}
                />
              )}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="newTagName"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              variant="standard"
              {...field}
              label={t('tags.mergeTagsModal.newTag')}
            />
          )}
        />
      </FormControl>
      <div className="gap-x-sm mt-lg flex justify-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('tags.mergeTagsModal.cancel')}
        </Button>
        <Button
          className="!px-md"
          variant="contained"
          type="submit"
          disabled={!formState.isValid}
        >
          {t('tags.mergeTagsModal.merge')}
        </Button>
      </div>
    </form>
  );
};

export default MergeTagsForm;
