import { Box, Button, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import WarningIcon from '@assets/groups/warning.svg?react';
import ErrorIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useRemoveFile from '../hooks/useRemoveFile';

interface GroupRemoveFileConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  groupId: string;
}

const GroupRemoveFileConfirmationModal = ({
  isOpen,
  onClose,
  groupId,
  fileId,
}: GroupRemoveFileConfirmationModalProps) => {
  const { t } = useTranslation();
  const {
    mutateAsync: removeFile,
    isPending,
    isError,
    reset,
  } = useRemoveFile();
  const { displayGlobal } = useAlert();
  const withCanClose = useCloseRequestModal(isPending, reset);

  const handleRemoveFile = async () => {
    await removeFile({ fileId, groupId });
    onClose();
    displayGlobal({
      text: t('groups.groupFiles.table.removeFileConfirmationModal.success'),
      type: 'success',
    });
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex h-32 flex-col items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center">
          <ErrorIcon width={128} height={128} />
          <p className="mx-lg my-sm text-center text-xl">
            <Trans i18nKey="groups.groupFiles.table.removeFileConfirmationModal.error">
              Oops! There was an error removing the file from the group.
              <span className="cursor-pointer underline" onClick={reset}>
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center">
        <WarningIcon width={128} height={128} />
        <p className="mt-sm text-xl font-medium">
          {t('groups.groupFiles.table.removeFileConfirmationModal.text')}
        </p>
        <p className="text-xl">
          {t('groups.groupFiles.table.removeFileConfirmationModal.continue')}
        </p>
        <Button
          variant="contained"
          onClick={handleRemoveFile}
          className="!mt-sm !self-end"
        >
          {t('groups.groupFiles.table.removeFileConfirmationModal.remove')}
        </Button>
      </div>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box
        className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
        id="share-file-confirmation"
      >
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography variant="h1" className="!text-2xl !font-medium">
          {t('groups.groupFiles.table.removeFileConfirmationModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default GroupRemoveFileConfirmationModal;
