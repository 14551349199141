import { useCallback } from 'react';

import { PermissionLevel } from '@app/services/groups/types';

const useGroupPermissions = (permission?: PermissionLevel) => {
  const canManageGroup = useCallback(
    () =>
      permission === PermissionLevel.OWNER ||
      permission === PermissionLevel.ADMIN ||
      permission === PermissionLevel.MANAGER,
    [permission]
  );

  const canSeeOtherUsers = useCallback(
    () =>
      permission === PermissionLevel.OWNER ||
      permission === PermissionLevel.ADMIN ||
      permission === PermissionLevel.MANAGER,
    [permission]
  );

  const canChangePermission = useCallback(
    () => permission === PermissionLevel.OWNER,
    [permission]
  );

  const canRemoveUsers = useCallback(
    (otherPermission: PermissionLevel) => {
      if (permission === PermissionLevel.OWNER) {
        return true;
      }
      switch (otherPermission) {
        case PermissionLevel.ADMIN:
          return false;
        case PermissionLevel.MANAGER:
          return permission === PermissionLevel.ADMIN;
        case PermissionLevel.MEMBER:
          return (
            permission === PermissionLevel.ADMIN ||
            permission === PermissionLevel.MANAGER
          );
        default:
          return false;
      }
    },
    [permission]
  );

  const canDeleteGroup = useCallback(
    () => permission === PermissionLevel.OWNER,
    [permission]
  );

  const canEditGroup = useCallback(
    () =>
      permission === PermissionLevel.OWNER ||
      permission === PermissionLevel.ADMIN,
    [permission]
  );

  const canEditFiles = useCallback(
    () =>
      permission === PermissionLevel.OWNER ||
      permission === PermissionLevel.ADMIN,
    [permission]
  );

  const canAddFiles = useCallback(
    () =>
      permission === PermissionLevel.OWNER ||
      permission === PermissionLevel.ADMIN,
    [permission]
  );

  return {
    canManageGroup,
    canSeeOtherUsers,
    canChangePermission,
    canRemoveUsers,
    canDeleteGroup,
    canEditGroup,
    canAddFiles,
    canEditFiles,
  };
};

export default useGroupPermissions;
