import { useMutation } from '@tanstack/react-query';

import { getNotesApi } from '@app/services/notes/api';

const useEditNote = () => {
  const { editNote } = getNotesApi();
  const mutation = useMutation({
    mutationFn: editNote,
    mutationKey: ['notes-edit'],
  });

  return mutation;
};

export default useEditNote;
