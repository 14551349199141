import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useRevokeSharingPermissions = () => {
  const { revokeSharingPermissions } = getFilesApi();
  const query = useMutation({
    mutationFn: revokeSharingPermissions,
    mutationKey: ['file-permissions-revoke'],
  });

  return query;
};

export default useRevokeSharingPermissions;
