import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { File, Group } from '@app/services/groups/types';
import TablePagination from '@components/table-pagination';

import BulkEditFilesTableRow from './bulk-edit-files-table-row';

interface BulkEditFilesTableProps {
  files: Group['files'];
  page: number;
  onPageChange: (newPage: number) => void;
  isPlaceholderData: boolean;
  total: number;
  selected: File[];
  onRowClick: (file: File) => void;
  onSelectPage: (value: boolean) => void;
  allSelected: boolean;
}

const BulkEditFilesTable = ({
  files,
  onPageChange,
  page,
  isPlaceholderData,
  total,
  onRowClick,
  onSelectPage,
  selected,
  allSelected,
}: BulkEditFilesTableProps) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleSelectPage = () => {
    if (selected.length === 0) {
      onSelectPage(true);
    } else {
      onSelectPage(false);
    }
  };

  return (
    <TableContainer className="my-md">
      <Table aria-label="files-details-table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  !allSelected &&
                  selected.length > 0 &&
                  selected.length < files.length &&
                  selected.some((item) =>
                    files.map(({ id }) => id).includes(item.id)
                  )
                }
                checked={
                  allSelected ||
                  (files.length > 0 && selected.length === files.length)
                }
                onChange={handleSelectPage}
                inputProps={{
                  'aria-label': 'select all',
                }}
              />
            </TableCell>
            <TableCell>
              {t('groups.bulkEditing.selectFiles.table.header.file')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files?.map((file) => (
            <BulkEditFilesTableRow
              key={file.id}
              file={file}
              onSelect={onRowClick}
              selected={
                allSelected || selected.some((item) => item.id === file.id)
              }
            />
          ))}
        </TableBody>
        <TableFooter>
          {total > 5 ? (
            <TablePagination
              isPlaceholderData={isPlaceholderData}
              onPageChange={onPageChange}
              onRowsPerPageChange={setRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              total={total}
            />
          ) : null}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BulkEditFilesTable;
