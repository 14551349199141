import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useUpdateGroupDetails = () => {
  const { updateGroupDetails } = getGroupsApi();
  const mutation = useMutation({
    mutationFn: updateGroupDetails,
    mutationKey: ['groups-update-details'],
  });

  return mutation;
};

export default useUpdateGroupDetails;
