import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';

import { File, Group } from '@app/services/groups/types';
import FileIcon from '@components/file-icon';

interface BulkEditFilesTableRowProps {
  file: FlatArray<Group['files'], 0>;
  selected: boolean;
  onSelect: (file: File) => void;
}

const BulkEditFilesTableRow = ({
  file,
  selected,
  onSelect,
}: BulkEditFilesTableRowProps) => (
  <TableRow key={file.name} onClick={() => onSelect(file)}>
    <TableCell padding="checkbox">
      <Checkbox
        color="primary"
        checked={selected}
        inputProps={{
          'aria-labelledby': file.id,
        }}
      />
    </TableCell>
    <TableCell>
      <div className="gap-sm flex items-center">
        <FileIcon type={file.type ?? 'pdf'} width={26} height={26} />
        <Typography>{file.name}</Typography>
      </div>
    </TableCell>
  </TableRow>
);

export default BulkEditFilesTableRow;
