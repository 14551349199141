import { SVGProps } from 'react';

import PdfIcon from '@assets/file-icons/pdf.svg?react';
import FolderIcon from '@assets/file-icons/shared_folder.svg?react';
import TxtIcon from '@assets/file-icons/txt.svg?react';

interface FileIconProps extends SVGProps<SVGSVGElement> {
  type: string;
}

const FileIcon = ({ type, ...props }: FileIconProps) => {
  const fileIconMap = new Map([
    ['pdf', PdfIcon],
    ['folder', FolderIcon],
    ['txt', TxtIcon],
  ]);

  return fileIconMap.get(type)?.({ ...props, title: type });
};

export default FileIcon;
