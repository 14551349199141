import { Button } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddFilesForm } from '@app/services/groups/types';

import { useSelectedFiles } from '../hooks/useSelectedFiles';

import GroupAddFilesPermissionTable from './group-add-files-permission-table';

interface GroupAddFilesSelectPermissionProps {
  onContinue: (form: AddFilesForm) => void;
}

const GroupAddFilesSelectPermission = ({
  onContinue,
}: GroupAddFilesSelectPermissionProps) => {
  const { t } = useTranslation();
  const selectedFiles = useSelectedFiles();

  const defaultValues = useMemo(() => {
    const values: AddFilesForm = {};
    selectedFiles.forEach((selectedFile) => {
      values[selectedFile.id] = { permissions: selectedFile.permissions };
    });

    return values;
  }, [selectedFiles]);

  const formMethods = useForm<AddFilesForm>({
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onContinue)}>
        <div className="mt-md flex flex-1 flex-col px-screen pb-screen">
          <GroupAddFilesPermissionTable files={selectedFiles} />
          <Button
            size="large"
            variant="contained"
            className="!mt-lg !self-end !px-lg"
            type="submit"
          >
            {t('groups.addFiles.addFiles')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default GroupAddFilesSelectPermission;
