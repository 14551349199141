import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useDeleteFilePreview = () => {
  const { deleteFilePreview } = getFilesApi();
  const mutation = useMutation({
    mutationFn: deleteFilePreview,
    mutationKey: ['file-preview-delete'],
  });

  return mutation;
};

export default useDeleteFilePreview;
