import { ClickAwayListener, Divider, Typography } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { createContext, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import useCloseable from '@app/hooks/useCloseable';
import useFileDetails from '@app/hooks/useFileDetails';
import { File } from '@app/services/files/types';
import Umbrella from '@assets/files/umbrella.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import FileIcon from '@components/file-icon';
import Loading from '@components/loading';

import FileDetailsButtons from './file-details-buttons';
import FileDetailsInfo from './file-details-info';
import FileDetailsNote from './file-details-note';
import FileDetailsPeople from './file-details-people';
import FilePreviewModal from './file-preview-modal';
// import FileDetailsVersions from './file-details-versions';

interface FileDetailsProps {
  id: string;
  onClose: () => void;
  onAddTag: () => void;
  deleteTagControls: UseMutationResult<
    void,
    Error,
    {
      fileId: string;
      tagId: string;
    },
    unknown
  >;
}

export const FileDetailsContext = createContext<null | File>(null);

const FileDetails = ({
  id,
  onClose,
  onAddTag,
  deleteTagControls,
}: FileDetailsProps) => {
  const { isLoading, isError, data: fileDetail, refetch } = useFileDetails(id);
  const { t } = useTranslation();
  const { Alert, display } = useAlert();
  const { resume, setOnStageFinished, isSingleRun, setOnboardingFinished } =
    useAppJoyride();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  useCloseable({ onClose });

  /* v8 ignore start */
  setOnStageFinished(() => {
    if (isSingleRun) {
      setOnboardingFinished();
    }
  });
  /* v8 ignore stop */

  useEffect(() => {
    if (deleteTagControls.isError) {
      deleteTagControls.reset();
      display();
    }
  }, [deleteTagControls, display]);

  const handleOpenPreview = () => {
    setTimeout(() => {
      setIsPreviewOpen(true);
    }, 200);
  };

  const Content = forwardRef<HTMLDivElement, unknown>((_, ref) => {
    if (isLoading) {
      return (
        <div className="mt-56 self-center" ref={ref}>
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="gap-y-md mt-56 self-center text-center" ref={ref}>
          <Umbrella className="mb-md mx-auto" />
          <Typography className="!text-lg">
            {t('fileList.fileDetails.error.title')}
          </Typography>
          <Typography
            className="!mb-md cursor-pointer !text-lg underline"
            onClick={() => refetch()}
          >
            {t('fileList.fileDetails.error.tryAgain')}
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (fileDetail) {
      return (
        <div ref={ref}>
          <FileDetailsContext.Provider value={fileDetail}>
            <div className="flex flex-row items-end">
              <FileIcon type={fileDetail.type} width={42} height={42} />
              <Typography className="!ml-md !mb-1 !text-xl">
                {fileDetail.fileName}
              </Typography>
            </div>
            <FileDetailsButtons
              onCloudSyncClick={() => {}}
              onDownloadClick={() => {}}
              onHistoryClick={() => {}}
              onPreviewClick={handleOpenPreview}
              isLocked={fileDetail.locked}
            />
            <Divider className="!my-sm" />
            <FileDetailsPeople
              usersId={fileDetail.people}
              ownerId={fileDetail.owner}
            />
            <Divider className="!mb-sm !mt-md" />
            {/* <FileDetailsVersions /> */}
            {/* <Divider className="!mb-sm !mt-md" /> */}
            <FileDetailsNote />
            <Divider className="!mb-md !mt-sm" />
            <FileDetailsInfo
              onDeleteTag={(tagId) =>
                deleteTagControls.mutateAsync({ fileId: id, tagId })
              }
              isDeleting={
                deleteTagControls.isPending
                  ? deleteTagControls.variables.tagId
                  : null
              }
              onAddTag={onAddTag}
            />
            {isPreviewOpen ? (
              <FilePreviewModal
                file={fileDetail}
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
              />
            ) : null}
          </FileDetailsContext.Provider>
        </div>
      );
    }

    return null;
  });

  const handleAnimationComplete = () => {
    /* v8 ignore next */
    resume();
  };

  const handleClickAway = ({ target }: MouseEvent | TouchEvent) => {
    if (
      target instanceof Element &&
      target.tagName.toLocaleLowerCase() !== 'body'
    ) {
      onClose();
    }
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          id="file-details"
          data-testid="file-details"
          initial={{ translateX: 200 }}
          animate={{ translateX: 0 }}
          exit={{ translateX: 200 }}
          transition={{ type: 'keyframes' }}
          key={id}
          onAnimationComplete={handleAnimationComplete}
          className="p-sm float-right flex h-full flex-col overflow-y-auto bg-white shadow-black drop-shadow-xl"
          style={{ width: 'min(33.33%, 30rem)' }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Content />
          </ClickAwayListener>
        </motion.div>
      </AnimatePresence>
      <Alert severity="error">
        {t('fileList.fileDetails.deleteTags.errorAlert')}
      </Alert>
    </>
  );
};

export default FileDetails;
