import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getTagsApi, tagResponseToTag } from '@app/services/tags/api';
import { TagResponse, TagWithFiles } from '@app/services/tags/types';
import { PaginationOptions } from '@filot/types/api';

interface UseTagsOptions {
  filters?: string;
}

const useTags = (
  paginationOptions: PaginationOptions,
  { filters }: UseTagsOptions = {}
) => {
  const { get } = getTagsApi();
  const query = useQuery({
    queryFn: () => get(paginationOptions, filters),
    queryKey: ['tags', paginationOptions, filters],
    select: (data) => {
      const tags: TagWithFiles[] | undefined = data.data.map(
        (tag: TagResponse) => tagResponseToTag(tag)
      );

      return { ...data, data: tags };
    },
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useTags;
