import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useUpdateSharingPermissions = () => {
  const { updateSharingPermissions } = getFilesApi();
  const query = useMutation({
    mutationFn: updateSharingPermissions,
    mutationKey: ['file-permissions-update'],
  });

  return query;
};

export default useUpdateSharingPermissions;
