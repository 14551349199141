import { createTheme } from '@mui/material/styles';

const spacing = {
  screen: 35,
  sm: 12,
  md: 24,
  lg: 40,
};

const sizes = {
  xxs: 12,
  xs: 16,
  sm: 24,
  md: 48,
  lg: 124,
  xl: 248,
  xxl: 320,
  xxxl: 500,
};

const lightModeTheme = createTheme({
  spacing: [...Object.values(spacing)],
  palette: {
    primary: {
      main: '#006cab',
      light: '#068ddb',
      dark: '#00456e',
    },
    secondary: {
      main: '#00AB7A',
      light: '#00D99B',
      dark: '#005E44',
      contrastText: '#1e1f1f',
    },
    warning: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      '300': '#E3D02688',
      contrastText: '#242105',
    },
    danger: {
      main: '#FFB65E',
      light: '#FFCC5E',
      dark: '#FF7E5E',
      '300': '#FFB65E88',
      contrastText: '#242105',
    },
    grey: {
      '50': '#F8F9FA',
      '100': '#EBEDEF',
      '200': '#DDE1E4',
      '300': '#CED3D8',
      '400': '#BDC4CB',
      '500': '#AAB4BC',
      '600': '#95A1AC',
      '700': '#808A93',
      '800': '#646D74',
      '900': '#3B3F44',
    },
  },
});

export { lightModeTheme, spacing, sizes };
