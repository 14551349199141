import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';

import { Paginated, PaginationOptions } from '@filot/types/api';

type UsePaginationResult<R> = Omit<
  UseQueryResult<Paginated<R> | null>,
  'data'
> & {
  data?: R[];
  page: number;
  onPageChange: (newPage: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  total: number;
};

const usePagination = <T extends unknown[], R>(
  paginationOptions: Partial<PaginationOptions> = {},
  queryStarter: (
    paginationOptions: PaginationOptions,
    ...params: T
  ) => UseQueryResult<Paginated<R> | null>,
  ...params: T
): UsePaginationResult<R> => {
  const [page, setPage] = useState(paginationOptions.page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationOptions.rowsPerPage ?? 5
  );

  const query = queryStarter({ page, rowsPerPage }, ...params);

  return {
    ...query,
    data: query.data?.data,
    page,
    rowsPerPage,
    total: query.data?.pagination?.total ?? 0,
    onPageChange: setPage,
    onRowsPerPageChange: setRowsPerPage,
  };
};

export default usePagination;
