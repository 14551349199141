import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import ErrorIcon from '@assets/files/umbrella.svg?react';
import EmptyIcon from '@assets/tags/empty.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import useFileContext from '../hooks/useFileContext';
import useProposedVersions from '../hooks/useProposedVersions';

import ProposedVersionsTableRow from './proposed-versions-table-row';

const ProposedVersionsTable = () => {
  const file = useFileContext();
  const { t } = useTranslation();
  const {
    data: versions,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useProposedVersions({ fileId: file!.id });

  if (isLoading || (isError && isRefetching)) {
    return (
      <div className="mt-lg flex flex-1 justify-center">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="mt-lg gap-y-sm flex flex-1 flex-col items-center justify-center">
        <ErrorIcon />
        <p>
          <Trans i18nKey="fileVersions.proposedVersions.error">
            There was an error loading proposed versions.
            <span
              className="cursor-pointer underline"
              onClick={() => refetch()}
            >
              Try again?
            </span>
          </Trans>
        </p>
        <ErrorHelpReport />
      </div>
    );
  }

  if (versions) {
    if (versions.length === 0) {
      return (
        <div className="mt-sm flex flex-1 flex-col items-center">
          <EmptyIcon width={128} height={128} />
          <p className="mt-sm text-lg font-medium">
            {t('fileVersions.proposedVersions.empty.title')}
          </p>
          <p className="text-sm">
            {t('fileVersions.proposedVersions.empty.description')}
          </p>
        </div>
      );
    }

    return (
      <TableContainer>
        <Table aria-label="files-details-table">
          <colgroup>
            <col className="w-1/2" />
            <col className="w-1/4" />
            <col className="w-1/4" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('fileVersions.proposedVersions.table.name')}
              </TableCell>
              <TableCell className="!text-center">
                {t('fileVersions.proposedVersions.table.acceptedBy')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.map((version) => (
              <ProposedVersionsTableRow key={version.id} version={version} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default ProposedVersionsTable;
