import { AvatarGroup, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProposedVersion } from '@app/services/files/types';
import Discard from '@assets/groups/cancel.svg?react';
import Save from '@assets/groups/save.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';

interface ProposedVersionsTableRowProps {
  version: ProposedVersion;
}

const ProposedVersionsTableRow = ({
  version,
}: ProposedVersionsTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className="!py-sm">
        <p>{version.name}</p>
      </TableCell>
      <TableCell className="!py-sm !text-center">
        <AvatarGroup max={2} className="!items-center !justify-center">
          {version.acceptedBy.length > 0 ? (
            version.acceptedBy.map((user) => (
              <UserAvatar
                className="!h-9 !w-9 !text-base"
                key={user}
                id={user}
              />
            ))
          ) : (
            <p>-</p>
          )}
        </AvatarGroup>
      </TableCell>
      <TableCell className="!py-sm !text-center">
        <div className="flex flex-row-reverse">
          <PressableIcon
            icon={<Save title="accept" className="text-gray-600" />}
            onClick={() => {}}
            altText={t('fileVersions.proposedVersions.table.approve')}
          />
          <PressableIcon
            icon={<Discard title="deny" className="text-gray-600" />}
            onClick={() => {}}
            altText={t('fileVersions.proposedVersions.table.deny')}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ProposedVersionsTableRow;
