import { Button, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessDates } from '@app/services/groups/types';
import ReactivateIcon from '@assets/groups/reactivate.svg?react';

import { GroupDetailsContext } from '../group-details';

interface GroupDetailsReactivateProps {
  onCancel: () => void;
  onSubmit: (
    form: AccessDates,
    accepted: true,
    restorePermissions: boolean
  ) => void;
  startDate: DateTime;
  expirationDate: DateTime | null;
}

const GroupDetailsReactivate = ({
  onCancel,
  onSubmit,
  startDate,
  expirationDate,
}: GroupDetailsReactivateProps) => {
  const { t } = useTranslation();
  const groupDetails = useContext(GroupDetailsContext);

  if (!groupDetails) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <ReactivateIcon className="my-sm self-center" />
      <Typography className="!text-center !text-2xl">
        {t('groups.groupDetails.reactivate.title', {
          groupName: groupDetails.name,
        })}
      </Typography>
      <Typography className="!text-center !text-sm">
        {t('groups.groupDetails.reactivate.message', {
          date: DateTime.fromISO(groupDetails.startDate).toLocaleString(),
          relative: DateTime.fromISO(
            groupDetails.startDate
          ).toRelativeCalendar(),
          interpolation: {
            escapeValue: false,
          },
        })}
      </Typography>
      <div className="mt-md flex flex-1 justify-between">
        <Button variant="outlined" onClick={onCancel}>
          {t('groups.groupDetails.reactivate.cancel')}
        </Button>
        <div className="flex gap-x-sm">
          <Button
            variant="outlined"
            onClick={() => onSubmit({ startDate, expirationDate }, true, false)}
          >
            {t('groups.groupDetails.reactivate.reset')}
          </Button>
          <Button
            variant="contained"
            onClick={() => onSubmit({ startDate, expirationDate }, true, true)}
          >
            {t('groups.groupDetails.reactivate.reactivate')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsReactivate;
