import { TextField, Button, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useDebounce from '@app/hooks/useDebounce';

import DnsRecordField from './dns-record-field';

export type NewOrganizationFormInputs = {
  name: string;
  domain: string;
  dns: string;
};

interface NewOrganizationFormProps {
  onSubmit: SubmitHandler<NewOrganizationFormInputs>;
}

const NewOrganizationForm = ({ onSubmit }: NewOrganizationFormProps) => {
  const { handleSubmit, control, formState, watch, setValue } =
    useForm<NewOrganizationFormInputs>({
      defaultValues: {
        name: '',
        domain: '',
        dns: '',
      },
    });
  const domainDebounced = useDebounce({
    value: watch('domain'),
    delay: 1000,
  });
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col justify-between"
    >
      <div className="flex flex-col">
        <Typography className="!mb-md !text-2xl !font-medium">
          {t('organization.newOrganization.title')}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            validate: (name) => name.length > 3,
          }}
          render={({ field }) => (
            <TextField
              variant="standard"
              {...field}
              label={t('organization.newOrganization.name')}
              className="!mb-md"
            />
          )}
        />
        <Controller
          name="domain"
          control={control}
          rules={{
            validate: (value) => value.length > 3,
          }}
          render={({ field }) => (
            <TextField
              variant="standard"
              {...field}
              helperText={t('organization.newOrganization.domain.message')}
              label={
                <p>
                  <Trans i18nKey="organization.newOrganization.domain.label">
                    Primary Organization Domain
                    <span className="text-gray-400">
                      (e.g. filot.io, google.com)
                    </span>
                  </Trans>
                </p>
              }
              className="!mb-md"
            />
          )}
        />
        <DnsRecordField
          onChange={(value) => setValue('dns', value)}
          domain={domainDebounced}
          control={control}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
        disabled={!formState.isValid}
      >
        {t('organization.newOrganization.submit')}
      </Button>
    </form>
  );
};

export default NewOrganizationForm;
