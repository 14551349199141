import { useAutocomplete } from '@mui/base/useAutocomplete';
import { UseAutocompleteProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import useCreateTag from '@app/hooks/useCreateTag';
import { Tag } from '@app/services/files/types';
import CheckIcon from '@assets/layout/check.svg?react';
import CloseIcon from '@assets/shared/close.svg?react';

import PressableIcon from './pressable-icon';

interface TagsInputProps {
  options: Tag[];
  value: Tag[];
  onChange: (value: Tag[]) => void;
  tagClass?: string;
  inputClass?: string;
  onlySearch?: boolean;
  autoCompleteProps?: Omit<
    UseAutocompleteProps<Tag, true, false, boolean>,
    'options'
  >;
  onInputValueChange?: (value: string) => void;
}

const TagsInput = forwardRef<unknown, TagsInputProps>(
  (
    {
      options = [],
      value,
      onChange,
      tagClass,
      inputClass,
      onlySearch = false,
      autoCompleteProps,
      onInputValueChange,
    },
    _ref
  ) => {
    const { palette } = useTheme();
    const createTag = useCreateTag();

    const inputClasses = twMerge(
      'w-24 flex-grow border-0 bg-white px-2 text-lg outline-none',
      inputClass
    );

    const tagClasses = twMerge(
      `m-1 box-content flex items-center overflow-hidden rounded-md border
       border-gray-200 bg-gray-50 pl-2 text-lg leading-5 outline-none`,
      tagClass
    );

    const parseChanges = (newValues: Array<string | Tag>) => {
      onInputValueChange?.('');
      onChange(
        newValues.map((newValue) =>
          typeof newValue === 'string' ? createTag(newValue) : newValue
        )
      );
    };

    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      setAnchorEl,
    } = useAutocomplete({
      id: 'tags-input',
      defaultValue: [],
      multiple: true,
      options,
      getOptionLabel: (option) =>
        typeof option === 'string' ? option : option.name,
      autoComplete: true,
      value,
      freeSolo: !onlySearch,
      onChange: (_, newValue) => parseChanges(newValue),
      isOptionEqualToValue: (option, valueToTest) =>
        option.id === valueToTest.id,
      disableCloseOnSelect: true,
      ...autoCompleteProps,
    });

    return (
      <div className="relative text-base text-opacity-65">
        <div {...getRootProps()}>
          <div
            className="flex max-h-72 min-h-14 flex-wrap overflow-auto rounded-sm border-0 border-b bg-white p-1"
            ref={setAnchorEl}
          >
            {value.map((option, index) => {
              const { onDelete, key, ...props } = getTagProps({ index });

              return (
                <div className={tagClasses} key={key} {...props}>
                  <span
                    className="flex-grow overflow-hidden whitespace-nowrap"
                    data-testid={`tag-${index}`}
                  >
                    {option.name}
                  </span>
                  <PressableIcon
                    icon={<CloseIcon width={8} />}
                    onClick={onDelete}
                  />
                </div>
              );
            })}
            <input
              className={inputClasses}
              data-testid="new-tags-input"
              {...getInputProps()}
              onChange={(e) => {
                onInputValueChange?.(e.target.value);
                getInputProps().onChange?.(e);
              }}
            />
          </div>
        </div>
        {groupedOptions.length > 0 ? (
          <ul
            className="max-h-250 absolute z-10 w-full list-none overflow-auto bg-white p-0 shadow-md"
            {...getListboxProps()}
          >
            {(groupedOptions as Tag[]).map((option, index) => (
              <li
                className={`px-md py-sm flex cursor-pointer transition-colors
                  duration-100 hover:bg-gray-50 aria-[selected='true']:bg-gray-100 aria-[selected='true']:font-medium`}
                {...getOptionProps({ option, index })}
                key={option.id}
              >
                <span className="flex-grow">{option.name}</span>
                {value.some((selected) => selected.id === option.id) ? (
                  <CheckIcon
                    fontSize="small"
                    title="check-icon"
                    color={palette.primary.main}
                  />
                ) : null}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
);

export default TagsInput;
