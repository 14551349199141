import { ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DeleteForever from '@assets/trash/delete-forever.svg?react';
import RestoreIcon from '@assets/trash/restore.svg?react';

interface DeletedFileDetailsButtonsProps {
  onRestoreFile: () => void;
  onDeleteForever: () => void;
}

const ICON_SIZE = 16;

const DeletedFileDetailsButtons = ({
  onDeleteForever,
  onRestoreFile,
}: DeletedFileDetailsButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-md flex flex-col" data-tour="preview-document-step-3">
      <ButtonBase
        focusRipple
        onClick={onRestoreFile}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-2"
      >
        <RestoreIcon
          className="mr-sm text-black"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
        <Typography>{t('trash.deletedFileDetails.buttons.restore')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onDeleteForever}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-4"
      >
        <DeleteForever className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>
          {t('trash.deletedFileDetails.buttons.deleteForever')}
        </Typography>
      </ButtonBase>
    </div>
  );
};

export default DeletedFileDetailsButtons;
