import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';
import { File } from '@app/services/groups/types';

const useGroupDetails = (id: string, page: number) => {
  const { getDetails } = getGroupsApi();

  const query = useQuery({
    queryFn: () => getDetails(id),
    queryKey: ['group-details', id, page],
    placeholderData: keepPreviousData,
  });

  if (!query.data) {
    return query;
  }

  const files: File[] = query.data.files.map((file: File) => ({
    ...file,
    type: file.name?.split('.').at(1) ?? 'folder',
  }));

  return {
    ...query,
    data: {
      ...query.data,
      files,
    },
  };
};

export default useGroupDetails;
