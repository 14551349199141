import { AvatarGroup, Divider } from '@mui/material';

import AddIcon from '@assets/shared/add-people.svg?react';

import PressableIcon from './pressable-icon';
import UserAvatar from './user-avatar';

interface UsersAvatarProps {
  usersId: string[];
  ownerId: string;
  onAdd?: () => void;
  max?: number;
}

const UsersAvatar = ({
  usersId,
  ownerId,
  max = 5,
  onAdd,
}: UsersAvatarProps) => {
  const handleAdd = () => {
    if (onAdd) {
      onAdd();
    }
  };

  return (
    <div className="flex items-center">
      <UserAvatar className="!h-9 !w-9 !text-base" id={ownerId} />
      {usersId.length > 0 ? (
        <>
          <Divider orientation="vertical" className="!mx-sm" flexItem />
          <AvatarGroup max={max}>
            {usersId.map((id) => (
              <UserAvatar className="!h-9 !w-9 !text-base" key={id} id={id} />
            ))}
          </AvatarGroup>
        </>
      ) : null}
      {onAdd ? (
        <PressableIcon
          onClick={handleAdd}
          icon={<AddIcon title="add-people" />}
        />
      ) : null}
    </div>
  );
};

export default UsersAvatar;
