import { Paper, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AdvancedSearchFormInputs, Filter } from '@app/services/files/types';
import DeleteIcon from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface FiltersProps {
  filters: Filter[];
  onRemoveFilter: (name: keyof AdvancedSearchFormInputs) => void;
}

const Filters = ({ filters, onRemoveFilter }: FiltersProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="gap-sm flex flex-wrap">
      {filters.map(({ name, value }) => (
        <Paper
          className="px-sm flex items-center py-2"
          elevation={2}
          key={name}
        >
          <Typography className="!text-lg">
            {t(`fileList.filters.options.${name}`)}
          </Typography>
          <Typography className="!ml-1 !text-lg !font-medium">
            {value}
          </Typography>
          <PressableIcon
            icon={
              <DeleteIcon
                title="delete-filter"
                width={12}
                color={palette.text.primary}
              />
            }
            onClick={() => onRemoveFilter(name)}
          />
        </Paper>
      ))}
    </div>
  );
};

export default Filters;
