import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { User } from '@app/services/organizations/types';
import HelpIcon from '@assets/organizations/help.svg?react';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';

export interface RemoveUsersFromOrganizationModalForm {
  newOwner: string;
  accessExpirationDate: DateTime<true>;
}

interface RemoveUsersFromOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onSubmit: (form: RemoveUsersFromOrganizationModalForm) => void;
}

const RemoveUsersFromOrganizationModal = ({
  isOpen,
  onClose,
  user,
  onSubmit,
}: RemoveUsersFromOrganizationModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const { handleSubmit, control, formState } =
    useForm<RemoveUsersFromOrganizationModalForm>({
      defaultValues: {
        accessExpirationDate: DateTime.now(),
        newOwner: '',
      },
    });

  return (
    <Modal className="flex" id="tag-editor" open={isOpen} onClose={onClose}>
      <Box className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('organization.users.table.removeUserModal.title')}
        </Typography>
        <div className="mt-md gap-x-sm flex items-center">
          <UserAvatar user={user} />
          <div>
            <p className="text-2xl font-bold">{user.name}</p>
            <p className="text-black/60">{user.email}</p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-md flex flex-1 flex-col justify-between"
        >
          <FormControl fullWidth className="!mb-md">
            <Controller
              name="newOwner"
              control={control}
              rules={{
                required: true,
                validate: (email) => {
                  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

                  if (!emailRegex.test(email.trim())) {
                    setError(true);

                    return false;
                  }
                  setError(false);

                  return true;
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label={t(
                    'organization.users.table.removeUserModal.newOwner.label'
                  )}
                  error={error}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={t(
                          'organization.users.table.removeUserModal.newOwner.message'
                        )}
                        arrow
                        placement="top"
                      >
                        <div>
                          <HelpIcon
                            className="text-gray-600"
                            width={18}
                            height={18}
                          />
                        </div>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth className="!mb-md">
            <Controller
              name="accessExpirationDate"
              control={control}
              rules={{
                validate: (value) =>
                  value !== null &&
                  value.isValid &&
                  value.diffNow('days').days > -1,
                required: true,
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    {...field}
                    disablePast
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                    label={t(
                      'organization.users.table.removeUserModal.accessExpiration'
                    )}
                    className="!mb-md"
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
          <Button
            disabled={!formState.isValid || error}
            type="submit"
            variant="contained"
            className="!px-md self-end"
          >
            {t('organization.users.table.removeUserModal.continue')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default RemoveUsersFromOrganizationModal;
