import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import Loading from '@components/loading';

import useGroupDetails from '../group-details/hooks/useGroupDetails';

import GroupFilesHeader from './components/group-files-header';
import GroupFilesTable from './components/group-files-table';

const GroupFiles = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { groupId: id = '' } = useParams();
  const navigate = useNavigate();

  const { data, isError, isLoading, isPlaceholderData } = useGroupDetails(
    id,
    page
  );
  const { display, Alert } = useAlert();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useErrorHandler(isError, {
    callback: () => {
      display(goBack);
    },
  });

  const handleBulkEdit = useCallback(() => {
    navigate('bulk-edit');
  }, [navigate]);

  const handleAddFiles = useCallback(() => {
    navigate('add', { state: { group: data } });
  }, [data, navigate]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex h-72 flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (data) {
      return (
        <>
          <GroupFilesHeader
            onBack={goBack}
            onBulkEdit={handleBulkEdit}
            onAddFilesClick={handleAddFiles}
            group={data}
          />
          <GroupFilesTable
            group={data}
            page={page}
            onPageChange={setPage}
            isPlaceholderData={isPlaceholderData}
            total={data.totalFiles}
          />
        </>
      );
    }

    return null;
  }, [
    data,
    goBack,
    handleAddFiles,
    handleBulkEdit,
    isLoading,
    isPlaceholderData,
    page,
  ]);

  return (
    <div className="m-screen w-full">
      {content}
      <Alert severity="error">{t('groups.groupFiles.error')}</Alert>
    </div>
  );
};

export default GroupFiles;
