import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PermissionLevel, UserRoles } from '@app/services/groups/types';
import TablePagination from '@components/table-pagination';

import GroupMembersTableRow from './group-members-table-row';

interface GroupsMemberTableReadOnlyProps {
  members: UserRoles;
  isPlaceholderData?: undefined;
  page?: undefined;
  onPageChange?: undefined;
  total?: undefined;
  permission: PermissionLevel;
  readOnly: true;
}

interface GroupsMemberTableEditableProps {
  members: UserRoles;
  isPlaceholderData: boolean;
  page: number;
  onPageChange: (page: number) => void;
  total: number;
  permission: PermissionLevel;
  readOnly?: false;
}

type GroupsMemberTableProps =
  | GroupsMemberTableReadOnlyProps
  | GroupsMemberTableEditableProps;

const GroupsMemberTable = ({
  members,
  isPlaceholderData,
  page,
  onPageChange,
  total,
  permission,
  readOnly,
}: GroupsMemberTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer className="!mt-md">
      <Table aria-label="members-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="!text-black/50">
              {t('groups.groupMembers.table.header.name')}
            </TableCell>
            <TableCell className="!text-black/50">
              {t('groups.groupMembers.table.header.email')}
            </TableCell>
            {!readOnly ? (
              <>
                <TableCell className="!text-black/50">
                  {t('groups.groupMembers.table.header.permissionLevel')}
                </TableCell>
                <TableCell />
              </>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(members).map((memberId) => (
            <GroupMembersTableRow
              key={memberId}
              memberId={memberId}
              memberPermission={members[memberId]}
              permission={permission}
              readOnly={readOnly}
            />
          ))}
        </TableBody>
        {!readOnly ? (
          <TableFooter>
            <TablePagination
              onPageChange={onPageChange}
              page={page}
              total={total}
              isPlaceholderData={isPlaceholderData}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default GroupsMemberTable;
