import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyGroupIcon from '@assets/groups/empty-groups.svg?react';

interface EmptyGroupsProps {
  onCreateGroupClick: () => void;
}

const EmptyGroups = ({ onCreateGroupClick }: EmptyGroupsProps) => {
  const { t } = useTranslation();

  return (
    <div className="my-lg flex flex-col items-center justify-center">
      <EmptyGroupIcon className="my-lg" />
      <Typography className="!my-lg !text-2xl !font-medium">
        {t('groups.empty.notSharing')}
      </Typography>
      <Button variant="contained" size="large" onClick={onCreateGroupClick}>
        {t('groups.empty.createGroup')}
      </Button>
    </div>
  );
};

export default EmptyGroups;
