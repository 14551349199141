import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useSyncFile = () => {
  const { syncFile } = getFilesApi();

  const mutation = useMutation({
    mutationFn: syncFile,
    mutationKey: ['syncFile'],
  });

  return mutation;
};

export default useSyncFile;
