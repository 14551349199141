import { IconButtonProps, Typography } from '@mui/material';
import { DragEventHandler, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { sizes } from '@app/config/theme';
import Drop from '@assets/files/drop.svg?react';

interface DroppableProps {
  children: ReactNode;
  className?: IconButtonProps['className'];
  dropClassName?: IconButtonProps['className'];
  onDrop?: DragEventHandler<HTMLSpanElement>;
}

const Droppable = ({
  children,
  className,
  dropClassName,
  onDrop,
}: DroppableProps) => {
  const { t } = useTranslation();
  const [holdingFile, setHoldingFile] = useState(false);

  const wrapperClass = useMemo(
    () =>
      twMerge(
        className,
        holdingFile
          ? [
              dropClassName,
              'rounded border border-dashed border-primary border-opacity-55 bg-primary/10 transition-all duration-300',
            ]
          : ''
      ),
    [className, dropClassName, holdingFile]
  );

  const handleDragOver: DragEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    setHoldingFile(true);
  };

  const handleDragLeave: DragEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    setHoldingFile(false);
  };

  const handleDrop: DragEventHandler<HTMLSpanElement> = (event) => {
    setHoldingFile(false);
    event.preventDefault();
    onDrop?.(event);
  };

  return (
    <span
      data-testid="droppable"
      className={wrapperClass}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {holdingFile ? (
        <div className="pointer-events-none flex flex-1 flex-col items-center justify-center">
          <Drop width={sizes.xxl} height={sizes.xxl} />
          <Typography fontSize={20} className="!mt-screen">
            {t('fileList.empty.drop')}
          </Typography>
        </div>
      ) : (
        children
      )}
    </span>
  );
};

export default Droppable;
