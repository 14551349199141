import { useMutation } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';

const useUpdatePicture = () => {
  const { uploadNewPicture } = getOrganizationApi();
  const mutation = useMutation({
    mutationFn: uploadNewPicture,
    mutationKey: ['organization-upload-picture'],
  });

  return mutation;
};

export default useUpdatePicture;
