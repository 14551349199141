import { useCallback, useMemo, useRef } from 'react';

import { isValidEmail } from '@app/utils/email';

const useEmailList = (emails: string) => {
  const ids = useRef(0);

  const newId = () => {
    ids.current += 1;

    return ids.current + 1;
  };

  const emailList: Array<{ email: string; id: number }> = useMemo(
    () =>
      emails.split(',').map((email) => ({ email: email.trim(), id: newId() })),
    [emails]
  );

  const isEmailDuplicate = useMemo(() => {
    const seen = new Set<string>();

    for (const email of emailList) {
      if (seen.has(email.email)) {
        return true;
      }
      seen.add(email.email);
    }

    return false;
  }, [emailList]);

  const validateEmail: (email: string) => {
    classes: string;
    error: 'invalid' | 'duplicated' | null;
  } = useCallback(
    (email: string) => {
      if (!isValidEmail(email)) {
        return {
          classes: 'text-red-600',
          error: 'invalid',
        };
      }

      if (emailList.filter((value) => value.email === email).length > 1) {
        return {
          classes: 'text-red-600',
          error: 'duplicated',
        };
      }

      return {
        classes: '',
        error: null,
      };
    },
    [emailList]
  );

  return {
    isEmailDuplicate,
    validateEmail,
    emailList,
  };
};

export default useEmailList;
