import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useCreateTag = () => {
  const { createTag } = getTagsApi();

  const mutation = useMutation({
    mutationFn: createTag,
    mutationKey: ['tags-create'],
  });

  return mutation;
};

export default useCreateTag;
