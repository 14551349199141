import { Button } from '@mui/material';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line max-len
import { OrganizationUserBulkInviteFormInputs } from '@pages/organizations/components/organization-user-bulk-invite-form';

import useEmailList from '../hooks/useEmailList';

const BulkInviteButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { control } = useFormContext<OrganizationUserBulkInviteFormInputs>();

  const emails = useWatch({ control, name: 'emails' });
  const { isEmailDuplicate, emailList } = useEmailList(emails);
  const formState = useFormState({ control });

  return (
    <div className="mt-lg gap-x-sm flex items-end">
      <Button size="medium" variant="outlined" onClick={() => navigate(-1)}>
        {t('organization.bulkInvite.back')}
      </Button>
      <Button
        size="medium"
        variant="contained"
        type="submit"
        disabled={!formState.isValid || isEmailDuplicate}
      >
        {t('organization.bulkInvite.invite', {
          count: emailList.length,
        })}
      </Button>
    </div>
  );
};

export default BulkInviteButtons;
