import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

interface UseClosedVersionsOptions {
  fileId: string;
  page: number;
}

const useClosedVersions = ({ fileId, page }: UseClosedVersionsOptions) => {
  const { getClosedVersions } = getFilesApi();
  const query = useQuery({
    queryFn: () => getClosedVersions(fileId, page),
    queryKey: ['files-versions-closedVersions', fileId, page],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useClosedVersions;
