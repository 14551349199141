import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useRemoveUserFromGroup = () => {
  const { removeUser } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: removeUser,
    mutationKey: ['group-removeUser'],
  });

  return mutation;
};

export default useRemoveUserFromGroup;
