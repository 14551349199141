import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useAddFiles = () => {
  const { addFiles } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: addFiles,
    mutationKey: ['groups-add-files'],
  });

  return mutation;
};

export default useAddFiles;
