import { isRunningOnElectron } from './environment';

export const setItem = async (key: string, value: string) => {
  if (isRunningOnElectron()) {
    await window.storage.setItem(key, value);
  } else {
    localStorage.setItem(key, value);
  }
};

export const getItem = async (key: string) => {
  if (isRunningOnElectron()) {
    return await window.storage.getItem(key);
  }

  return localStorage.getItem(key);
};

export const deleteItem = async (key: string) => {
  if (isRunningOnElectron()) {
    await window.storage.deleteItem(key);
  } else {
    localStorage.removeItem(key);
  }
};
