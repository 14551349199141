import { Chip } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from '@app/services/files/types';
import { hexAddOpacity } from '@app/utils/colors';
import CloseIcon from '@assets/shared/close.svg?react';
import Loading from '@components/loading';

interface TagsProps {
  tags: Tag[];
  onDelete?: (tagId: string) => void;
  isDeleting?: string | null;
  maxDisplay?: number;
}

const Tags = ({ tags, onDelete, isDeleting, maxDisplay }: TagsProps) => {
  const { t } = useTranslation();
  const getDeleteIcon = useCallback(
    (tagId: string, tagTextColor: string) => {
      if (!onDelete) {
        return <></>;
      }

      if (tagId === isDeleting) {
        return <Loading size={12} />;
      }

      return (
        <CloseIcon
          title={`close-${tagId}`}
          height={12}
          style={{ color: hexAddOpacity(tagTextColor, 0.6) }}
        />
      );
    },
    [isDeleting, onDelete]
  );

  const handleDelete = (tagId: string) => {
    if (onDelete) {
      onDelete(tagId);
    }
  };

  if (tags.length === 0) {
    return <p className="text-sm">{t('fileList.fileDetails.tags.noTags')}</p>;
  }

  return (
    <>
      {tags.slice(0, maxDisplay).map((tag) => (
        <Chip
          key={tag.id}
          color="default"
          className="!h-auto !max-w-40 overflow-ellipsis !px-0 !py-1"
          style={{
            backgroundColor: tag.backgroundColor,
            color: tag.textColor,
          }}
          label={tag.name}
          onDelete={() => handleDelete(tag.id)}
          deleteIcon={getDeleteIcon(tag.id, tag.textColor)}
        />
      ))}
      {maxDisplay && tags.length > maxDisplay ? <p>...</p> : null}
    </>
  );
};

export default Tags;
