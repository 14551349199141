import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { RouterProvider } from 'react-router-dom';

import { AlertProvider } from '@app/hooks/useAlert';
import { AppNavigateContextProvider } from '@app/hooks/useAppNavigate';
import { AuthContextProvider } from '@app/hooks/useAuth';
import { BreadcrumbContextProvider } from '@app/hooks/useBreadcrumb';
import { DraggingFileContextProvider } from '@app/hooks/useDraggingFile';
import { SearchContextProvider } from '@app/hooks/useSearch';
import { UserInputChipContextProvider } from '@pages/group-members/hooks/useUserInputContext';
import router from '@pages/routes';

import { lightModeTheme } from './config/theme';
import { AppJoyrideProvider } from './hooks/useAppJoyride';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={lightModeTheme}>
      <AuthContextProvider>
        <BreadcrumbContextProvider>
          <SearchContextProvider>
            <AppNavigateContextProvider>
              <UserInputChipContextProvider>
                <DraggingFileContextProvider>
                  <AlertProvider>
                    <AppJoyrideProvider>
                      <RouterProvider router={router} />
                    </AppJoyrideProvider>
                  </AlertProvider>
                </DraggingFileContextProvider>
              </UserInputChipContextProvider>
            </AppNavigateContextProvider>
          </SearchContextProvider>
        </BreadcrumbContextProvider>
      </AuthContextProvider>
      <SpeedInsights />
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
