import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAdvancedSearch from '@app/hooks/useAdvancedSearch';
import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import useErrorHandler from '@app/hooks/useErrorHandler';
import { useSearch, useSetSearch } from '@app/hooks/useSearch';
import { GroupFolder } from '@app/services/files/types';
import { AdvancedSearchFormInputs, File, Tag } from '@app/services/files/types';
import Breadcrumb from '@components/breadcrumb';
import Loading from '@components/loading';
import { useLayout } from '@pages/layout/layout';

import Droppable from './components/droppable';
import Empty from './components/empty';
import FileDetails from './components/file-details';
import FileList from './components/file-list';
import FilePreviewModal from './components/file-preview-modal';
import Filters from './components/filters';
import SearchResults from './components/search-results';
import Sort from './components/sort';
import TagsEditorModal from './components/tags-editor-modal';
import useEditTags from './hooks/useEditTags';
import useFilter from './hooks/useFilter';
import useRemoveTag from './hooks/useRemoveTag';

const Files = () => {
  const filter = useFilter();
  const search = useSearch();
  const setSearch = useSetSearch();
  const { parseToFilters, parse, toString } = useAdvancedSearch();
  const {
    filesQuery: { isLoading, files, groupFolders },
    onFileMove,
    onNavigate,
    onDrop,
    sort: { direction, onDirectionChange, onSortOptionChange, sort, sortFiles },
  } = useLayout();
  const [fileDetailId, setFileDetailId] = useState<string | null>(null);
  const [previewOpen, setPreviewOpen] = useState<File | null>(null);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);
  const deleteTagMutation = useRemoveTag();
  const {
    mutateAsync: editTags,
    isError: isEditTagError,
    reset: resetEditTags,
  } = useEditTags();
  const { display: displayEditTagsAlert, Alert: AlertEditTags } = useAlert();
  useErrorHandler(isEditTagError, {
    callback: () => {
      displayEditTagsAlert();
      resetEditTags();
    },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setStageConfig, advanceStep, pause, resume } = useAppJoyride();
  /* v8 ignore start */
  setStageConfig(6, [
    {
      onNext: () => {
        setIsTagEditorOpen(true);
        pause();
      },
    },
    {
      onNext: () => {
        setIsTagEditorOpen(false);
      },
    },
    {
      onNext: () => {
        pause();
        navigate('/tags');
      },
    },
  ]);
  /* v8 ignore stop */

  const handleFolderNavigate = ({ path }: File) => {
    if (search !== '') {
      setSearch(toString({ ...parse(search), path }));
    }

    navigate(`/files${path}`);
  };

  const handleDoubleClick = (file: File) => {
    setFileDetailId(null);
    setPreviewOpen(file);
  };

  const handleRemoveFilter = (name: keyof AdvancedSearchFormInputs) => {
    const advancedSearch = parse(search);
    delete advancedSearch[name];
    setSearch(toString(advancedSearch));
  };

  const handleAddTag = () => {
    setTimeout(() => {
      advanceStep(6, 0);
      setIsTagEditorOpen(true);
    }, 200);
  };

  useEffect(() => {
    if (isTagEditorOpen) {
      setTimeout(() => {
        /* v8 ignore next */
        resume();
      }, 400);
    }
  }, [isTagEditorOpen, resume]);

  const handleGroupFolderClick = ({ id }: GroupFolder) => {
    navigate(`/groups/${id}`);
  };

  const handleEditTags = async ({
    tags,
    fileId,
  }: {
    fileId: string;
    tags: Tag[];
  }) => {
    setIsTagEditorOpen(false);
    advanceStep(6, 1);
    await editTags({ tagNames: tags.map((tag) => tag.name), fileId });
  };

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading color="primary" />
      </div>
    );
  }

  if (
    files?.filter(({ type }) => type !== 'folderDescription').length === 0 &&
    groupFolders?.length === 0
  ) {
    return search === '' ? (
      <Empty onDrop={onDrop} />
    ) : (
      <div className="flex flex-1 flex-col">
        <Breadcrumb />
        <div className="flex flex-1 items-center" onDrop={onDrop}>
          <SearchResults results={sortFiles(filter(search, files))} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row overflow-x-hidden" data-tour="files">
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex justify-between">
          <Breadcrumb />
          <Sort
            direction={direction}
            onDirectionChange={onDirectionChange}
            onSelectOption={onSortOptionChange}
            sortBy={sort}
          />
        </div>
        <Droppable
          className="flex flex-1"
          dropClassName="m-md flex flex-1 flex-col text-center"
          onDrop={onDrop}
        >
          <div className="gap-md p-md flex flex-1 flex-col">
            <Filters
              filters={parseToFilters(search)}
              onRemoveFilter={handleRemoveFilter}
            />
            {search === '' ? (
              <FileList
                onFileMove={onFileMove}
                onFolderHover={onNavigate}
                files={sortFiles(files)}
                onFileClick={({ id }) => setFileDetailId(id)}
                onFolderClick={handleFolderNavigate}
                onFileDoubleClick={handleDoubleClick}
                onGroupFolderClick={handleGroupFolderClick}
                groupFolders={groupFolders}
              />
            ) : (
              <SearchResults
                results={sortFiles(filter(search, files))}
                onFileClick={({ id }) => setFileDetailId(id)}
                onFolderClick={handleFolderNavigate}
                onFileDoubleClick={handleDoubleClick}
              />
            )}
          </div>
        </Droppable>
      </div>
      {fileDetailId ? (
        <FileDetails
          key={fileDetailId}
          id={fileDetailId}
          onClose={() => setFileDetailId(null)}
          deleteTagControls={deleteTagMutation}
          onAddTag={handleAddTag}
        />
      ) : null}
      {fileDetailId ? (
        <TagsEditorModal
          isOpen={isTagEditorOpen}
          onClose={() => setIsTagEditorOpen(false)}
          fileId={fileDetailId}
          onSubmit={({ tags }) =>
            handleEditTags({ fileId: fileDetailId, tags })
          }
        />
      ) : null}
      {previewOpen ? (
        <FilePreviewModal
          file={previewOpen}
          isOpen={!!previewOpen}
          onClose={() => setPreviewOpen(null)}
        />
      ) : null}
      <AlertEditTags severity="error">
        {t('fileList.fileDetails.tagsEditorModal.error')}
      </AlertEditTags>
    </div>
  );
};

export default Files;
