import { useEffect } from 'react';

interface UseCloseableOptions {
  shouldClose?: (event: MouseEvent) => boolean;
  onClose: () => void;
}

const useCloseable = ({ onClose }: UseCloseableOptions) => {
  useEffect(() => {
    const handleClose = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleClose);

    return () => {
      document.removeEventListener('keydown', handleClose);
    };
  }, [onClose]);
};

export default useCloseable;
