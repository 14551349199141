import { useQuery } from '@tanstack/react-query';

import { fileResponseToFile, getFilesApi } from '@app/services/files/api';
import {
  AdvancedSearchFormInputs,
  SortOption,
} from '@app/services/files/types';

interface UseFilesOptions {
  filters?: Partial<AdvancedSearchFormInputs>;
  sortBy?: SortOption;
  sortDirection?: 'asc' | 'desc';
  path?: string;
}

const useFiles = ({
  filters,
  sortBy,
  sortDirection,
  path,
}: UseFilesOptions = {}) => {
  const { get } = getFilesApi();
  const query = useQuery({
    queryFn: () => get({ filters, sortBy, sortDirection, path }),
    queryKey: ['files', filters, sortBy, sortDirection, path],
    select: (data) => ({
      files: data.files.data.map((file) => fileResponseToFile(file)),
      groupFolders: path === '' ? data.groupFolders : [],
    }),
  });

  return query;
};

export default useFiles;
