import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { sizes } from '@app/config/theme';
import EmptyImage from '@assets/files/empty.svg?react';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className="m-md flex flex-1 flex-col items-center justify-center">
      <EmptyImage width={sizes.xxl} height={sizes.xxl} />
      <Typography fontSize={20}>{t('groups.groupFiles.empty')}</Typography>
    </div>
  );
};

export default Empty;
