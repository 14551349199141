import { AvatarGroup, TableCell, TableRow } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Group, PermissionLevel, UserRoles } from '@app/services/groups/types';
import ThreeDotsIcon from '@assets/shared/more-vert.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';

import useGroupPermissions from '../hooks/useGroupPermissions';

import GroupsContextMenu from './groups-context-menu';

interface YourGroupsTableRowProps {
  group: Group;
}

interface UserSplit {
  owner: string;
  members: string[];
}

const YourGroupsTableRow = ({ group }: YourGroupsTableRowProps) => {
  const { t } = useTranslation();
  const pressableIconRef = useRef<HTMLDivElement | null>(null);
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const { canManageGroup, canSeeOtherUsers } = useGroupPermissions(
    group.permission
  );

  const getOwnerUser = useCallback((users: UserRoles): UserSplit => {
    let owner: string = '';
    const members: string[] = [];

    for (const userId in users) {
      if (users[userId] === PermissionLevel.OWNER) {
        owner = userId;
      } else {
        members.push(userId);
      }
    }

    return { owner, members };
  }, []);

  return (
    <TableRow key={group.name} className="!table-row last:border-0">
      <TableCell>{group.name}</TableCell>
      <TableCell>
        <UserAvatar
          className="!h-9 !w-9 !text-base"
          id={getOwnerUser(group.users).owner}
        />
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          {canSeeOtherUsers() ? (
            <>
              <AvatarGroup max={4} className="items-center">
                {getOwnerUser(group.users).members.map((member) => (
                  <UserAvatar
                    className="!h-9 !w-9 !text-base"
                    key={member}
                    id={member}
                  />
                ))}
              </AvatarGroup>
            </>
          ) : null}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          <p>{t(`groups.table.status.${group.status}`)}</p>
        </div>
      </TableCell>
      <TableCell>
        {canManageGroup() ? (
          <div ref={pressableIconRef}>
            <PressableIcon
              icon={<ThreeDotsIcon />}
              onClick={() => setIsContextMenuVisible(true)}
            />
            <GroupsContextMenu
              group={group}
              anchorElement={pressableIconRef.current}
              isOpen={isContextMenuVisible}
              onClose={() => setIsContextMenuVisible(false)}
            />
          </div>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default YourGroupsTableRow;
