import { TextFieldProps, TextField } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import EditIcon from '@assets/shared/edit.svg?react';

import Loading from './loading';

type EditableFieldProps = TextFieldProps & {
  dirty?: boolean;
  loading?: boolean;
};

const EditableField = forwardRef<HTMLInputElement, EditableFieldProps>(
  ({ dirty = false, loading = false, ...props }, ref) => {
    const inputClasses = useMemo(
      () =>
        twMerge(
          'hover:!text-black focus:!text-black !text-black/65',
          dirty ? '!text-black' : ''
        ),
      [dirty]
    );

    return (
      <TextField
        ref={ref}
        {...props}
        variant="standard"
        inputProps={{
          className: inputClasses,
        }}
        InputProps={{
          endAdornment: loading ? <Loading size={12} /> : <EditIcon />,
        }}
      />
    );
  }
);

export default EditableField;
