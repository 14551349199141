import { Button, Chip, FormControl, TextField, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tag } from '@app/services/files/types';
import { CreateTagRequest } from '@app/services/tags/types';
import ColorInput from '@components/color-input';

interface CreateTagFormProps {
  onSubmit: (form: CreateTagRequest) => void;
  onCancel: () => void;
  initialTag?: Tag;
}

const CreateTagForm = ({ onCancel, onSubmit }: CreateTagFormProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { handleSubmit, control, formState, watch } = useForm<CreateTagRequest>(
    {
      defaultValues: {
        description: '',
        name: '',
        styling: {
          backgroundColor: palette.primary.main,
          textColor: palette.getContrastText(palette.primary.main),
        },
      },
    }
  );
  const background = watch('styling.backgroundColor');
  const text = watch('styling.textColor');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Chip
        className="!my-sm !w-fit"
        sx={{ backgroundColor: background, color: text }}
        label={t('tags.createTagModal.form.tag')}
      />
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('tags.createTagModal.form.name')}
              variant="standard"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('tags.createTagModal.form.description')}
              variant="standard"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="styling.backgroundColor"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <ColorInput
              {...field}
              label={t('tags.createTagModal.form.backgroundColor')}
              variant="standard"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="styling.textColor"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <ColorInput
              {...field}
              label={t('tags.createTagModal.form.text')}
              variant="standard"
            />
          )}
        />
      </FormControl>
      <div className="gap-x-sm mt-lg flex justify-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('tags.createTagModal.cancel')}
        </Button>
        <Button
          className="!px-md"
          variant="contained"
          type="submit"
          disabled={!formState.isValid}
        >
          {t('tags.createTagModal.create')}
        </Button>
      </div>
    </form>
  );
};

export default CreateTagForm;
