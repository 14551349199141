import { createContext, useContext } from 'react';

import { Tag } from '@app/services/files/types';

export const TagContext = createContext<Tag>({
  id: '',
  name: '',
  backgroundColor: '',
  textColor: '',
  description: null,
});

export const useTagContext = () => useContext(TagContext);
