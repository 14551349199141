import { useEffect } from 'react';

interface UseErrorHandlerOptions {
  // TODO: Actually record error in some tracking platform
  recordError?: boolean;
  error?: Error;
  callback?: (error?: Error) => void;
}

const useErrorHandler = (
  isError: boolean,
  options: UseErrorHandlerOptions = {}
) => {
  useEffect(() => {
    const { callback, error } = options;
    if (isError && callback) {
      callback(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};

export default useErrorHandler;
