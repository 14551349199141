import { Autocomplete, TextField } from '@mui/material';
import { ChangeEvent, KeyboardEventHandler, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useGroupsUserIsAdmin from '@app/hooks/useGroupsUserIsAdmin';
import { Group } from '@app/services/groups/types';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

import UserInputEmailChip from './user-input-email-chip';

interface GroupsUserInputProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  onUserFromOutside: () => void;
  onGroupSelected?: (group: Group) => void;
}

const GroupsUserInput = forwardRef<HTMLInputElement, GroupsUserInputProps>(
  ({ onChange, value, onUserFromOutside, onGroupSelected }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);

    const { data, isLoading } = useGroupsUserIsAdmin();

    const { setIsAvailable } = useUserInputContext();

    const { t } = useTranslation();

    const handleOpen = () => {
      if (value.length === 0) {
        setOpen(true);
      }
    };

    const handleGroupSelection = (group: Group | null) => {
      if (group) {
        onGroupSelected?.(group);
      }
    };

    const handleDelete = (userToDelete: string) => {
      onChange(value.filter((user) => user !== userToDelete));
    };

    const handleKeyDown: KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (event.key === 'Enter' && inputValue.trim()) {
        event.preventDefault();
        if (
          emailRegex.test(inputValue.trim()) &&
          !value.includes(inputValue.trim())
        ) {
          setIsAvailable(false);
          onChange([...value, inputValue.trim()]);
          setInputValue('');
          setError(false);
          setOpen(false);
        } else {
          setError(true);
          setOpen(false);
        }
      } else if (event.key === 'Backspace' && inputValue === '') {
        event.preventDefault();
        setInputValue(value.at(-1) ?? '');
        onChange(value.slice(0, -1));
      }
    };

    return (
      <Autocomplete
        disablePortal
        id="groups-selection"
        options={value.length === 0 ? data ?? [] : []}
        getOptionLabel={(option) => option.name}
        onChange={(_, group) => handleGroupSelection(group)}
        loading={isLoading}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={ref}
            label={t('components.groupsUserInput.label')}
            helperText={t('components.groupsUserInput.message')}
            variant="standard"
            error={error}
            className="!mb-md flex-wrap"
            inputProps={{
              ...params.inputProps,
              onKeyDown: handleKeyDown,
              value: inputValue,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                params.inputProps.onChange?.(e);
                setInputValue(e.currentTarget.value);
              },
            }}
            InputProps={{
              ...params.InputProps,
              className: '!w-none',
              startAdornment: (
                <div
                  className="my-sm flex flex-1 flex-wrap self-start"
                  data-testid="users-input-chips"
                >
                  {value.map((chip) => (
                    <UserInputEmailChip
                      key={chip}
                      label={chip}
                      onDelete={handleDelete}
                      onUserFromOutside={onUserFromOutside}
                    />
                  ))}
                </div>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default GroupsUserInput;
