import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilePermissions } from '@app/services/groups/types';

interface BulkEditPermissionsFormProps {
  onSubmit: (permissions: FilePermissions) => void;
}

const BulkEditPermissionsForm = ({
  onSubmit,
}: BulkEditPermissionsFormProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, watch } = useForm<
    FilePermissions & { noPermissions: boolean }
  >({
    defaultValues: {
      comment: false,
      read: false,
      write: false,
      noPermissions: true,
    },
  });

  const read = watch('read');
  const write = watch('write');
  const comment = watch('comment');

  useEffect(() => {
    if (read || write || comment) {
      setValue('noPermissions', false);
    } else {
      setValue('noPermissions', true);
    }
  }, [read, write, comment, setValue]);

  const handleRemoveClick = (
    e: ChangeEvent<HTMLInputElement>,
    callback: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    if (!read && !write && !comment) {
      return;
    }
    if (e.target.value) {
      setValue('comment', false);
      setValue('read', false);
      setValue('write', false);
      callback(e);
    }
  };

  return (
    <FormGroup className="mt-lg px-sm">
      <ul>
        <li>
          <FormControl>
            <Controller
              name="read"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.read')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="write"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.write')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.comment')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="noPermissions"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => handleRemoveClick(e, field.onChange)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.remove')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>
      </ul>
      <div className="flex flex-1 justify-end">
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          className="!my-sm !px-lg"
        >
          {t('groups.bulkEditing.setPermissions.review')}
        </Button>
      </div>
    </FormGroup>
  );
};

export default BulkEditPermissionsForm;
