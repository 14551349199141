import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Group, UserRoles } from '@app/services/groups/types';
import GroupFilesTable from '@pages/group-files/components/group-files-table';
import GroupsMemberTable from '@pages/group-members/components/group-members-table';

interface GroupDetailsTableProps {
  details: Group;
  members: UserRoles;
}

const GroupDetailsTable = ({ details, members }: GroupDetailsTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formMethods = useForm();

  const filesTableWrapperClasses = useMemo(() => {
    if (details.files.length > 0) {
      return 'cursor-pointer duration-200 hover:bg-slate-50';
    }

    return '';
  }, [details.files.length]);

  const navigateMembers = useCallback(
    () =>
      navigate(`/groups/${details?.id}/members`, {
        state: { group: details },
      }),
    [details, navigate]
  );

  const navigateFiles = useCallback(() => {
    if (details.files.length > 0) {
      navigate(`/groups/${details?.id}/files`);
    }
  }, [details, navigate]);

  return (
    <>
      <Typography className="!mt-lg !text-2xl">
        {t('groups.groupDetails.files', {
          total: details.files.length,
        })}
      </Typography>
      <div onClick={navigateFiles} className={filesTableWrapperClasses}>
        <GroupFilesTable group={details} readOnly />
      </div>
      <Typography className="!mt-lg !text-2xl">
        {t('groups.groupDetails.members', {
          total: Object.keys(members).length,
        })}
      </Typography>
      <div
        onClick={navigateMembers}
        className="cursor-pointer duration-200 hover:bg-slate-50"
      >
        <FormProvider {...formMethods}>
          <GroupsMemberTable
            members={Object.fromEntries(Object.entries(members).slice(0, 5))}
            readOnly
            permission={details.permission}
          />
        </FormProvider>
      </div>
    </>
  );
};

export default GroupDetailsTable;
