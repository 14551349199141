import { AlertColor, AlertProps, Alert as MUIAlert } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';

interface AlertContextProps {
  text: string | null;
  type: AlertColor | null;
  setAlert: (text: string, type: AlertColor, timeout: number | null) => void;
}

export const AlertContext = createContext<AlertContextProps>({
  text: null,
  type: null,
  setAlert: () => {},
});

export const AlertProvider = ({ children }: PropsWithChildren) => {
  const [text, setText] = useState<string | null>(null);
  const [type, setType] = useState<AlertColor | null>(null);

  const setAlert: AlertContextProps['setAlert'] = (
    newText,
    newType,
    timeout
  ) => {
    setText(newText);
    setType(newType);

    setTimeout(() => {
      setText(null);
      setType(null);
    }, timeout ?? 2000);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

interface UseAlertOptions {
  timeout?: number | null;
}

export interface AlertControls {
  Alert: (props: AlertProps) => ReactElement;
  display: (callback?: () => void) => void;
  displayGlobal: (options: { text: string; type: AlertColor }) => void;
  hide: () => void;
}

const useAlert = ({ timeout = 2000 }: UseAlertOptions = {}): AlertControls => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const globalAlertContext = useContext(AlertContext);

  const display = useCallback(
    (callback: () => void = () => {}) => {
      setIsAlertVisible(true);
      if (timeout) {
        setTimeout(() => {
          setIsAlertVisible(false);
          callback();
        }, timeout);
      }
    },
    [timeout]
  );

  const displayGlobal = useCallback(
    ({ text, type }: { text: string; type: AlertColor }) => {
      globalAlertContext.setAlert(text, type, timeout);
    },
    [globalAlertContext, timeout]
  );

  const hide = () => {
    setIsAlertVisible(false);
  };

  const Alert = useCallback(
    (props: AlertProps) => (
      <AnimatePresence>
        {isAlertVisible ? (
          <motion.div
            key="alert"
            initial={{ top: -20, opacity: 0 }}
            animate={{ top: 120, opacity: 1 }}
            exit={{ opacity: 0, top: -20 }}
            className="fixed left-1/2 -translate-x-1/2"
          >
            <MUIAlert {...props} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    ),
    [isAlertVisible]
  );

  return {
    Alert,
    display,
    displayGlobal,
    hide,
  };
};

export default useAlert;
