import { createContext, useContext } from 'react';

import { Organization } from '@app/services/organizations/types';

export const OrganizationContext = createContext<Organization>({
  id: '',
  name: '',
});

export const useOrganizationContext = () => useContext(OrganizationContext);
