import { DocRenderer } from '@cyntler/react-doc-viewer';
import { createContext, useContext, useState } from 'react';
import { Document, Thumbnail } from 'react-pdf';

interface GetDocumentRendererOptions {
  allPages?: boolean;
}

export const DocumentRendererContext = createContext({ scale: 1 });

const getDocumentRenderer = (
  className = '',
  { allPages = false }: GetDocumentRendererOptions = {}
) => {
  const DocumentRenderer: DocRenderer = ({
    mainState: { currentDocument },
  }) => {
    const [numPages, setNumPages] = useState(0);
    const { scale } = useContext(DocumentRendererContext);

    if (!currentDocument) {
      return null;
    }

    const onDocumentLoadSuccess = (document: { numPages: number }): void => {
      setNumPages(document.numPages);
    };

    return (
      <div className={className}>
        <Document
          file={currentDocument.uri}
          onLoadSuccess={onDocumentLoadSuccess}
          className="gap-y-md flex flex-col bg-transparent"
        >
          {!allPages ? (
            <Thumbnail pageNumber={1} />
          ) : (
            Array(numPages)
              .fill(0)
              .map((_, index) => (
                <Thumbnail pageIndex={index} key={index} scale={scale} />
              ))
          )}
        </Document>
      </div>
    );
  };

  DocumentRenderer.fileTypes = ['pdf', 'application/pdf'];
  DocumentRenderer.weight = 1;

  return DocumentRenderer;
};

export default getDocumentRenderer;
