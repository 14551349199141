import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { useBreadcrumb } from '@app/hooks/useBreadcrumb';
import { useSetSearch } from '@app/hooks/useSearch';
import RightChevron from '@assets/files/right_chevron.svg?react';
import ThreeDotsIcon from '@assets/shared/three-dots.svg?react';

import FolderList from './folder-list';
import HoverTimeout from './hover-timeout';
import PressableIcon from './pressable-icon';

const Breadcrumb = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { folders } = useBreadcrumb();
  const setSearch = useSetSearch();
  const [isExpanded, setIsExpanded] = useState(false);
  const { setStageConfig, setStep } = useAppJoyride();
  /* v8 ignore start */
  setStageConfig(2, [
    {
      onNext: () => navigate('/files'),
    },
  ]);
  /* v8 ignore stop */

  const currentFolder = folders.at(-1);
  const hasMidRoutes = folders.length - 1 > 1;

  const handleHomeClick = () => {
    setSearch('');
    setStep(1);
  };

  return (
    <nav className="pl-md pt-md flex items-center">
      <Link to="/files" onClick={handleHomeClick}>
        <Typography
          className={twMerge(
            '!text-4xl !font-medium',
            currentFolder !== '' ? '!text-gray-950/60' : ''
          )}
          data-tour="creating-folder-step-1"
          variant={currentFolder === '' ? 'h1' : 'h2'}
        >
          {t('title')}
        </Typography>
      </Link>
      {hasMidRoutes ? (
        <div className="relative flex items-center">
          <RightChevron className="ml-md" />
          <HoverTimeout onHover={() => setIsExpanded(true)}>
            <PressableIcon
              icon={
                <ThreeDotsIcon
                  className="self-end !text-gray-950/60"
                  title="..."
                />
              }
              className="!ml-md !pt-md relative z-[90] flex cursor-pointer items-center"
              onClick={() => setIsExpanded(true)}
            />
          </HoverTimeout>
          {isExpanded ? (
            <FolderList
              onClose={() => setIsExpanded(false)}
              folders={folders}
            />
          ) : null}
        </div>
      ) : null}
      {currentFolder ? (
        <>
          <RightChevron className="ml-md" />
          <Typography className="!ml-md !text-4xl !font-medium" variant="h1">
            {currentFolder}
          </Typography>
        </>
      ) : null}
    </nav>
  );
};

export default Breadcrumb;
