import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { FormEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAppJoyride from '@app/hooks/useAppJoyride';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

interface NewFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: { folderName: string }) => void;
}

const NewFolderModal = ({ isOpen, onClose, onSubmit }: NewFolderModalProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState, reset } = useForm<{
    folderName: string;
  }>();
  const { updateStageConfig, advanceStep, retreatStep } = useAppJoyride();
  /* v8 ignore next 4 */
  updateStageConfig(2, 2, {
    onNext: () => {
      onSubmit({ folderName: 'Walkthrough folder' });
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleClose = () => {
    onClose();
    retreatStep();
  };

  const handleSubmitClick: FormEventHandler<HTMLFormElement> = (e) => {
    void handleSubmit(onSubmit)(e);
    advanceStep();
  };

  return (
    <Modal
      className="flex"
      open={isOpen}
      onClose={handleClose}
      sx={{
        zIndex: 199,
      }}
      disableEnforceFocus
    >
      <Box
        className="w-small-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
        data-tour="creating-folder-step-3"
      >
        <PressableIcon
          onClick={handleClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-lg">
          {t('fileList.fileListContextMenu.newFolderModal.title')}
        </Typography>
        <form onSubmit={handleSubmitClick}>
          <FormControl fullWidth className="!mb-md">
            <Controller
              name="folderName"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  className="!mt-md"
                  {...field}
                  placeholder={t(
                    'fileList.fileListContextMenu.newFolderModal.placeholder'
                  )}
                  variant="standard"
                />
              )}
              defaultValue=""
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className="float-right"
            disabled={!formState.isDirty}
          >
            {t('fileList.fileListContextMenu.newFolderModal.submit')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default NewFolderModal;
