import { useQuery } from '@tanstack/react-query';

import { fileResponseToFile, getFilesApi } from '@app/services/files/api';
import { DeletedFile } from '@app/services/files/types';

const useDeletedFiles = () => {
  const { getDeletedFiles } = getFilesApi();

  const query = useQuery({
    queryFn: () => getDeletedFiles(),
    queryKey: ['files-deleted'],
    select: ({ data }) =>
      data.map((file) => fileResponseToFile(file) as DeletedFile),
  });

  return query;
};

export default useDeletedFiles;
