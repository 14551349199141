import { useEffect, useState } from 'react';

interface UseDebounceOptions<T> {
  value: T;
  delay: number;
}

const useDebounce = <T>({ value, delay }: UseDebounceOptions<T>): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const updateDebouncedValue = () => {
      setDebouncedValue(value);
      timeoutId = null;
    };

    if (!timeoutId) {
      timeoutId = setTimeout(updateDebouncedValue, delay);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
