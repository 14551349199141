import { PropsWithChildren, useEffect, useRef, useState } from 'react';

interface HoverTimeoutProps {
  onHover: () => void;
}

const HoverTimeout = ({
  onHover,
  children,
}: PropsWithChildren<HoverTimeoutProps>) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (hovering && !timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        onHover();
        timeoutId.current = null;
      }, 1000);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [hovering, onHover]);

  return (
    <span
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {children}
    </span>
  );
};

export default HoverTimeout;
