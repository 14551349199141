import { useMutation } from '@tanstack/react-query';

import { getTagsApi } from '@app/services/tags/api';

const useChangeTagColors = () => {
  const { changeColors } = getTagsApi();

  const mutation = useMutation({
    mutationFn: changeColors,
    mutationKey: ['tags-change-colors'],
  });

  return mutation;
};

export default useChangeTagColors;
