import {
  Navigate,
  RouteObject,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';

import { isRunningOnElectron } from '@app/utils/environment';
import GlobalAlert from '@components/global-alert';

import NotFound from './404';
import FileVersions from './file-versions/file-versions';
import Files from './files/files';
import GroupAddFiles from './group-add-files/group-add-files';
import GroupBulkEditing from './group-bulk-editing/group-bulk-editing';
import GroupDetails from './group-details/group-details';
import GroupFiles from './group-files/group-files';
import GroupMembers from './group-members/group-members';
import Groups from './groups/groups';
import Layout from './layout/layout';
import Organizations from './organizations/organizations';
import OrganizationsBulkInvite from './organizations-bulk-inviting/organizations-bulk-inviting';
import Profile from './profile/profile';
import RangedCallback from './ranged-callback';
import TagDetails from './tag-details/tag-details';
import Tags from './tags/tags';
import Trash from './trash/trash';

const createAppRouter = (appRoutes: RouteObject[]) => {
  if (isRunningOnElectron()) {
    return createHashRouter(appRoutes);
  }

  return createBrowserRouter(appRoutes);
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: [<Layout key="layout" />, <GlobalAlert key="alert" />],
    children: [
      {
        path: 'organizations',
        element: <Organizations />,
      },
      {
        path: 'organizations/bulk-invite',
        element: <OrganizationsBulkInvite />,
      },
      {
        path: 'groups',
        element: <Groups />,
      },
      {
        path: 'groups/:groupId',
        element: <GroupDetails />,
      },
      {
        path: 'groups/:groupId/files',
        element: <GroupFiles />,
      },
      {
        path: 'groups/:groupId/files/bulk-edit',
        element: <GroupBulkEditing />,
      },
      {
        path: 'groups/:groupId/files/add',
        element: <GroupAddFiles />,
      },
      {
        path: 'groups/:groupId/members',
        element: <GroupMembers />,
      },
      {
        path: 'files',
        element: <Files />,
      },
      {
        path: 'files/*',
        element: <Files />,
      },
      {
        path: 'tags',
        element: <Tags />,
      },
      {
        path: 'tags/:tagId',
        element: <TagDetails />,
      },
      {
        path: 'file/:fileId/versions',
        element: <FileVersions />,
      },
      {
        path: 'trash',
        element: <Trash />,
      },
    ],
    errorElement: <NotFound />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/ranged-callback',
    element: <RangedCallback />,
  },
  {
    path: '/callback',
    element: <Navigate to="/files" replace />,
  },
];

const router = createAppRouter(routes);

export default router;
