import { ButtonBase, ClickAwayListener, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useCloseable from '@app/hooks/useCloseable';
import FolderIcon from '@assets/file-icons/folder.svg?react';

interface FolderListProps {
  folders: string[];
  onClose: () => void;
}

interface Folder {
  name: string;
  path: string;
}

const FolderList = ({ folders, onClose }: FolderListProps) => {
  useCloseable({
    onClose,
  });

  const previousFolders = useMemo(() => {
    const result: Folder[] = [];
    let previous = '';
    const availableFolders = folders.slice(0, -1);

    for (const folder of availableFolders) {
      if (!folder) {
        continue;
      }
      const path = `${previous}${folder}`;
      result.push({ name: folder, path });
      previous = `${path}/`;
    }

    return result;
  }, [folders]);

  const handleClickAway = (e: TouchEvent | MouseEvent) => {
    if (!(e.target as Element).closest('.folderCard, .fileCard')) {
      onClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ol
        id="folder-list"
        className="absolute left-10 top-10 z-[60] max-w-48 overflow-hidden bg-white shadow-md"
      >
        {previousFolders.map(({ name, path }) => (
          <ButtonBase
            key={path}
            className="folderListItem !block w-full"
            title={path}
          >
            <Link
              to={path}
              onClick={onClose}
              className="gap-sm !p-sm flex w-full items-center !justify-start hover:!bg-slate-100"
            >
              <FolderIcon width={12} className="min-w-3" />
              <Typography className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                {name}
              </Typography>
            </Link>
          </ButtonBase>
        ))}
      </ol>
    </ClickAwayListener>
  );
};

export default FolderList;
