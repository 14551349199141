import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useCreateFolder = () => {
  const { createFolder } = getFilesApi();
  const query = useMutation({
    mutationFn: createFolder,
    mutationKey: ['createFolder'],
  });

  return query;
};

export default useCreateFolder;
