import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoPreview = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography>{t('layout.uploadPreview.noPreviewAvailable')}</Typography>
    </div>
  );
};

export default NoPreview;
