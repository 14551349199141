import { useInfiniteQuery } from '@tanstack/react-query';

import { getOrganizationApi } from '@app/services/organizations/api';
import { User } from '@app/services/organizations/types';

const useOrganizationInfiniteUsers = (organizationId: string) => {
  const { getUsers } = getOrganizationApi();

  const query = useInfiniteQuery({
    queryFn: ({ pageParam }) => getUsers(organizationId, pageParam),
    queryKey: ['organization-infinite-users', organizationId],
    getNextPageParam: (lastPage) =>
      lastPage.pagination.links.next !== null
        ? lastPage.pagination.page + 1
        : null,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    select: (data) => {
      const users: User[] = [];
      data.pages.forEach((page) => {
        users.push(...page.data);
      });

      return {
        ...data,
        users,
      };
    },
  });

  return query;
};

export default useOrganizationInfiniteUsers;
