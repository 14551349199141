import { Box, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import { CreateVersionRequest } from '@app/services/files/types';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useCreateVersion from '../hooks/useCreateVersion';

import NewVersionForm from './new-version-form';

interface NewVersionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewVersionModal = ({ isOpen, onClose }: NewVersionModalProps) => {
  const { t } = useTranslation();
  const {
    mutateAsync: createVersion,
    isPending,
    isError,
    reset,
  } = useCreateVersion();
  const { displayGlobal } = useAlert();

  const handleCreateVersion = async (form: CreateVersionRequest) => {
    await createVersion(form);
    onClose();
    displayGlobal({
      text: t('fileVersions.newVersionModal.success'),
      type: 'success',
    });
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium">
            <Trans i18nKey="fileVersions.newVersionModal.error">
              Oops! There was an error creating a new version.
              <span className="cursor-pointer underline" onClick={reset}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <div className="mt-md">
        <NewVersionForm onCancel={onClose} onSubmit={handleCreateVersion} />
      </div>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('fileVersions.newVersionModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default NewVersionModal;
