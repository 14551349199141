import { useTranslation } from 'react-i18next';

import useContextMenu from '@app/hooks/useContextMenu';

interface TagListItemContextMenuProps {
  anchorElement: HTMLElement | null;
  onOpenMergeTag?: () => void;
}

const TagListItemContextMenu = ({
  anchorElement,
  onOpenMergeTag,
}: TagListItemContextMenuProps) => {
  const { t } = useTranslation();

  const { ContextMenu } = useContextMenu(anchorElement, {
    items: [
      ...(onOpenMergeTag !== undefined
        ? [
            {
              icon: <></>,
              onClick: onOpenMergeTag,
              text: t('tags.contextMenu.mergeWith'),
            },
          ]
        : []),
    ],
  });

  return <ContextMenu />;
};
export default TagListItemContextMenu;
