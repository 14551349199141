import axios, { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';

import { getEnv } from '@app/config/env';
import { logout } from '@app/services/auth/login';
import { getRangedAuthorizationUrl } from '@filot/auth';
import { Range, RangedAuth } from '@filot/types/api';

import { isRunningOnElectron } from './environment';

const instances: Partial<Record<Range, AxiosInstance>> = {};

export interface NoPromptAuthResponse {
  error?: string;
  accessToken?: string;
}

const getTokenFromIframe = async (range: Range): Promise<string> =>
  new Promise<string>((res, rej) => {
    const iframe = document.createElement('iframe');

    iframe.src = getRangedAuthorizationUrl({
      callbackUrl: `${window.location.origin}/ranged-callback`,
      env: getEnv(),
      range,
    });
    iframe.style.display = 'none';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    const deleteIframe = () => {
      if (document.body.contains(iframe)) {
        document.body.removeChild(iframe);
      }
    };

    window.addEventListener(
      'message',
      (event: MessageEvent<NoPromptAuthResponse>) => {
        if (event.origin === window.location.origin) {
          if (event.data.accessToken) {
            res(event.data.accessToken);
            deleteIframe();
          } else if (event.data.error) {
            rej(event.data.error);
            deleteIframe();
          }
        }
      },
      false
    );
    document.body.appendChild(iframe);
  });

const rangedAuths: Partial<Record<Range, RangedAuth>> = {};

export const getRangedInstance = (range: Range) => {
  const rangedInstance = instances[range];

  if (rangedInstance) {
    return rangedInstance;
  }

  const newInstance = axios.create();
  newInstance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err instanceof AxiosError) {
        if (err.status === HttpStatusCode.Forbidden && err.config) {
          return newInstance(err.config);
        }

        return Promise.reject(err);
      }
    }
  );

  return newInstance;
};

export const getRangedAccessToken = async (range: Range): Promise<string> => {
  if (isRunningOnElectron()) {
    return window.auth.getRangedAccessToken(range);
  }

  let accessToken = '';
  const rangedAuth = rangedAuths[range];

  if (rangedAuth) {
    return rangedAuth.accessToken;
  }

  try {
    accessToken = await getTokenFromIframe(range);

    rangedAuths[range] = {
      accessToken,
    };
  } catch (e) {
    logout();
  }

  return accessToken;
};
