import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useGroupsUserIsAdmin = () => {
  const { getGroupsAdmin } = getGroupsApi();

  const query = useQuery({
    queryFn: getGroupsAdmin,
    queryKey: ['groups-user-is-admin'],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useGroupsUserIsAdmin;
