import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { RoutesNames } from '@filot/types/navigator';

export const AppNavigateContext = createContext<
  [RoutesNames, (newRoute: RoutesNames) => void]
>(['home', () => {}]);

export const AppNavigateContextProvider = ({ children }: PropsWithChildren) => {
  const [currentRoute, setCurrentRoute] = useState<RoutesNames>('home');

  return (
    <AppNavigateContext.Provider value={[currentRoute, setCurrentRoute]}>
      {children}
    </AppNavigateContext.Provider>
  );
};

const useAppNavigate = () => {
  const [currentRoute, setCurrentRoute] = useContext(AppNavigateContext);
  const navigate = useNavigate();
  const location = useLocation();

  const routesNamesArray: RoutesNames[] = useMemo(
    () => [
      'home',
      'tags',
      'groups',
      'recent',
      'trash',
      'organizations',
      'profile',
    ],
    []
  );

  // Type guard function to check if value is a RoutesNames
  const isRoutesName = useCallback(
    (value: string): value is RoutesNames =>
      routesNamesArray.includes(value as RoutesNames),
    [routesNamesArray]
  );

  useEffect(() => {
    if (location.pathname.startsWith('/files')) {
      setCurrentRoute('home');
    } else {
      const match = location.pathname.match(/\/([^/]+)/);
      const route = match ? match[1] : null;
      if (route && isRoutesName(route)) {
        setCurrentRoute(route);
      }
    }
  }, [isRoutesName, location.pathname, setCurrentRoute]);

  const isSelected = useCallback(
    (route: RoutesNames) => route === currentRoute,
    [currentRoute]
  );

  /**
   * @deprecated use default react-router-dom's `navigate` instead
   */
  const appNavigate = useCallback(
    (route: RoutesNames, options?: NavigateOptions) => {
      setCurrentRoute(route);
      if (route === 'home') {
        navigate('/files', options);
      } else {
        navigate(`/${route}`, options);
      }
    },
    [navigate, setCurrentRoute]
  );

  return {
    isSelected,
    /**
     * @deprecated use default react-router-dom's `navigate` instead
     */
    navigate: appNavigate,
    setCurrentRoute,
  };
};

export default useAppNavigate;
