import { getEnv } from '@app/config/env';

import getFilesMock from './mock';
import { getFilesService } from './service';
import { File, FileResponse, FilesApi } from './types';

const getFilesApi = (): FilesApi => {
  if (getEnv().mockApiCalls) {
    return getFilesMock();
  }

  return getFilesService();
};

const getType = (extension: string) => {
  if (extension === 'folder') {
    return 'folderDescription';
  }

  return extension === '' ? 'folder' : extension;
};

const fileResponseToFile = (fileResponse: FileResponse): File => ({
  id: fileResponse.id,
  path: fileResponse.path,
  fileName: `${fileResponse.name}${fileResponse.extension ? `.${fileResponse.extension}` : ''}`,
  tags: [],
  publicKeys: fileResponse.users_with_access,
  organizationName: fileResponse.organization_id,
  type: getType(fileResponse.extension),
  owner: fileResponse.owner,
  lastModified: new Date(fileResponse.modifications[0]).getTime(),
  creation: new Date(fileResponse.creation.created_at).getTime(),
  content: '',
  keys: {},
  people: fileResponse.users_with_access,
  deletion: fileResponse.deletion
    ? {
        deletedAt: fileResponse.deletion.deleted_at,
        deletedBy: fileResponse.deletion.deleted_by,
      }
    : null,
  locked: fileResponse.locked,
  size: fileResponse.size,
});

const fileToFileResponse = (file: File): FileResponse => {
  const fileNameParts = file.fileName.split('.');
  const extension = fileNameParts.length > 1 ? fileNameParts.pop() : '';
  const name = fileNameParts.join('.');

  return {
    name,
    path: file.path,
    organization_id: file.organizationName,
    owner: file.owner,
    users_with_access: file.people,
    groups_with_access: [],
    extension: extension || '',
    type: 'document',
    content_id: file.id,
    id: file.id,
    creation: {
      created_by: 'system',
      created_at: new Date(file.creation).toISOString(),
    },
    modifications: [new Date(file.lastModified).toISOString()],
    deletion: file.deletion
      ? {
          deleted_at: file.deletion.deletedAt,
          deleted_by: file.deletion.deletedBy,
        }
      : null,
    locked: file.locked,
    size: file.size,
  };
};

export { getFilesApi, fileResponseToFile, fileToFileResponse };
