import { Autocomplete, TextField } from '@mui/material';
import { ChangeEvent, KeyboardEventHandler, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useGroupsUserIsAdmin from '@app/hooks/useGroupsUserIsAdmin';
import { Group } from '@app/services/groups/types';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

interface ShareFileUserInputProps {
  value: string;
  onChange: (newValue: string) => void;
  onGroupSelected: (group: Group) => void;
  onEmailType: (email: string) => void;
}

const ShareFileUserInput = forwardRef<
  HTMLInputElement,
  ShareFileUserInputProps
>(({ onChange, value, onEmailType, onGroupSelected }, ref) => {
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGroupsUserIsAdmin();

  const { setIsAvailable } = useUserInputContext();

  const { t } = useTranslation();

  const handleOpen = () => {
    if (value.length === 0) {
      setOpen(true);
    }
  };

  const handleGroupSelection = (group: Group | null) => {
    if (group) {
      onGroupSelected?.(group);
    }
  };

  const handleKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const email = value.trim();

    if (event.key === 'Enter') {
      event.preventDefault();
      if (emailRegex.test(email)) {
        setIsAvailable(false);
        onEmailType(email);
        onChange('');
        setError(false);
        setOpen(false);
      } else {
        setError(true);
        setOpen(false);
      }
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="groups-selection"
      options={value.length === 0 ? (data ?? []) : []}
      getOptionLabel={(option) => option.name}
      onChange={(_, group) => handleGroupSelection(group)}
      loading={isLoading}
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={ref}
          label={t('fileList.fileDetails.manageAccessModal.form.label')}
          helperText={t('fileList.fileDetails.manageAccessModal.form.message')}
          variant="outlined"
          error={error}
          inputProps={{
            ...params.inputProps,
            onKeyDown: handleKeyDown,
            value,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              params.inputProps.onChange?.(e);
              onChange(e.target.value);
            },
          }}
        />
      )}
    />
  );
});

export default ShareFileUserInput;
