import { PropsWithChildren } from 'react';

import useFromApp from '@app/hooks/useFromApp';

interface PlatformOnlyProps {
  platform: 'app' | 'web';
  visible?: boolean;
}

const PlatformOnly = ({
  children,
  platform,
  visible = true,
}: PropsWithChildren<PlatformOnlyProps>) => {
  const { isApp } = useFromApp();

  if (!visible) {
    return null;
  }

  if (isApp && platform === 'app') {
    return children;
  } else if (!isApp && platform === 'web') {
    return children;
  }

  return null;
};

export default PlatformOnly;
