import { TableCell, TableRow } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useUser from '@app/hooks/useUser';
import { PermissionLevel } from '@app/services/groups/types';
import TrashIcon from '@assets/shared/trash.svg?react';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import useRemoveUserFromGroup from '../hooks/useRemoveUserFromGroup';

import ConfirmRemoveUserModal from './confirm-remove-user-modal';
import GroupMembersPermissionCell from './group-members-permission-cell';

interface GroupMembersTableRowProps {
  memberId: string;
  permission: PermissionLevel;
  memberPermission: PermissionLevel;
  readOnly?: boolean;
}

const GroupMembersTableRow = ({
  memberId,
  permission,
  memberPermission,
  readOnly,
}: GroupMembersTableRowProps) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { data: member } = useUser(memberId);

  const { canRemoveUsers } = useGroupPermissions(permission);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const {
    mutateAsync: removeUser,
    isError,
    isPending,
    reset,
  } = useRemoveUserFromGroup();
  const { display, Alert } = useAlert();
  const queryClient = useQueryClient();
  useErrorHandler(isError, {
    callback: () => {
      display();
      reset();
    },
  });

  const handleRemove = async () => {
    setIsConfirmModalOpen(false);
    await removeUser({ id: memberId, groupId: groupId! });
    await queryClient.invalidateQueries({ queryKey: ['groups-member'] });
  };

  const RemoveIcon = () => {
    if (isPending) {
      return <Loading size={20} />;
    }
    if (canRemoveUsers(memberPermission)) {
      return (
        <PressableIcon
          icon={<TrashIcon title="trash" />}
          onClick={() => setIsConfirmModalOpen(true)}
          altText={t('groups.groupMembers.table.remove')}
        />
      );
    }

    return null;
  };

  return (
    <TableRow key={memberId}>
      <TableCell className="!py-2">
        {member ? (
          <UserAvatar className="!h-9 !w-9 !text-base" user={member} />
        ) : null}
      </TableCell>
      <TableCell className="!py-2">
        <p className="text-base">{member?.name ?? memberId}</p>
      </TableCell>
      <TableCell className="!py-2">
        <p className="text-base">{member?.email}</p>
      </TableCell>
      {!readOnly ? (
        <>
          <GroupMembersPermissionCell
            permission={permission}
            memberId={memberId}
            memberPermission={memberPermission}
          />
          <TableCell className="!py-2 !text-center">
            <RemoveIcon />
            <Alert severity="error">
              {t('groups.groupMembers.removeUserModal.error')}
            </Alert>
          </TableCell>
        </>
      ) : null}
      <ConfirmRemoveUserModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        name={member?.name ?? memberId}
        onSubmit={handleRemove}
      />
    </TableRow>
  );
};

export default GroupMembersTableRow;
