import { MenuProps, TableCell, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';

import { WorkingClosedVersion } from '@app/services/files/types';
import MoreIcon from '@assets/shared/more-vert.svg?react';
import PressableIcon from '@components/pressable-icon';

interface WorkingClosedVersionsTableRowProps {
  version: WorkingClosedVersion;
  ContextMenu: (props: MenuProps) => JSX.Element;
}

const WorkingClosedVersionsTableRow = ({
  version,
  ContextMenu,
}: WorkingClosedVersionsTableRowProps) => {
  const contextAnchor = useRef<HTMLButtonElement | null>(null);
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

  return (
    <TableRow>
      <TableCell className="!py-sm">
        <p>{version.name}</p>
      </TableCell>
      <TableCell className="!py-sm !text-center">
        <p>{DateTime.fromISO(version.lastModified).toRelativeCalendar()}</p>
      </TableCell>
      <TableCell className="!py-sm !text-end">
        <PressableIcon
          ref={contextAnchor}
          icon={<MoreIcon title="more" />}
          onClick={() => setIsContextMenuVisible(true)}
        />
        <ContextMenu
          anchorEl={contextAnchor.current}
          open={isContextMenuVisible}
          onClose={() => setIsContextMenuVisible(false)}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkingClosedVersionsTableRow;
