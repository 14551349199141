import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useBulkEdit = () => {
  const { bulkEdit } = getGroupsApi();

  const mutation = useMutation({
    mutationFn: bulkEdit,
    mutationKey: ['bulkEdit'],
  });

  return mutation;
};

export default useBulkEdit;
