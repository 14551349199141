import { Button, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareFile, ShareFileRequest } from '@app/services/files/types';
import UsersAvatar from '@components/users-avatar';

import useShareFiles from '../hooks/useShareFile';

import { FileDetailsContext } from './file-details';
import ManageAccessModal from './manage-access-modal';
import ShareFileConfirmationModal from './share-file-confirmation-modal';
import ShareFileModal from './share-file-modal';

interface FileDetailsPeopleProps {
  usersId: string[];
  ownerId: string;
}

const FileDetailsPeople = ({ usersId, ownerId }: FileDetailsPeopleProps) => {
  const { t } = useTranslation();
  const file = useContext(FileDetailsContext);
  const { mutateAsync: shareFiles, reset } = useShareFiles();
  const [isUserInviteOpen, setIsUserInviteOpen] = useState(false);
  const [isManageAccessModalVisible, setIsManageAccessModalVisible] =
    useState(false);
  const [isShareConfirmationOpen, setIsShareConfirmationOpen] = useState(false);
  const [shareParams, setShareParams] = useState<ShareFileRequest | null>(null);

  const handleContinue = (share: ShareFile) => {
    const startDate = share.startDate.toISODate();
    if (startDate && file) {
      setShareParams({
        ...share,
        startDate,
        expirationDate: share.expirationDate?.toISODate() ?? null,
        fileId: file.id,
      });
    }
    setIsShareConfirmationOpen(true);
    setIsUserInviteOpen(false);
  };

  const handleShareFile = () => {
    if (shareParams) {
      void shareFiles(shareParams);
      setTimeout(() => {
        setIsManageAccessModalVisible(true);
      }, 200);
      setTimeout(() => {
        setIsShareConfirmationOpen(false);
      }, 200);
    }
  };

  const handleOpenShareFileModal = () => {
    reset();
    setTimeout(() => {
      setIsUserInviteOpen(true);
    }, 200);
    setTimeout(() => {
      setIsManageAccessModalVisible(false);
      setIsShareConfirmationOpen(false);
    }, 200);
  };

  return (
    <>
      <div>
        <Typography className="!mb-sm">
          {t('fileList.fileDetails.users.peopleWithAccess')}
        </Typography>
        <div className="flex">
          <UsersAvatar
            usersId={usersId}
            ownerId={ownerId}
            onAdd={() => setIsUserInviteOpen(true)}
          />
        </div>
        <Button
          variant="outlined"
          color="primary"
          className="!mt-sm !px-md"
          onClick={() => setIsManageAccessModalVisible(true)}
        >
          {t('fileList.fileDetails.users.manageAccess')}
        </Button>
      </div>

      <ShareFileModal
        isOpen={isUserInviteOpen}
        onClose={() => setIsUserInviteOpen(false)}
        onContinue={handleContinue}
        initialUsers={shareParams?.users}
      />
      {shareParams ? (
        <ShareFileConfirmationModal
          isOpen={isShareConfirmationOpen}
          onClose={() => setIsShareConfirmationOpen(false)}
          onSubmit={handleShareFile}
          shareParams={shareParams}
          onGoBack={handleOpenShareFileModal}
        />
      ) : null}
      <ManageAccessModal
        isOpen={isManageAccessModalVisible}
        onClose={() => setIsManageAccessModalVisible(false)}
        file={file}
      />
    </>
  );
};

export default FileDetailsPeople;
