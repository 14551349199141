import { getEnv } from '@app/config/env';

import getGroupsMock from './mock';
import getGroupsService from './service';
import { GroupsApi } from './types';

const getGroupsApi = (): GroupsApi => {
  if (getEnv().mockApiCalls) {
    return getGroupsMock();
  }

  return getGroupsService();
};

export { getGroupsApi };
