import { DocRenderer } from '@cyntler/react-doc-viewer';
import { toSvg } from 'html-to-image';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

const getHtmlRenderer = (className = '') => {
  const HtmlRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
    const html = useRef<HTMLDivElement | null>(null);
    const iframe = useRef<HTMLIFrameElement | null>(null);

    const handleLoad = () => {
      if (!html.current) {
        return;
      }
      void toSvg(html.current).then((dataUrl) => {
        if (!html.current || !iframe.current) {
          return;
        }
        html.current.removeChild(iframe.current);
        const img = new Image();
        img.src = dataUrl;
        img.classList.add('overflow-auto');
        html.current.appendChild(img);
      });
    };

    if (!currentDocument) {
      return null;
    }

    const divClass = twMerge('h-full w-full', className);

    return (
      <div className={divClass} ref={html} id="container" onLoad={handleLoad}>
        <iframe
          className="h-full w-full"
          src={currentDocument.uri}
          ref={iframe}
        />
      </div>
    );
  };

  HtmlRenderer.fileTypes = ['html', 'text/html'];
  HtmlRenderer.weight = 1;

  return HtmlRenderer;
};

export default getHtmlRenderer;
