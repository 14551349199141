import { Card, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import useClickAndDrag from '@app/hooks/useClickAndDrag';
import { File } from '@app/services/files/types';
import FolderIcon from '@assets/file-icons/folder.svg?react';
import MoveableToFolder from '@components/moveable-to-folder';

import { MoveableElements } from '../hooks/useMoveableToFolder';

import FolderCardContextMenu from './folder-card-context-menu';

interface FolderCardProps<T> {
  folder: T;
  onFileMove?: (elements: MoveableElements) => void;
  onFolderHover?: (elements: MoveableElements) => void;
  onClick?: (folder: T) => void;
  tourId?: string;
}

const FolderCard = <T extends File>({
  folder,
  onFileMove,
  onFolderHover,
  onClick,
  tourId,
}: FolderCardProps<T>) => {
  const { handleMouseDown, handleMouseUp, handleClick } = useClickAndDrag();
  const folderCardRef = useRef<HTMLDivElement | null>(null);

  const cardClasses = useMemo(
    () =>
      twMerge(
        'p-sm flex w-full flex-1 cursor-pointer items-center gap-3',
        'bg-gray-100 transition duration-300 hover:bg-gray-300'
      ),
    []
  );

  const FolderRaw = () => (
    <AnimatePresence>
      <div
        className="folderCard"
        data-tour={tourId}
        id={folder.id}
        title={folder.fileName}
        onClick={handleClick(() => onClick?.(folder))}
      >
        <Card data-testid="folder-card" className="flex h-14">
          <div className={cardClasses}>
            <span>
              <FolderIcon title="folder" />
            </span>
            <Typography className="overflow-hidden text-ellipsis whitespace-nowrap !text-base !font-medium">
              {folder.fileName}
            </Typography>
          </div>
        </Card>
      </div>
    </AnimatePresence>
  );

  if (onFileMove && onFolderHover) {
    return (
      <>
        <span onClick={handleClick(() => onClick?.(folder))}>
          <MoveableToFolder
            onFolderHover={onFolderHover}
            onDragStart={handleMouseDown}
            onDragEnd={handleMouseUp}
            onFileMove={onFileMove}
            elementId={folder.id}
            elementName={folder.fileName}
            ref={folderCardRef}
          >
            <FolderRaw />
          </MoveableToFolder>
        </span>
        <FolderCardContextMenu
          folder={folder}
          anchorElement={folderCardRef.current}
        />
      </>
    );
  }

  return <FolderRaw />;
};

export default FolderCard;
