import {
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useUser from '@app/hooks/useUser';
import { Note } from '@app/services/files/types';
import EditIcon from '@assets/shared/edit.svg?react';
import TrashIcon from '@assets/shared/trash.svg?react';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useDeleteNote from '../hooks/useDeleteNote';
import useEditNote from '../hooks/useEditNote';

interface FileDetailsNoteCardProps {
  note: Note;
}

const FileDetailsNoteCard = ({ note }: FileDetailsNoteCardProps) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteNote, isPending, isError } = useDeleteNote();
  const { data: user } = useUser(note.user_id);
  const {
    mutateAsync: editNote,
    isPending: isEditPending,
    isError: isEditError,
  } = useEditNote();
  const { displayGlobal } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      displayGlobal({
        text: t('fileList.fileDetails.notes.card.errorDeleting'),
        type: 'error',
      });
    },
  });
  useErrorHandler(isEditError, {
    callback: () => {
      displayGlobal({
        text: t('fileList.fileDetails.notes.card.edit.error'),
        type: 'error',
      });
    },
  });
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const { handleSubmit, control, formState, reset } = useForm<
    Pick<Note, 'content'>
  >({
    defaultValues: {
      content: note.content,
    },
  });

  const handleDelete = useCallback(async () => {
    await deleteNote(note.id);
    await queryClient.invalidateQueries({ queryKey: ['notes'] });
  }, [deleteNote, note.id, queryClient]);

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      setIsEditing(false);
      event.stopPropagation();
    },
    []
  );

  const handleUpdate = useCallback(
    async ({ content }: Pick<Note, 'content'>) => {
      setIsEditing(false);
      await editNote({ content, id: note.id });
      reset({ content });
      await queryClient.invalidateQueries({ queryKey: ['notes'] });
    },
    [editNote, note.id, queryClient, reset]
  );

  const handleKeyPress: KeyboardEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      if (event.code === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        void handleSubmit(handleUpdate)();
      }
    },
    [handleSubmit, handleUpdate]
  );

  const component = useMemo(() => {
    if (isPending || isEditPending) {
      return (
        <div className="flex items-center justify-center">
          <Loading size={18} />
        </div>
      );
    }

    return (
      <>
        <div className="mb-2 flex items-center justify-between">
          <p className="text-sm text-black/40">
            {t('fileList.fileDetails.notes.card.header', {
              name: user?.name ?? note.user_id,
              date: DateTime.fromISO(note.created_at).toLocaleString(),
              interpolation: { escapeValue: false },
            })}
          </p>
          <PressableIcon
            icon={
              <EditIcon
                title="edit"
                width={12}
                height={12}
                className="text-black/80"
              />
            }
            onClick={() => setIsEditing(true)}
            altText={t('fileList.fileDetails.notes.card.edit.button')}
          />
          <PressableIcon
            icon={
              <TrashIcon
                title="trash"
                width={12}
                height={12}
                className="text-black/80"
              />
            }
            onClick={handleDelete}
            altText={t('fileList.fileDetails.notes.card.delete')}
          />
        </div>
        {isEditing ? (
          <form
            onSubmit={handleSubmit(handleUpdate)}
            onKeyDown={handleKeyPress}
          >
            <FormControl fullWidth className="!m-0">
              <Controller
                name="content"
                control={control}
                rules={{
                  validate: (value) => value.length > 1,
                }}
                render={({ field }) => (
                  <TextField
                    multiline
                    autoFocus
                    fullWidth
                    variant="outlined"
                    className="!mt-sm"
                    {...field}
                    label={t('fileList.fileDetails.notes.card.edit.label')}
                    placeholder={t(
                      'fileList.fileDetails.notes.form.placeholder'
                    )}
                  />
                )}
              />
            </FormControl>
            <div className="gap-x-sm flex flex-row justify-end">
              <Button className="!my-sm !float-right" onClick={handleCancel}>
                {t('fileList.fileDetails.notes.card.edit.cancel')}
              </Button>
              <Button
                disabled={!formState.isValid || !formState.isDirty}
                type="submit"
                className="!my-sm"
              >
                {t('fileList.fileDetails.notes.card.edit.save')}
              </Button>
            </div>
          </form>
        ) : (
          <p>{note.content}</p>
        )}
      </>
    );
  }, [
    control,
    formState.isDirty,
    formState.isValid,
    handleCancel,
    handleDelete,
    handleKeyPress,
    handleSubmit,
    handleUpdate,
    isEditPending,
    isEditing,
    isPending,
    note.content,
    note.created_at,
    note.user_id,
    t,
    user?.name,
  ]);

  return (
    <Card className="!m-0">
      <CardContent>{component}</CardContent>
    </Card>
  );
};

export default FileDetailsNoteCard;
