import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateVersionRequest } from '@app/services/files/types';

import useFileContext from '../hooks/useFileContext';
import useProposedVersions from '../hooks/useProposedVersions';

interface NewVersionFormProps {
  onSubmit: (form: CreateVersionRequest) => void;
  onCancel: () => void;
}

const NewVersionForm = ({ onSubmit, onCancel }: NewVersionFormProps) => {
  const { t } = useTranslation();
  const file = useFileContext();

  const { handleSubmit, control, formState, setValue, watch } =
    useForm<CreateVersionRequest>({
      defaultValues: {
        baseVersionId: undefined,
        name: '',
      },
    });
  const baseVersionId = watch('baseVersionId');

  const { data: versions, isLoading } = useProposedVersions({
    fileId: file!.id,
  });

  useEffect(() => {
    if (!baseVersionId && versions?.at(0)) {
      setValue('baseVersionId', versions.at(0)!.id);
    }
  }, [baseVersionId, setValue, versions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              variant="standard"
              {...field}
              label={t('fileVersions.newVersionModal.name')}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth className="!my-sm">
        <Controller
          name="baseVersionId"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Autocomplete
              options={versions ?? []}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_e, data) => onChange(data)}
              value={
                versions?.find((version) => version.id === baseVersionId) ??
                null
              }
              loading={isLoading}
              {...field}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label={t('fileVersions.newVersionModal.base')}
                />
              )}
            />
          )}
        />
      </FormControl>
      <div className="gap-x-sm mt-lg flex justify-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('fileVersions.newVersionModal.cancel')}
        </Button>
        <Button
          className="!px-md"
          variant="contained"
          type="submit"
          disabled={!formState.isValid}
        >
          {t('fileVersions.newVersionModal.create')}
        </Button>
      </div>
    </form>
  );
};

export default NewVersionForm;
