import { useMutation } from '@tanstack/react-query';

import { getFilesApi } from '@app/services/files/api';

const useMoveFolderToTrash = () => {
  const { moveFolderToTrash } = getFilesApi();
  const query = useMutation({
    mutationFn: moveFolderToTrash,
    mutationKey: ['folder-move-to-trash'],
  });

  return query;
};

export default useMoveFolderToTrash;
