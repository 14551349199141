import { useMutation } from '@tanstack/react-query';

import { getGroupsApi } from '@app/services/groups/api';

const useReactivateGroup = () => {
  const { reactivateGroup } = getGroupsApi();
  const mutation = useMutation({
    mutationFn: reactivateGroup,
    mutationKey: ['groups-reactivate-group'],
  });

  return mutation;
};

export default useReactivateGroup;
