import { Avatar, AvatarGroup, TableCell, TableRow } from '@mui/material';

import { File } from '@app/services/files/types';
import FileIcon from '@components/file-icon';
import useAvatar from '@pages/files/hooks/useAvatar';

interface TagDetailsFilesTableRowProps {
  file: File;
  onClick: () => void;
}

const TagDetailsFilesTableRow = ({
  file,
  onClick,
}: TagDetailsFilesTableRowProps) => {
  const { stringAvatar } = useAvatar();

  return (
    <TableRow onClick={onClick} className="cursor-pointer">
      <TableCell className="!py-2">
        <div className="gap-x-sm flex items-center">
          <FileIcon type={file.type} width={32} height={32} />
          <p className="text-base">{file.fileName}</p>
        </div>
      </TableCell>
      <TableCell className="!py-2">
        <Avatar
          {...stringAvatar(file.people[0], {
            fontSize: 18,
          })}
        />
      </TableCell>
      <TableCell className="!py-2">
        <div className="flex items-center">
          <AvatarGroup max={4}>
            {file.people.map((user) => (
              <Avatar
                key={user}
                {...stringAvatar(user, {
                  fontSize: 18,
                })}
              />
            ))}
          </AvatarGroup>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TagDetailsFilesTableRow;
