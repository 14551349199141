import { DateTime } from 'luxon';

import { Paginated, PaginationOptions } from '@filot/types/api';

export enum PermissionLevel {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  CLIENT = 'CLIENT',
}

export type Status = 'active' | 'inactive';

export interface FilePermissions {
  write: boolean;
  read: boolean;
  comment: boolean;
}

export interface AccessDates {
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export interface File {
  id: string;
  name: string;
  permissions: FilePermissions;
  type?: string;
}

export interface NewGroup {
  name: string;
  users: string[];
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export type UserRoles = Record<string, PermissionLevel>;

export type Group = {
  id: string;
  name: string;
  description: string | null;
  picture?: string;
  users: UserRoles;
  files: File[];
  totalFiles: number;
  startDate: string;
  expirationDate?: string;
  permission: PermissionLevel;
  status: Status;
};

export type GroupResponse = {
  name: string;
  description: string;
  icon_image: string;
  users: UserRoles;
  total_files: number;
  start_date: string;
  expiration_date: string;
  files: string[];
  organization_id: string;
  id: string;
  status: Status;
};

export interface NewGroupRequest {
  name: string;
  users: string[];
  startDate: string;
  expirationDate: string | null;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  permissionLevel: PermissionLevel;
}

export type AddFilesForm = {
  [fileId: string]: { permissions: FilePermissions };
};

export interface NewGroupOwner {
  email: string;
}

export interface GroupsApi {
  get: (
    paginationOptions: PaginationOptions,
    userId?: string
  ) => Promise<Paginated<Group>>;
  getDetails: (id: string) => Promise<Group>;
  updateFilePermissions: (options: {
    groupId: string;
    fileId: string;
    newPermissions: FilePermissions;
  }) => Promise<void>;
  removeFile: (options: { groupId: string; fileId: string }) => Promise<void>;
  createGroup: (form: NewGroupRequest) => Promise<Group>;
  getFiles: (options: { groupId: string; page: number }) => Promise<File[]>;
  bulkEdit: (options: {
    groupId: string;
    fileIds: string[];
    permissions: FilePermissions;
  }) => Promise<void>;
  getMembers: (groupId: string) => Promise<Member[]>;
  changeMemberPermission: (params: {
    member: string;
    groupId: string;
    permission: PermissionLevel;
  }) => Promise<void>;
  userHasAccount: (email: string) => Promise<boolean>;
  inviteUsers: (params: { emails: string[]; groupId: string }) => Promise<void>;
  removeUser: (params: { id: string; groupId: string }) => Promise<void>;
  addFiles: (options: {
    files: AddFilesForm;
    groupId: string;
  }) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  getGroupsAdmin: () => Promise<Group[]>;
  updateGroupDetails: (options: {
    groupId: string;
    name?: string;
    description?: string;
  }) => Promise<void>;
  reactivateGroup: (params: {
    groupId: string;
    startDate: string;
    expirationDate: string | null;
    restorePermissions: boolean;
  }) => Promise<void>;
  leaveGroup: (params: { newOwnerEmail?: string; id: string }) => Promise<void>;
  updateGroupPicture: (params: {
    file: globalThis.File;
    groupId: string;
  }) => Promise<void>;
}
