import mock from '../../../../../mock/organization.json';

import {
  OrganizationApi,
  Organization,
  OrganizationUserInviteRequest,
  RemoveUserFromOrganizationRequest,
  UserOrganizationsResponse,
  UserRolesResponse,
  DnsRecord,
  OrganizationBulkInviteRequest,
  UserResponse,
} from './types';

let organization: null | Organization = {
  name: 'Filot LLC',
  id: 'org_QzW529mRgmZxF7er',
};

const getOrganizationMock = (): OrganizationApi => {
  const getUsers: OrganizationApi['getUsers'] = (_id: string, page: number) =>
    new Promise<UserResponse>((res) => {
      const totalUsers = mock.users.length; // Total number of users
      const totalPages = Math.ceil(totalUsers / 8); // Calculate total pages

      // Calculate the starting index for the requested page
      const startIndex = (page - 1) * 8;
      // Get the subset of users for the requested page
      const paginatedUsers = mock.users.slice(startIndex, startIndex + 8);

      setTimeout(() => {
        res({
          success: true,
          message: 'Members found successfully!',
          pagination: {
            total: totalUsers,
            page_size: 8,
            pages: totalPages,
            page,
            links: {
              self: `/api/v1/organizations/${_id}/members/get?page=${page}`,
              next:
                page < totalPages
                  ? `/api/v1/organizations/${_id}/members/get?page=${page + 1}`
                  : null,
              previous:
                page > 1
                  ? `/api/v1/organizations/${_id}/members/get?page=${page - 1}`
                  : null,
            },
          },
          data: paginatedUsers,
        });
      }, 500);
    });

  const inviteUser = (_newUser: OrganizationUserInviteRequest) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const bulkInvite = (_params: OrganizationBulkInviteRequest) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getOrganization: OrganizationApi['getOrganization'] = async () =>
    new Promise<null | UserOrganizationsResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5 || !organization) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            ...organization,
            display_name: organization.name,
            id: organization.id,
            status: 'created',
            role: 'it-staff',
          });
        }
      }, 1000);
    });

  const createOrganization = async ({
    dns,
    name,
    domain,
  }: {
    name: string;
    domain: string;
    dns: string;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          if (dns && name && domain) {
            organization = {
              name: 'Filot LLC',
              id: 'org_QzW529mRgmZxF7er',
            };
            res();
          }
        }
      }, 1000);
    });

  const removeUserFromOrganization = async ({
    newOwnerEmail,
  }: RemoveUserFromOrganizationRequest) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (newOwnerEmail.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getUserRoles = async (_userId: string, _organizationId: string) =>
    new Promise<UserRolesResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res([
            {
              id: 'rol_F7Syo3Wk3ijx15Io',
              name: 'organization-admin',
              description: 'admin of an organization',
            },
          ]);
        }
      }, 1000);
    });

  const generateDnsRecord = async (_domain: string) =>
    new Promise<DnsRecord>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            domain: 'filot.io',
            dns_record_type: 'TXT',
            content:
              'filot.filot.io-2024-07-25_11:55:17.611180-8090586986140763',
            verified: false,
            verification_datetime: null,
          });
        }
      }, 1000);
    });

  const transferOwnership = async (_params: {
    organizationId: string;
    newUserId: string;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const uploadNewPicture = async (_params: {
    organizationId: string;
    file: File;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const update = async (_params: { organizationId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  return {
    getUsers,
    inviteUser,
    createOrganization,
    getOrganization,
    removeUserFromOrganization,
    getUserRoles,
    generateDnsRecord,
    bulkInvite,
    transferOwnership,
    uploadNewPicture,
    update,
  };
};

export default getOrganizationMock;
