import { Box, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { NewGroup } from '@app/services/groups/types';
import Close from '@assets/shared/close.svg?react';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import NewGroupForm from './new-group-form';

interface NewGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: NewGroup) => void;
  isError: boolean;
  onTryAgain: () => void;
  isPending: boolean;
}

const NewGroupModal = ({
  isOpen,
  onClose,
  onSubmit,
  isError,
  onTryAgain,
  isPending,
}: NewGroupModalProps) => {
  const { t } = useTranslation();
  const withCanClose = useCloseRequestModal(isPending, onTryAgain);

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <ErrorIcon />
          <Typography className="!mt-lg !mr-1 !text-lg">
            <Trans i18nKey="groups.newGroupModal.error">
              Oops! There was an error creating the new group. Please
              <span
                className="cursor-pointer text-lg underline"
                onClick={onTryAgain}
              >
                try again
              </span>
              .
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    return <NewGroupForm onSubmit={onSubmit} />;
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="p-screen mx-auto my-auto !flex h-5/6 w-1/2 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!mb-lg !text-2xl !font-medium">
          {t('groups.newGroupModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default NewGroupModal;
